import React from "react";
import styled from "@emotion/styled";
import EditIcon from "../../../../../../assets/images/edit-icon.svg";
import Table from "../../../../../shared/Table";
import TextInput from "../../../../../shared/TextInput";
import Button from "../../../../../shared/Button";
import SecondaryButton from "../../../../../shared/SecondaryButton";
import Select from "../../../../../shared/Select";
import EyeIcon from "../../../../../../assets/images/icon_eye.svg";
import DeleteIcon from "../../../../../../assets/images/delete-icon.svg";

import Tooltip from "@material-ui/core/Tooltip";

const ReportSenderComponent = ({ config, types, reportSender, surveys, registers, actions }) => {

    const Title = styled.div`
        background-color: darkgray;
        color: white;
        text-transform: uppercase;
        font-weight: bold;
        padding: 2vh 5vh;
    `;

    const Wrapper = {
        width: "calc(100% - 12vh)",
        display: "flex",
        padding: "4vh 6vh",
        marginBottom: "0.5rem",
        justifyContent: "center",
        flexDirection: "column",
        rowGap: "2vh",
        columnGap: "3vh"
    }

    const Tag = styled.div`
        flex: 1;
        font-size: 2.5vh;
        border-bottom: 1px solid grey;
        margin-bottom: 1vh;
        margin-top: 1vh;
        padding-bottom: 1vh;
    `

    const Label = styled.div`
        font-size: 2.3vh;
        font-weight: bold;
    `

    const Data = styled.div`
        font-size: 2.3vh;
    `

    const Icon = styled.img`
        height: 2.5vh;
        cursor: pointer;
    `

    const Row = {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        columnGap: "5vh",
        alignItems: "center"
    }

    const RowFlexStart = {
        ...Row,
        justifyContent: "flex-start"
    }

    const Column = {
        width: "50%",
        display: "flex",
        flexDirection: "column",
        rowGap: "2.5vh",
        alignItems: "center"
    }

    const ColumnFull = {
        ...Column,
        width: "100%"
    }

    let listActions = [
        {
            title: (
                <Tooltip title={config.strings.seeDetails} aria-label="add">
                    <Icon src={EyeIcon} />
                </Tooltip>
            ),
            action: actions.showCompleteStatus,
        }
    ];

    return (
        <div style={{ width: "100%" }}>
            <Title>{config?.getText("reportSenders")}</Title>

            <div style={Wrapper}>
                <Tag>{config?.getText("config")}</Tag>

                <div style={{ ...Row, alignItems: "flex-start" }}>
                    <div style={Column}>
                        <div style={Row}>
                            <Label>{config?.getText("alias")}:</Label>
                            <TextInput
                                id="alias"
                                width={"75%"}
                                value={reportSender?.alias}
                                onChange={(e) => actions.updateReportSender("alias", e.target.value)} />
                        </div>
                        <div style={Row}>
                            <Label>{config?.getText("survey")}: </Label>
                            <Select
                                id={"surveyId"}
                                width={"75%"}
                                options={surveys}
                                defaultOption={reportSender?.surveyId}
                                onChange={actions.updateReportSender} />
                        </div>
                        <div style={Row}>
                            <Label>{config?.getText("type")}: </Label>
                            <Select
                                id={"type"}
                                width={"75%"}
                                options={types}
                                defaultOption={reportSender?.type}
                                onChange={actions.updateReportSender} />
                        </div>
                    </div>

                    <div style={Column}>
                        <div style={Row}>
                            <Label>Emails:</Label>
                            <Button value={config?.getText("add")} action={actions.addEmail} />
                        </div>
                        <div style={ColumnFull}>
                            {(reportSender?.emails || []).map((email, index) => {
                                return (
                                    <div style={RowFlexStart}>
                                        <TextInput
                                            id="alias"
                                            width={"100%"}
                                            value={email}
                                            onChange={(e) => actions.editEmail(index, e.target.value)} />
                                        <Icon src={DeleteIcon} onClick={() => actions.deleteEmail(index)} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div style={{ ...Row, padding: "2vh 0" }}>
                    <SecondaryButton value={config.strings.cancel} action={actions.cancel} style="margin: 0 1vh 0 auto" />
                    <Button value={config.strings.confirm} action={actions.confirm} style="margin: 0 auto 0 0" />
                </div>

                <Tag>{config?.getText("details")}</Tag>

                <div style={Row}>
                    <div style={{ ...RowFlexStart, width: "auto" }}>
                        <Label>{config?.getText("nextDelivery")}:</Label>
                        <Data>{actions?.getNextExecutionDate()}</Data>
                    </div>
                    {actions.isSendReportSenderAllowed() ? (
                        <SecondaryButton value={config.getText("sendReport")}
                            action={actions.sendReportSender} />
                    ) : (
                        <></>
                    )}
                </div>

                <div style={{ ...ColumnFull, alignItems: "flex-start" }}>
                    <Label>{config?.getText("log")}:</Label>
                    <Table
                        header={config?.strings?.reportSenderLogTableHeaders}
                        data={registers}
                        noElementsText={config.strings.noneListRep}
                        sortByField={"creationDate"}
                        sortOrder={"descending"}
                        styles={"width: 100%"} />
                </div>
            </div>
        </div>
    );
};

export default ReportSenderComponent;

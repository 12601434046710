import React from "react";
import styled from "@emotion/styled";

const SettingsComponent = ({ config }) => {

  const Container = styled.div`
    display: flex;
    min-width: 100%;
    min-height: 100%;
    justify-content: center;
    align-items: center;
  `;

  return (
    <Container>
      TODO: EDIT SETTINGS (DASHBOARD LANGUAGE, ENABLE TOOLTIPS, ETC...)
    </Container>
  );
};

export default SettingsComponent;

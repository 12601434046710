import React, { useEffect, useState } from "react";
import NavbarComponent from "./NavbarComponent";
import { useNavigate } from "react-router-dom";
import Firebase from "../../../../assets/Firebase";

const NavBarEngine = ({ config }) => {
  const navigate = useNavigate();
  const f = new Firebase();

  const [logo, setLogo] = useState(null);

  const redirect = (path) => {
    navigate(path);
  }

  const logout = () => {
    config?.popupAlert(config?.strings.logout, config?.strings.logoutConfirm, null, true, () => {
      f.tryLogout(() => {
        config.update("user", null);
        navigate("/login");
      }, (error) => {
        console.log("Error logout: ", error);
      })
    });
  }

  const goToProfile = () => {
    navigate("/dashboard/profile");
  }

  const getLangIcon = () => {
    try {
      return require(`../../../../assets/images/flag-${config?.lang}.png`);
    } catch (e) {
      return null;
    }
  }

  useEffect(() => {
    let userLogo = config?.user?.logo;
    if (userLogo !== null && userLogo !== undefined) {
      f.loadUserImage(userLogo, setLogo);
    }
  }, [config?.user?.logo]);

  return (
    <NavbarComponent
      config={config}
      logo={logo}
      langIcon={getLangIcon()}
      goToProfile={goToProfile}
      logout={logout} />);
};

export default NavBarEngine;

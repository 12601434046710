import React from "react";
import styled from "@emotion/styled";

const QuestionFreeComponent = ({ config, screenIndex, questionIndex, isMultipleQuestion, question, actions }) => {

    const Wrapper = {
        background: "white",
        borderRadius: "1vh",
        width: "calc(100% - 4vh)",
        margin: "2vh 0",
        marginTop: (screenIndex === 0) ? "2vh" : "0vh",
        padding: "2vh",
        display: "flex",
        flexDirection: "row",
    };

    const Row = {
        display: "flex",
        flexDirection: "row",
        width: "100%"
    }

    const Column = {
        ...Row,
        flexDirection: "column",
    }

    const EmptyText = {
        ...Row,
        justifyContent: "center",
        color: "lightgray",
        fontStyle: "italic",
        fontSize: "2.2vh"
    }

    const QuestionIndex = styled.div`
        display: flex;
        background: lightgray;
        color: white;
        border-radius: 1vh;
        min-height: 4vh;
        min-width: 4vh;
        margin: auto 0;
        font-size: 2.5vh;
        font-weight: bold;
        align-items: center;
        justify-content: center;
    `;

    const QuestionTag = styled.div`
        font-size: 2.3vh;
        margin: auto 2vh;
        flex: 1;
        font-weight: bold;
    `;

    const QuestionDetail = styled.div`
        font-size: 2.3vh;
        color: gray;
        margin: auto 2vh;
        flex: 1;
    `;

    const ResultsTable = styled.table`
        border-collapse: separate; 
        table-layout: fixed;
        margin: auto 0 ;
        background-color: white;
        border: 2px solid lightgray;
        border-radius: 1vh;
        border-spacing: 0px;
        td {
            padding: 2vh;
        }
        th {
            width: 10%;
            padding: 2vh;
            text-align: left;
            background-color: lightgray;
            font-weight: normal;
        }
        tr:nth-of-type(even) {
            background-color: rgb(0 0 0 / 5%);
        }
        tr:first-child {
            th:first-child { border-top-left-radius: 0.5vh; }
            td:last-child { border-top-right-radius: 0.5vh;  }
        }
        tr:last-child {
            th:first-child { border-bottom-left-radius: 0.5vh; }
            td:last-child {  border-bottom-right-radius: 0.5vh; }
        }
    `;

    let questionNum = "" + (screenIndex + 1) + (isMultipleQuestion ? ("." + (questionIndex + 1)) : "")
    let responses = actions.getFreeResponses(question);

    return (
        <div style={Wrapper}>
            <div style={Column}>
                <div style={{ ...Row, marginBottom: "2vh" }}>
                    <QuestionIndex>{questionNum}</QuestionIndex>
                    <QuestionTag>{config.getTranslatedText(question?.questionTitle)}</QuestionTag>
                    <QuestionDetail>{config?.strings?.[question?.type]}</QuestionDetail>
                </div>
                {responses?.length > 0 ? (
                    question?.type == "EMAIL" ? (
                        <ResultsTable>
                            <tr>
                                <th>{config.getText("email")}</th>
                                <th>{config.getText("name")}</th>
                                <th>{config.getText("checkedRGPD")}</th>
                                <th>{config.getText("checkedNewsletter")}</th>
                            </tr>
                            {responses.map((response, responseIndex) => {
                                if (!response?.freeResponses?.email) return;

                                return (
                                    <tr>
                                        <td>{response?.freeResponses?.email || "-"}</td>
                                        <td>{response?.freeResponses?.name || "-"}</td>
                                        <td>{response?.freeResponses?.checkedLOPD ? config.getText("yes") : config.getText("no")}</td>
                                        <td>{response?.freeResponses?.checkedNewsletter ? config.getText("yes") : config.getText("no")}</td>
                                    </tr>
                                );
                            })}
                        </ResultsTable>
                    ) : (
                        <ResultsTable>
                            <tr>
                                <th>{config.getText("freeText")}</th>
                            </tr>
                            {responses.map((response, responseIndex) => {
                                if (!response?.freeResponses?.freeText) return;
                                return (
                                    <tr>
                                        <td>{response?.freeResponses?.freeText || "-"}</td>
                                    </tr>
                                );
                            })}
                        </ResultsTable>
                        )
                ) : (
                    <div style={EmptyText}>{config.getText("noResponses")}</div>
                )}
            </div>
        </div >
    );

};
export default QuestionFreeComponent;
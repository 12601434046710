import React from "react";
import RegisterComponent from "./RegisterComponent";
import Firebase from "../../../assets/Firebase";
import { useNavigate } from "react-router-dom";

const RegisterEngine = ({ props }) => {
  let navigate = useNavigate();
  let f = new Firebase();

  const register = () => {
    let name = document.getElementById("nameInput").value;
    let password = document.getElementById("passwordInput").value;
    let password2 = document.getElementById("passwordInput2").value;

    if (validateEmail(name)) {
      if (validatePassword(password, password2)) {
        f.tryCreateUser(name, password, password2, props, success, error);
      }
      else {
        error("errorPasword2");
      }
    }
    else {
      error("errorEmail");
    }
  };

  const validatePassword = (password, password2) => {
    let isValid = true
    if (password !== '' && password2 !== '') {
      if (password !== password2) {
        isValid = false
      }
    }
    else {
      isValid = false
    }
    return isValid
  }

  const validateEmail = (email) => {
    let isValid = true
    let regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    if (email !== '') {
      if (regex.test(email)) {
        isValid = true
      }
      else {
        isValid = false
      }
    }
    else {
      isValid = false
    }
    return isValid
  }

  const success = () => {
    props.popupAlert(props.strings.correct, props.strings.createUser, () => {
      navigate("/")
    });
  };

  const error = (err) => {
    console.log("Register error:", err);

    switch (err?.code) {
      case "auth/wrong-password":
        props.popupAlert(props.strings.error, props.strings.errorEmail, () => {
          navigate("/register");
        });
        break;
      case "auth/user-not-found":
        props.popupAlert(props.strings.error, props.strings.errorEmail, () => {
          navigate("/register");
        });
        break;
      case "auth/too-many-requests":
        props.popupAlert(props.strings.error, props.strings.errorToManyRequests, () => {
          navigate("/register");
        });
        break;
      case "auth/invalid-email":
        props.popupAlert(props.strings.error, props.strings.errorEmail, () => {
          navigate("/register");
        });
        break;
      default:
        switch (err) {
          case "errorPasword2":
            props.popupAlert(props.strings.error, props.strings.errorPasword2, () => {
              navigate("/register");
            });
            break;
          case "errorEmail":
            props.popupAlert(props.strings.error, props.strings.errorEmail, () => {
              navigate("/register");
            });
            break;
          case "auth/email-already-in-use":
            props.popupAlert(props.strings.error, props.strings.errorExist, () => {
              navigate("/register");
            });
            break;
          case "auth/weak-password":
            props.popupAlert(props.strings.error, props.strings.errorMin, () => {
              navigate("/register");
            });
            break;
          default:
            props.popupAlert(props.strings.error, props.strings.errorCreateUser, () => {
              navigate("/register");
            });
            break;
        }
        break;
    }

  };

  return (
    <RegisterComponent
      props={props}
      register={register} />
  );
};

export default RegisterEngine;

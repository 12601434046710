import React from "react";
import { BrowserRouter as useMatch, useLocation, useNavigate } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";

const Option = ({ config, child = false, style, to, icon, id, tooltip, stringId, text, action }) => {
  let locations = useLocation().pathname.split("/"); //[2];
  let navigate = useNavigate();

  const selectedStyle = {
    ...style,
    backgroundColor: "rgb(255 255 255 / 90%)",
    color: "black",
    fontWeight: "bold",
  };

  const Img = {
    width: "2.5vh",
    height: "2.5vh",
    marginRight: "1.5vh",
  };

  const Tag = {
    padding: "0.5vh",
    fontSize: "1.7vh",
  };

  const isSelected = () => {
    if (!id) return null;
    let selected = true;
    let index = 2;
    id.split("/").forEach((idPart) => {
      if (idPart != locations[index]) selected = false;
      index++;
    });
    return selected;
  };

  const redirect = () => {
    navigate(to);
  };

  return (
    <>
      {tooltip && config && config.tooltipsEnabled ? (
        <Tooltip title={<div style={Tag}>{tooltip}</div>} aria-label="add" arrow>
          <div className={child ? "child" : ""} onClick={action ? action : redirect} style={isSelected() ? selectedStyle : style}>
            <img style={Img} src={icon} />
            {text ? text : stringId ? config?.strings[stringId] : config?.strings[id]}
          </div>
        </Tooltip>
      ) : (
          <div className={child ? "child" : ""} onClick={action ? action : redirect} style={isSelected() ? selectedStyle : style}>
          <img style={Img} src={icon} />
            {text ? text : stringId ? config?.strings?.[stringId] : config?.strings?.[id]}
        </div>
      )}
    </>
  );
};
export default Option;

import React, { useState, useEffect } from "react";
import ScreenEngine from "./Components/ScreenEngine";
import Button from "../../../../../shared/Button";
import TranslatableInput from "../../../../../shared/TranslatableInput";
import SecondaryButton from "../../../../../shared/SecondaryButton";
import TextInput from "../../../../../shared/TextInput";
import Select from "../../../../../shared/Select";
import styled from "@emotion/styled";
import ImagePicker from "../../../../../shared/ImagePicker";
import TopIcon from "../../../../../../assets/images/ic_contract.png";
import BottomIcon from "../../../../../../assets/images/ic_expand.png";
import Checkbox from "../../../../../shared/Checkbox";
import DateTimeTicker from "../../../../../shared/DateTimeTicker";
import { isNullOrUndefined } from "../../../../../../assets/utils/FormsUtils";
import VideoPicker from "../../../../../shared/VideoPicker";
import DivButtons from "../../../../../shared/DivButtons";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import IconClipBoard from "../../../../../../assets/images/clipboard-icon.svg";
import PlayIcon from "../../../../../../assets/images/ic_play.svg";
import PlayStoreIcon from "../../../../../../assets/images/play_store.png";
import Tooltip from "@material-ui/core/Tooltip";

const SurveyFormComponent = ({ config, survey, surveyTypes, configVisible, surveyActions, screenActions, confirmAction, cancelAction, URL, surveyId }) => {
  const ButtonWrapper = styled.div`
    display: flex;
    place-items: center;
    margin-top: 0.5rem;
  `;

  const Title = styled.div`
    background-color: darkgray;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    padding: 2vh 5vh;
  `;

  const Wrapper = {
    width: "calc(100% - 4vh)",
    display: "flex",
    borderBottom: "1px solid grey",
    paddingBottom: "0.5rem",
    marginBottom: "0.5rem",
    justifyContent: "center",
    padding: "2vh",
    flexDirection: "column",
    rowGap: "1.5vh",
    columnGap: "3vh"
  }

  const Row = {
    width: "100%",
    display: "flex",
    flex: "1",
    flexDirection: "row",
    rowGap: "2vh",
    columnGap: "3vh"
  }

  const RowUp = {
    ...Row,
    justifyContent: "center",
    alignItems: "center",
  }

  const TightRow = {
    ...Row,
    rowGap: "1vh",
  }

  const Column = {
    ...Row,
    flexDirection: "column"
  }

  const WelcomeConfig = {
    ...Column,
    background: "lightgray",
    width: "calc(100% - 6vh)",
    padding: "2vh 3vh",
    borderRadius: "1vh",
    rowGap: "1vh"
  }

  const QuestionHeaderWrapper = {
    ...Row,
    padding: "1.5vh 0",
    borderBottom: "1px solid grey",
  }

  const QuestionHeaderTag = {
    marginTop: "auto",
    marginBottom: "auto",
    fontSize: "2.7vh"
  }

  const Item = {
    display: "flex",
    flex: "1",
    flexDirection: "column"
  }

  const Item2 = {
    display: "flex",
    flex: "1",
    flexDirection: "row",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  }

  const ImageContainer = {
    height: configVisible ? "50%" : "100%",
    width: "100%",
    margin: "auto",
    textAlign: "center",
    backgroundColor: "#cbcbcb",
    position: "relative",
  };

  const ImageContainer2 = {
    maxHeight: "100%",
    maxWidth: "100%",
    position: "absolute",
    inset: "0px",
    margin: "auto",
  };

  const Tag = styled.div`
    flex: 1;
    font-size: 2.7vh;
    max-height: 2.7vh;
    border-bottom: 1px solid grey;
    margin-bottom: 2vh;
    padding-bottom: 2vh;
  `;

  const Tag2 = styled.div`
    flex: 1;
    font-size: 2.7vh;
    max-height: 2.7vh;
    margin-bottom: 1vh;
  `;

  const Headertag = styled.div`
    flex: 1;
    font-size: 2.3vh;
    max-height: 2.3vh;
    font-weight: bold;
    padding: 0.5vh 0;
    margin: auto;
  `;

  const Subtag = styled.div`
    flex: 1;
    font-size: 2vh;
    max-height: 2vh;
    margin: auto;
  `;

  const Action = styled.div`
    margin: auto;
    font-size: 2vh;
    cursor: pointer;
    padding: 0.5vh 2vh;
    &:hover {
      background: darkgray;
      border-radius: 0.5vh;
      color: white;
    }
  `;

  const IconAction = styled.img`
    margin: auto 0 auto 1vh;
    height: 1.5vh;
    width: auto;
  `;

  const CopyText = styled.div`
    width: 100%;
    border: 1px solid grey;
    border-radius: 0.3rem;
    background-color: #cbcbcb;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    ${surveyId === undefined ? null : `
    cursor: pointer;

    &:hover {
      background: darkgray;
      border-radius: 0.5vh;
    }`}
    
  `;

  const TextPlayStore = styled.div`
    border: 1px solid grey;
    border-radius: 0.3rem;
    background-color: #cbcbcb;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `;

  const Icon = styled.img`
    height: 3.5vh;
    width: 3.5vh;
    margin: 2vh;
    ${surveyId === undefined ? null : `cursor: pointer;`}
  `;

  const Icon2 = styled.img`
    height: 10vh;
    width: 20vh;
    margin: 1vh;
    cursor: pointer;
  `;

  return (
    <div style={{ width: "100%" }}>
      <Title>{survey?.ref ? config.strings.editSurvey : config.strings.createSurvey}</Title>

      <div style={{ padding: "2vh 3vh" }}>

        <div style={Wrapper}>

          <div style={Row}>

            <div style={{ ...Column, flex: "2" }}>
              <div style={Item2}>
                <Tag2>{config?.strings?.nameEnquesta}</Tag2>
                <TextInput
                  id="name"
                  style={{ fontSize: "2vh" }}
                  width={"75%"}
                  placeholder={config?.strings?.nameEnquesta}
                  value={survey?.name}
                  onChange={(e) => surveyActions.update("name", e.target.value)} />
              </div>

              <div style={Item}>
                <Tag>{config?.strings?.typeEnquesta + ": "}<b style={{ fontSize: "2.7vh" }}>{survey?.type === undefined ? config.strings.notSelected : surveyTypes[survey?.type]}</b></Tag>
                <DivButtons
                  width={"100%"}
                  defaultOption={survey?.type}
                  options={surveyTypes}
                  id={"type"}
                  onClick={surveyActions.update}
                  config={config} />
              </div>

              {/* KIOSK SETTINGS */}
              {survey?.type === "KIOSK" ? (
                <>
                  <div style={RowUp}>
                    <TextPlayStore>
                      <p style={{ margin: "2vh" }}>{config.strings.textPlayStore}</p>
                    </TextPlayStore>
                    <div>
                      <Tooltip title={config.strings.downloadApp} aria-label="add">
                        <Icon2 src={PlayStoreIcon} onClick={surveyActions.openApp} />
                      </Tooltip>
                    </div>
                  </div>
                  {configVisible ? (
                    <>
                      <div style={Row}>
                        <Checkbox
                          text={"Reiniciar enquesta por inactividad"}
                          checked={survey?.inactivityTimeEnabled}
                          onChange={val => {
                            surveyActions.update("inactivityTimeEnabled", val)
                            if (!val) surveyActions.update("maxInactivityTime", null);
                          }} />
                      </div>

                      {survey?.inactivityTimeEnabled ? (
                        <div style={Row}>
                          <Subtag>Tiempo máximo inactividad:</Subtag>
                          <TextInput
                            value={isNullOrUndefined(survey?.maxInactivityTime) ? 60 : survey?.maxInactivityTime}
                            rootStyles={{ flex: "1.5" }}
                            pattern={"[1-9]{4}"}
                            type="number"
                            maxLength={4}
                            unit="segs"
                            onChange={(e) => surveyActions.update("maxInactivityTime", e.target.value)} />
                        </div>
                      ) : (
                        <></>
                      )}

                      <div style={Row}>
                        <Subtag>Pin de desbloqueo:</Subtag>
                        <TextInput
                          value={survey?.unlockPin}
                          rootStyles={{ flex: "1.5" }}
                          type="number"
                          pattern={"[1-9]{4}"}
                          maxLength={4}
                          onChange={(e) => surveyActions.update("unlockPin", e.target.value)} />
                      </div>
                      <div style={Row}>
                        <Subtag>Mensaje de despedida:</Subtag>
                        <TranslatableInput
                          id="endMessage"
                          config={config}
                          rootStyles={{ flex: "1.5" }}
                          texts={survey?.endMessage}
                          onChange={surveyActions.update} />
                      </div>
                      <div style={TightRow}>
                        <Checkbox
                          text={"Mostrar pantalla de bienvenida"}
                          checked={survey?.preScreen?.enabled}
                          onChange={val => surveyActions.updatePreScreen("enabled", val)} />
                      </div>

                      {survey?.preScreen?.enabled ? (
                        <div style={WelcomeConfig}>
                          <Headertag>Pantalla bienvenida</Headertag>
                          <div style={TightRow}>
                            <Subtag>Mensaje de bienvenida:</Subtag>
                            <TranslatableInput
                              id="header"
                              config={config}
                              rootStyles={{ flex: "1.5" }}
                              texts={survey?.preScreen?.header}
                              onChange={surveyActions.updatePreScreen} />
                          </div>
                          <div style={TightRow}>
                            <Subtag>Texto boton inicio:</Subtag>
                            <TranslatableInput
                              id="startButtonText"
                              config={config}
                              rootStyles={{ flex: "1.5" }}
                              texts={survey?.preScreen?.startButtonText}
                              onChange={surveyActions.updatePreScreen} />
                          </div>
                          <div style={TightRow}>
                            <Subtag>Video de fondo:</Subtag>
                            <VideoPicker
                              config={config}
                              id="backgroundVideo"
                              folder="videos"
                              rootStyles={{ flex: "1.5" }}
                              videoName={survey?.preScreen?.backgroundVideo}
                              onSelect={surveyActions.updatePreScreen} />
                          </div>
                          <Checkbox
                            text={"Confirmar mayoria de edad"}
                            checked={survey?.preScreen?.checkLegalAge}
                            onChange={val => surveyActions.updatePreScreen("checkLegalAge", val)} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>{ /* CONFIG NOT VISIBLE */}</>
                  )}
                  <Action onClick={() => { surveyActions.setConfigVisible(!configVisible) }}>
                    {configVisible ? config?.strings?.hintConfig : config?.strings?.showConfig}
                    <IconAction src={configVisible ? TopIcon : BottomIcon} />
                  </Action>
                </>


                /*BUTTON_PANEL SETTINGS*/
              ) : survey?.type === "BUTTON_PANEL" ? (
                <div style={Row}>
                  <div style={Column}>
                    <Tag style={{ marginBottom: "0" }}>{config.getText("fromDate")}</Tag>
                    <DateTimeTicker
                      id="fromDate"
                      value={survey?.fromDate}
                      onChange={surveyActions.update}
                      width={"calc(99% - 1rem) "}
                      style="max-height: calc(2vh + 0.3rem);" />
                  </div>

                  <div style={Column}>
                    <Tag style={{ marginBottom: "0" }}>{config.getText("toDate")}</Tag>
                    <DateTimeTicker
                      id="toDate"
                      value={survey?.toDate}
                      onChange={surveyActions.update}
                      width={"calc(99% - 1rem)"}
                      style="max-height: calc(2vh + 0.3rem);" />
                  </div>
                </div>

                /*WEB SETTINGS*/
              ) : survey?.type === "WEB" ? (
                <div style={RowUp}>
                  {surveyId === undefined ?
                    <Tooltip title={config.strings.copy} aria-label="add">
                      <CopyText>
                        <p style={{ margin: "2vh" }}>{config.strings.noSurveyId}</p>
                      </CopyText>
                    </Tooltip>
                    :
                    <CopyToClipboard text={URL}>
                      <Tooltip title={config.strings.copy} aria-label="add">
                        <CopyText>
                          <p style={{ margin: "2vh", fontSize: "1.7vh" }}>{URL !== null ? URL : ""}</p>
                          <Icon src={IconClipBoard}></Icon>
                        </CopyText>
                      </Tooltip>
                    </CopyToClipboard>
                  }
                  <div>
                    <Tooltip title={config.strings.openSurvey} aria-label="add">
                      <Icon src={PlayIcon} onClick={surveyId === undefined ? null : surveyActions.openSurveyApp} />
                    </Tooltip>
                  </div>
                </div>

                /*INTERVIEWER SETTINGS*/
              ) : survey?.type === "INTERVIEWER" ? (
                <div style={RowUp}>
                  <TextPlayStore>
                    <p style={{ margin: "2vh" }}>{config.strings.textPlayStore}</p>
                  </TextPlayStore>
                  <div>
                    <Tooltip title={config.strings.downloadApp} aria-label="add">
                      <Icon2 src={PlayStoreIcon} onClick={surveyActions.openApp} />
                    </Tooltip>
                  </div>
                </div>

                /* OTHER TYPES SETTINGS*/
              ) : (
                <></>
              )}
            </div>

            {surveyActions?.isCustomBackgroundAllowed() ? (
              <div style={Column}>
                <div style={Item}>
                  <Tag>{config?.strings?.background}</Tag>
                  <ImagePicker
                    width="100%"
                    height="100%"
                    id="background"
                    imageName={survey?.background}
                    onSelect={surveyActions.update}
                  />
                </div>
              </div>
            ) : (
              <div style={Column}>
                <div style={Item}>
                  <Tag>{surveyActions?.isAddQuestionAllowed() ? config?.strings?.backgroundKiosk : config?.strings?.backgroundButton}</Tag>
                  <div style={ImageContainer}>
                    <img src={require(`../../../../../../assets/images/${survey?.type}.png`)} style={ImageContainer2} />
                  </div>
                  <p>{config?.strings?.emailDispositivo}</p>
                  {configVisible ?
                    <div style={Item}>
                      <Tag>{config?.strings?.background}</Tag>
                      <ImagePicker
                        width="100%"
                        height="100%"
                        id="background"
                        imageName={survey?.background}
                        onSelect={surveyActions.update}
                      />
                    </div>
                    : <></>
                  }
                </div>
              </div>
            )}

          </div>

          <div style={Column}>
            <div style={QuestionHeaderWrapper}>
              <div style={QuestionHeaderTag}>{config?.strings?.textPreguntas}</div>

              {surveyActions.isAddQuestionAllowed() ? (
                <Button value={config.strings.addQuestion} action={screenActions.add} />
              ) : (
                <></>
              )}

            </div>
            <ScreenEngine config={config} survey={survey} surveyActions={surveyActions} screenActions={screenActions} />

            {surveyActions.isAddQuestionAllowed() ? (
              <Button value={config.strings.addQuestion} action={screenActions.add} style="margin: auto;" />
            ) : (
              <></>
            )}

          </div>
        </div>

        <ButtonWrapper>
          <SecondaryButton value={config.strings.cancel} action={cancelAction} style="margin: 0 1vh 0 auto" />
          <Button value={config.strings.guardar} action={confirmAction} style="margin: 0 auto 0 0" />
        </ButtonWrapper>

      </div>
    </div >
  );
};
export default SurveyFormComponent;

const Strings = {
    es_ES: require("./strings-ES.json"),
    ca_ES: require("./strings-CA.json"),
    en_GB: require("./strings-EN.json")
}

class StringsLoader {
    DEFAULT_LANG = "es_ES";

    constructor() {
        // Singleton instance
        if (typeof StringsLoader.instance === "object") {
            return StringsLoader.instance;
        } else {
            StringsLoader.instance = this;
            return this;
        }
    }

    getDefaultStrings() {
        return (Strings[this.DEFAULT_LANG]);
    }

    // Devuelve el objeto con los strings en el idioma seleccionado
    getStrings(lang) {
        if (this.DEFAULT_LANG === lang || !(lang in Strings)) {
            return (Strings[this.DEFAULT_LANG]);
        } else {
            let selectedTxts = Strings[lang];
            this.fillIntegity(Strings[this.DEFAULT_LANG], selectedTxts);
            return (selectedTxts);
        }
    };

    // Rellena las propiedades del objeto toObj que no estan, comparandolo
    // con el fromObj. Usdo para los idiomas.
    fillIntegity(fromObj, toObj) {
        for (let prop in fromObj) {
            if (typeof fromObj[prop] === "object" && fromObj[prop] !== null) {
                if (toObj[prop] == null) {
                    toObj[prop] = {};
                }
                this.fillIntegity(fromObj[prop], toObj[prop]);
            } else {
                if (toObj[prop] == null) {
                    toObj[prop] = fromObj[prop];
                    //console.log("Translation missing " + prop);
                }
            }
        }
    };
}
export default StringsLoader;
import React, { useState } from "react";
import styled from "@emotion/styled";
import DeleteIcon from "../../../../../../../../assets/images/cross-icon.svg";

const FilterItem = ({ config, filterActions, survey, questionId, optionId }) => {

    const deleteFilter = () => {
        filterActions.deleteFilter(questionId);
    }

    const getQuestion = () => {
        let screeen = survey?.screens?.find((s) => s?.questions?.some((q) => q?.id === questionId));
        let question = screeen?.questions?.find((q) => q?.id === questionId);
        return question;
    }

    const getOption = () => {
        let question = getQuestion();
        let option = question?.options?.find((o) => o?.id === optionId);
        return option;
    }

    const getQuestionText = () => {
        let question = getQuestion();
        return config?.getTranslatedText(question?.questionTitle);
    }

    const getOptionsText = () => {
        let option = getOption();
        return config?.getTranslatedText(option?.option);
    }

    const Wrapper = {
        backgroundColor: "lightgray",
        borderRadius: "3vmin",
        padding: "1vh 2vh",
        margin: "0 1vh",
        display: "flex"
    }

    const QuestionText = styled.div`
        font-weight: bold;
    `;

    const Sepatation = styled.div`
        width: 2vh;
        text-align: center;
    `;

    const OptionText = styled.div`

    `;

    const DeleteIconImg = styled.img`
        height: 1.5vh;
        width:  1.5vh;
        cursor: pointer;
        margin: auto 0 auto 1.5vh;
    `;

    return (
        <div style={Wrapper}>
            <QuestionText>{getQuestionText()}</QuestionText>
            <Sepatation>{" = "}</Sepatation>
            <OptionText>{getOptionsText()}</OptionText>
            <DeleteIconImg src={DeleteIcon} onClick={deleteFilter} />
        </div>
    );
}

export default FilterItem;
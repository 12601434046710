import React from "react";
import styled from "@emotion/styled";
import QuestionGraphComponent from "./Components/QuestionGraphComponent";
import Button from "../../../../../../shared/Button";
import QuestionFreeComponent from "./Components/QuestionFreeComponent";
import SecondaryButton from "../../../../../../shared/SecondaryButton";
import FilterPopup from "./Components/FilterPopup";
import FilterItem from "./Components/FilterItem";

const ReportGraphsComponent = ({ config, survey, responses, filtersConfig, reportData, actions, filterActions }) => {
    const Wrapper = {
        width: "100%",
        position: "relative"
    }

    const FiltersWrapper = {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingBottom: "2vh",
        rowGap: "1vh"
    }

    const FiltersHeader = styled.div`
        font-size: 2.5vh;
        margin-right: 2vh;
    `;

    const Tag = styled.div`
        font-size: 2.7vh;
    `;

    const Header = styled.div`
        flex: 1;
        max-height: 2.7vh;
        border-bottom: 1px solid grey;
        margin-top: 1vh;
        margin-bottom: 2vh;
        padding-bottom: 2vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
    `;  

    const GrpahsWrapper = {
        width: "100%",
        marginBottom: "2vh"
    }

    return (
        <div style={Wrapper}>

            {Object.entries(filtersConfig?.filters || {}).length > 0 ? (
                <div style={FiltersWrapper}>
                    <FiltersHeader>{config?.getText("filters")}:</FiltersHeader>

                    {Object.entries(filtersConfig?.filters || {}).map(([questionId, optionId]) => {
                        return <FilterItem
                            config={config}
                            survey={survey}
                            filterActions={filterActions}
                            questionId={questionId}
                            optionId={optionId} />
                    })}
                </div>
            ) : (
                <></>
            )}

            <Header>
                <Tag>{config.strings.textPreguntas}</Tag>

                <div>
                    <SecondaryButton value={config.getText("addFilter")} action={filterActions.openFilterPopup} style={"margin-right:2vh"} />
                    <Button value={config?.strings?.rawData} action={() => { actions.updateReportData("mode", "RAW") }} />
                </div>
            </Header>

            <div style={GrpahsWrapper}>
                {(survey?.screens || []).map((screen, screenIndex) => {
                    return (screen?.questions || []).map((question, questionIndex) => {
                        return (question?.type === "FREE_TEXT" || question?.type === "EMAIL" ? (
                            <QuestionFreeComponent
                                config={config}
                                question={question}
                                screenIndex={screenIndex}
                                questionIndex={questionIndex}
                                isMultipleQuestion={screen?.questions?.length > 1}
                                actions={actions} />
                        ) : (
                            <QuestionGraphComponent
                                config={config}
                                question={question}
                                screenIndex={screenIndex}
                                questionIndex={questionIndex}
                                isMultipleQuestion={screen?.questions?.length > 1}
                                actions={actions} />
                        ));
                    });
                })}
            </div>

            {filterActions?.config?.popupVisible ? (
                <FilterPopup config={config} filterActions={filterActions} survey={survey} />
            ) : (
                <></>
            )}
        </div>
    );

};
export default ReportGraphsComponent;
import React, { useState } from "react";
import styled from "@emotion/styled";
import TranslatableInput from "../../../../../../shared/TranslatableInput";
import OptionEngine from "./OptionEngine";
import Button from "../../../../../../shared/Button";
import Select from "../../../../../../shared/Select";
import Checkbox from "../../../../../../shared/Checkbox";
import EditIcon from "../../../../../../../assets/images/edit-icon.svg";
import CollapseIcon from "../../../../../../../assets/images/collapse-icon.svg";
import { isNullSelecction } from "../../../../../../../assets/utils/FormsUtils";
import SecondaryButton from "../../../../../../shared/SecondaryButton";

const QuestionComponent = ({ config, expanded, setExpanded, screenIndex, questionIndex, question, isMultipleQuestion, types, questionActions, optionActions }) => {

    const Wrapper = {
        background: "white",
        borderRadius: "1vh",
        width: "calc(100% - 8vh)",
        margin: "2vh",
        marginTop: (questionIndex === 0) ? "2vh" : "0vh",
        padding: expanded ? "0.5vh 2vh" : "2vh",
        display: "flex",
        flexDirection: expanded ? "column" : "row",
        cursor: expanded ? "auto" : "pointer",
    };

    const MainHeaderWrapper = {
        position: "relative",
        display: "flex",
        background: expanded ? "none" : "lightgray",
        color: expanded ? "black" : "white",
        borderRadius: "1vh",
        height: expanded ? "auto" : "4vh",
        width: expanded ? "auto" : "4vh",
        margin: expanded ? "" : "auto 0",

    }

    const QuestionWrapper = {
        display: "flex",
        flexDirection: expanded ? "column" : "",
        flex: expanded ? "" : "1",
    }

    const Question = {
        margin: "auto 2vh",
        fontSize: "2.3vh",
        fontWeight: "bold",
        flex: "2",
    }

    const EmptyQuestion = {
        ...Question,
        fontWeight: "normal",
        fontStyle: "italic",
        color: "lightgray"
    }

    const Atribute = {
        ...Question,
        fontWeight: "normal",
        color: "gray",
        flex: "1",
    }

    const EmptyAtribute = {
        ...Atribute,
        fontStyle: "italic",
        color: "lightgray"
    }

    const Row = {
        display: "flex",
        flexDirection: "row",
        width: "100%"
    }

    const OptionsWrapper = {
        display: expanded ? "grid" : "none",
        width: "100%",
        maxWidth: "100%",
        overflow: "auto",
        marginBottom: "1vh",
        overflow: "auto",
    }

    const Options = {
        width: "100%",
        maxWidth: "100%",
        display: "flex",
        flexWrap: optionActions.isIconType() ? "nowrap" : "wrap",
        flexDirection: "row",
        justifyContent: "center",
        justifyContent: "flex-start",
        marginTop: "1vh",
        columnGap: "1vh",
        rowGap: "1vh",
        paddingBottom: "1vh",
        alignItems: "flex-start",
        overflow: "auto",
    }

    const HeaderWrapper = {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        marginBottom: "1vh",
        padding: "2vh 0",
        borderBottom: "1px solid grey",
    }

    const HeaderTag = {
        margin: "auto",
        fontSize: "2.5vh",
        flex: "1"
    }

    const AtributTag = {
        margin: "1vh 0",
        fontSize: "2.1vh",
        flex: "1"
    }

    const ButtonStyle = {
        display: expanded ? "inline-block" : "none",
        margin: "0.5vh auto 1.5vh",
    }

    const QuestionIndex = styled.div`
        margin: ${expanded ? "2vh 0" : "auto"};
        font-size: 2.5vh;
        font-weight: bold;
        flex:  ${expanded ? "1" : "0"};
    `;

    const Icon = styled.img`
        height: 3vh;
        width: 4vh;
        margin: auto;
        cursor: pointer;
        flex: 0;
    `;

    const CloseIcon = styled.img`
        position: absolute;
        right: 0;
        top: 1vh;
        height: 3.5vh;
        width: 3.5vh;
        margin: auto;
        cursor: pointer;
    `;

    let questionNum = "" + (screenIndex + 1) + (isMultipleQuestion ? ("." + (questionIndex + 1)) : "")

    return (
        <div style={Wrapper} onClick={() => { if (!expanded) setExpanded(true) }}>
            <div style={MainHeaderWrapper}>
                {expanded ? (
                    <CloseIcon src={CollapseIcon} onClick={() => { if (expanded) setExpanded(false) }} />
                ) : (
                    <QuestionIndex>{questionNum}</QuestionIndex>
                )}
            </div>
            <div style={QuestionWrapper}>
                {expanded ? (
                    <>
                        <div style={HeaderWrapper}>
                            <div style={HeaderTag}>{config?.strings?.textPregunta} {questionNum}</div>
                        </div>
                        <div style={Row}>
                            <div style={{ flex: "4", marginRight: "2vh" }}>
                                <div style={AtributTag}>{config.getText("questionTitle")}</div>
                                <TranslatableInput
                                    compId={`questionTitle-${screenIndex}-${questionIndex}`}
                                    id={"questionTitle"}
                                    config={config}
                                    width="100%"
                                    texts={question?.questionTitle}
                                    onChange={questionActions.update} />
                            </div>
                            <div style={{ flex: "1.5" }}>
                                <div style={AtributTag}>{config.getText("questionType")}</div>
                                <Select
                                    width={"100%"}
                                    defaultOption={question?.type}
                                    options={types}
                                    id={"type"}
                                    disabled={!questionActions.isTypeModificable()}
                                    onChange={questionActions.update} />
                            </div>
                        </div>
                        {questionActions.containSubtitle() ? (
                            <div style={Row}>
                                <div style={{ flex: "1" }}>
                                    <div style={AtributTag}>{config.getText("questionSubtitle")}</div>
                                    <TranslatableInput
                                        id="questionSubtitle"
                                        config={config}
                                        width="100%"
                                        texts={question?.questionSubtitle}
                                        onChange={questionActions.update} />
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        {question?.type === "EMAIL" ? (
                            <div style={{ ...Row, marginTop: "1.5vh" }}>
                                <Checkbox
                                    text={config.getText("enableNewsletter")}
                                    checked={question?.newsletterEnabled}
                                    wrapperStyle={{ flex: "4" }}
                                    onChange={(selected) => { questionActions.update("newsletterEnabled", selected) }} />

                                <SecondaryButton
                                    value={config.getText("editRGPD")}
                                    style={{ flex: "1", height: "fit-content" }}
                                    action={questionActions.openRGPDEditor} />
                            </div>
                        ) : (
                            <></>
                        )}
                    </>
                ) : (
                    <>
                        <div style={config?.getTranslatedText(question?.questionTitle) ? Question : EmptyQuestion}>
                            {config?.getTranslatedText(question?.questionTitle) || "Not defined question"}
                        </div>
                        <div style={isNullSelecction(question?.type) ? EmptyAtribute : Atribute}>
                            {config.getText(question?.type) || "Not defined type"}
                        </div>
                        <div style={Atribute}>{(question?.options || []).length} {config?.strings?.textOpciones}</div>
                    </>
                )}
            </div>
            {questionActions.containOptions() ? (
                <div style={OptionsWrapper}>
                    <div style={HeaderWrapper}>
                        <div style={HeaderTag}>{config?.strings?.tituloOpciones}</div>
                    </div>
                    <div style={Options}>
                        {(question?.options || []).map((option, i) => {
                            return (
                                <OptionEngine
                                    config={config}
                                    id={"op" + i}
                                    question={question}
                                    screenIndex={screenIndex}
                                    questionIndex={questionIndex}
                                    optionIndex={i}
                                    option={option}
                                    optionActions={optionActions} />
                            );
                        })}
                    </div>
                </div>
            ) : (
                <div style={{ height: "3vh" }} />
            )}
            {expanded ? (
                !questionActions.isIconType() && questionActions.containOptions() ? (
                    <Button value={config?.strings?.addOpciones} action={optionActions.add} style={ButtonStyle} />
                ) : (
                    <></> // ICON OPTION  
                )
            ) : (
                <Icon src={EditIcon} onClick={() => { if (!expanded) setExpanded(true) }} />
            )}
        </div >
    );
};

export default QuestionComponent;

import React, { useState, useEffect } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { adaptButtonPanelResponses } from "../../../../../../assets/adapter/ResponsesAdapter";
import Firebase from "../../../../../../assets/Firebase";
import { getEndOfCurrentMonth, getStartOfCurrentMonth } from "../../../../../../assets/utils/DateUtils";
import LoadingScreen from "../../../../../shared/LoadingScreen";
import ReportComponent from "./ReportComponent";

const ReportEngine = () => {
    const config = useOutletContext();
    const { surveyId } = useParams();
    const f = new Firebase();

    let loadingSurvey = false;
    let loadingResponses = false;

    const [survey, setSurvey] = useState(null);
    const [responses, setResponses] = useState(null);
    const [devices, setDevices] = useState({});
    const [reportData, setReportData] = useState({
        fromDate: getStartOfCurrentMonth(),
        toDate: getEndOfCurrentMonth(),
        mode: "GRAPHS",
        dateEdiatble: true 
    });

    const [filtersConfig, setFiltersConfig] = useState({
        popupVisible: false,
        filters: {}
    });

    const loadSurvey = () => {
        loadingSurvey = true;
        f.loadCompleteSurvey(surveyId, (survey) => {
            console.debug("Survey loaded: ", survey);
            setSurvey(survey);

            if (survey.type === "BUTTON_PANEL") {
                setReportData((prev) => ({
                    ...prev,
                    dateEdiatble: false,
                    toDate: survey?.toDate?.toDate() || getEndOfCurrentMonth(),
                    fromDate: survey?.fromDate?.toDate() || getStartOfCurrentMonth()
                }))
            }

            loadingSurvey = false;
        }, (error) => {
            console.error("Error loading survey: ", error);
            loadingSurvey = false;
        });
    }

    const loadResponses = () => {
        setResponses(null);
        loadingResponses = true;
        f.loadSurveyResponses(surveyId, true, reportData?.fromDate, reportData?.toDate,
            onResponsesLoaded,
            onResponsesLoadingFail
        );
    }

    const loadButtonPanelResponses = () => {
        setResponses(null);
        loadingResponses = true;

        f.loadButtonPanelResponses(reportData?.fromDate, reportData?.toDate, (buttonPanelResponses) => {
            let responses = adaptButtonPanelResponses(survey, buttonPanelResponses);
            onResponsesLoaded(responses);
        }, onResponsesLoadingFail);
    }

    const onResponsesLoaded = (responses) => {
        console.debug("Responses loaded: ", responses);
        setResponses(responses);
        loadResponsesDevices(responses);
        loadingResponses = false;
    }

    const onResponsesLoadingFail = (error) => {
        console.error("Error loading responses: ", error);
        loadingResponses = false;
    }

    const loadResponsesDevices = (responses) => {
        let devicesToLoad = [];
        let readDevices = {};
        let read = 0;

        responses.forEach((response, i) => {
            let devId = response.macaddress;
            if (devId !== null && devId !== undefined && devId.length > 0 && !(devId in devices) && !(devicesToLoad.includes(devId))) {
                devicesToLoad.push(response.macaddress);
            }
        });

        devicesToLoad.forEach(deviceId => {
            f.getSimpleDeviceById(deviceId, (device) => {
                readDevices[deviceId] = device;
                if (++read == devicesToLoad.length) {
                    setDevices((prev) => ({
                        ...prev,
                        ...readDevices,
                    }));
                }
            }, (error) => {
                console.error("Error loading device: ", deviceId, error);
                ++read;
            })
        });
    }   

    const updateReportData = (key, value) => {
        setReportData((prev) => ({
            ...prev,
            [key]: value
        }))
    }

    const setFilterPopupVisible = (visible) => {
        setFiltersConfig((prevState) => ({
            ...prevState,
            popupVisible: visible,
        }));
    }

    const openFilterPopup = () => {
        setFilterPopupVisible(true)
    }

    const closeFilterPopup = () => {
        setFilterPopupVisible(false);
    }

    const addFilter = (questionId, optionId) => {
        setFiltersConfig((prevState) => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                [questionId]: optionId
            }
        }));
    }

    const deleteFilter = (questionId) => {
        setFiltersConfig((prevState) => {
            const state = { ...prevState };
            delete state?.filters?.[questionId];
            return state;
        });
    }

    const getFilteredResponses = () => {
        if (Object.entries(filtersConfig.filters).length > 0) {

            let filteredResponses = responses?.filter((response) => {
                let result = true;

                Object.entries(filtersConfig.filters).forEach(([fQuestionId, fOptionId]) => {
                    let question = response?.answers?.find((q) => q.questionReference.id === fQuestionId);
                    if (question) {
                        let containsOption = question?.options?.some((op) => op.optionReference.id === fOptionId)
                        if (!containsOption) {
                            result = false;
                        }
                    } else {
                        result = false;
                    }
                });

                return result;
            });

            return filteredResponses;
        }
        return responses;
    }


    useEffect(() => {
        if (!surveyId) return;
        if (survey == null && !loadingSurvey) loadSurvey();
        //if (responses == null && !loadingResponses) loadResponses();
    }, [surveyId]);

    useEffect(() => {
        if (survey != null && responses == null && !loadingResponses) {
            if (survey.type === "BUTTON_PANEL") {
                loadButtonPanelResponses();
            } else {
                loadResponses();
            }
        }
    }, [survey]);

    return (
        <>
            {survey && responses ? (
                <ReportComponent
                    config={config}
                    survey={survey}
                    responses={responses}
                    filtersConfig={filtersConfig}
                    devices={devices}
                    reportData={reportData}
                    actions={{
                        getFilteredResponses: getFilteredResponses,
                        updateReportData: updateReportData,
                        applyNewDates: loadResponses
                    }}
                    filterActions={{
                        config: filtersConfig,
                        openFilterPopup: openFilterPopup,
                        closeFilterPopup: closeFilterPopup,
                        addFilter: addFilter,
                        deleteFilter: deleteFilter,
                    }} />
            ) : (
                <LoadingScreen />
            )}
        </>
    );
};

export default ReportEngine;

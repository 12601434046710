import React, { useEffect, useState } from "react";
import QuestionComponent from "./QuestionComponent";
import DefaultOptions from "../../../../../../../assets/utils/DefaultOptions.js";

const QuestionEngine = ({ config, survey, screenIndex, questionIndex, question, isMultipleQuestion, maximized, questionActions }) => {
    const [expanded, setExpanded] = useState(maximized ? maximized : false);

    const QuestionTypes = {
        "notSelected": config.strings.notSelected,
        "MONOOPTION": config.strings.MONOOPTION,
        "MULTIOPTION": config.strings.MULTIOPTION,
        "SATISFACTION": config.strings.SATISFACTION,
        "SATISFACTION_EXTENDED": config.strings.SATISFACTION_EXTENDED,
        "YES_NO": config.strings.YES_NO,
        "NPS": config.strings.NPS,
        "FREE_TEXT": config.strings.FREE_TEXT,
        "EMAIL": config.strings.EMAIL,
    }

    const MultiQuestionTypes = {
        "notSelected": config.strings.notSelected,
        "MONOOPTION": config.strings.MONOOPTION,
        "MULTIOPTION": config.strings.MULTIOPTION
    }

    const loadDefaultOptions = (force) => {
        if (question && question.type) {
            if (question?.options?.length !== 0 && !force) return;

            let defaultOptionsOfType = structuredClone(DefaultOptions[question.type] || []);
            updateCurrentQuestion("options", defaultOptionsOfType);
        }
    }

    const updateCurrentQuestion = (key, value) => {
        let previousValue = question[key];

        questionActions.update(screenIndex, questionIndex, key, value);

        if (key === "type") {
            let isMonoToMulipleChange = (
                (previousValue === "MONOOPTION" && value === "MULTIOPTION") ||
                (previousValue === "MULTIOPTION" && value === "MONOOPTION")
            );

            if (!isMonoToMulipleChange) {
                loadDefaultOptions(true);
            }
        }
    }

    const updateOption = (optionIndex, key, value) => {
        let options = question?.options;
        let option = options?.[optionIndex];
        if (option) {
            option[key] = value;
            updateCurrentQuestion("options", options);
        }
    }

    const addOption = () => {
        let options = question?.options;
        let optionIndex = options.push({}) - 1;
        updateCurrentQuestion("options", options);
        config.focusComponent(`option-${screenIndex}-${questionIndex}-${optionIndex}`);
    }

    const deleteOption = (index) => {
        let options = question?.options;
        if (options) {
            options.splice(index, 1);
            updateCurrentQuestion("options", options);
        }
    }

    const moveOptionUp = (index) => {
        if (index > 0) {
            let options = question?.options;
            let option = options[index];
            options.splice(index, 1);
            options.splice((index - 1), 0, option);
            updateCurrentQuestion("options", options);
        }
    }

    const moveOptionDown = (index) => {
        let options = question?.options;
        if (index < options.length) {
            let option = options[index];
            options.splice(index, 1);
            options.splice((index + 1), 0, option);
            updateCurrentQuestion("options", options);
        }
    }

    const openRGPDEditor = () => {
        window.open("/dashboard/profile/RGPD", '_blank', 'noopener,noreferrer');
    }

    const containSubtitle = () => {
        let type = question?.type;
        return type === "EMAIL";
    }

    const containOptions = () => {
        let type = question?.type;
        return type !== null &&
            type !== undefined &&
            type !== "EMAIL" &&
            type !== "FREE_TEXT" &&
            type !== "EMAIL" &&
            type !== "notSelected";
    }

    const isIconType = () => {
        let type = question?.type;
        return type === "SATISFACTION" ||
            type === "SATISFACTION_EXTENDED" ||
            type === "ICON_OPTIONS" ||
            type === "YES_NO" ||
            type === "NPS";
    }

    const isNPSType = () => {
        let type = question?.type;
        return type === "NPS";
    }

    const isTypeModificable = () => {
        return survey?.type !== "BUTTON_PANEL";
    }

    useEffect(() => {
        loadDefaultOptions(false);
    }, [question.type]);

    return (
        <QuestionComponent
            expanded={expanded}
            setExpanded={setExpanded}
            config={config}
            screenIndex={screenIndex}
            questionIndex={questionIndex}
            question={question}
            isMultipleQuestion={isMultipleQuestion}
            types={isMultipleQuestion ? MultiQuestionTypes : QuestionTypes}
            questionActions={{
                update: updateCurrentQuestion,
                isIconType: isIconType,
                containOptions: containOptions,
                containSubtitle: containSubtitle,
                openRGPDEditor: openRGPDEditor,
                isTypeModificable: isTypeModificable
            }}
            optionActions={{
                update: updateOption,
                add: addOption,
                delete: deleteOption,
                moveUp: moveOptionUp,
                moveDown: moveOptionDown,
                isIconType: isIconType,
                isNPSType: isNPSType,
            }} />
    );
};

export default QuestionEngine;

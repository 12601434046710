import React, { useState } from "react";
import OptionComponent from "./OptionComponent";

const OptionEngine = ({ config, question, screenIndex, questionIndex, optionIndex, option, optionActions }) => {
    const updateCurrentOption = (key, value) => {
        optionActions.update(optionIndex, key, value);
        if (option?.metadata?.focues) {
            updateCurrentOption("metadata", {
                ...option?.metadata,
                focuses: false
            })
        }
    }

    const deleteCurrentOption = () => {
        optionActions.delete(optionIndex);
    }

    const moveUpCurrentOption = () => {
        optionActions.moveUp(optionIndex);
    }

    const moveDownCurrentOption = () => {
        optionActions.moveDown(optionIndex);
    }

    const updateSuboption = (index, key, value) => {
        let suboptions = option?.suboptions;
        let suboption = suboptions?.[index];
        if (suboption && suboptions) {
            suboption[key] = value;
            updateCurrentOption("suboptions", suboptions);
        }
    }

    const addSuboption = () => {
        let suboptions = option?.suboptions || [];
        let suboptionIndex = suboptions.push({}) - 1;
        updateCurrentOption("suboptions", suboptions);
        config.focusComponent(`suboption-${screenIndex}-${questionIndex}-${optionIndex}-${suboptionIndex}`);
    }

    const moveSuboptionUp = (index) => {
        if (index > 0) {
            let suboptions = option?.suboptions;
            let suboption = suboptions[index];
            suboptions.splice(index, 1);
            suboptions.splice((index - 1), 0, suboption);
            updateCurrentOption("suboptions", suboptions);
        }
    }

    const moveSuboptionDown = (index) => {
        let suboptions = option?.suboptions;
        if (index < suboptions.length) {
            let suboption = suboptions[index];
            suboptions.splice(index, 1);
            suboptions.splice((index + 1), 0, suboption);
            updateCurrentOption("suboptions", suboptions);
        }
    }

    const deleteSuboption = (index) => {
        let suboptions = option?.suboptions;
        if (suboptions) {
            suboptions.splice(index, 1);
            updateCurrentOption("suboptions", suboptions);
        }
    }

    const getImage = () => {
        try {
            if (optionActions.isIconType()) {
                return require("../../../../../../../assets/images/" + option.icon + ".png");
            }
        } catch (e) {
            return require("../../../../../../../assets/images/edit-icon.svg");
        }
    }

    return (
        <OptionComponent
            config={config}
            screenIndex={screenIndex}
            questionIndex={questionIndex}
            optionIndex={optionIndex}
            option={option}
            isIconType={optionActions.isIconType()}
            isNPSType={optionActions.isNPSType()}
            optionActions={{
                moveUp: moveUpCurrentOption,
                moveDown: moveDownCurrentOption,
                update: updateCurrentOption,
                delete: deleteCurrentOption,
                getImage: getImage,
            }}
            suboptionActions={{
                update: updateSuboption,
                add: addSuboption,
                delete: deleteSuboption,
                moveUp: moveSuboptionUp,
                moveDown: moveSuboptionDown,
            }} />
    );
};

export default OptionEngine;

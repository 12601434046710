import React, { useState } from "react";
import RawReportComponent from "./RawReportComponent";

const RawReportEngine = ({ config, survey, responses, devices, reportData, actions }) => {
    const [tableActions, setTableActions] = useState(null);
    const [includeEmptyResponses, setIncludeEmptyResponses] = useState(true);

    const getOptionByRef = (question, optionRef) => {
        if (!optionRef) return null;

        return (question?.options || []).find(option => option.ref.path === optionRef.path);
    }

    const getSuboptionByRef = (option, suboptionRef) => {
        if (!suboptionRef) return null;
        return (option?.suboptions || []).find(suboption => suboption.ref.path === suboptionRef.path);
    }

    const buildBasicEntry = (index, question, response, answer) => {
        let basicEntry = {
            index: "R" + index,
            creationDate: response?.creationDate?.toDate(),
            lang: response?.responseLanguage,
            device: devices?.[response?.macaddress]?.alias || response?.macaddress || "-",
            ip: response?.ipaddress || "-",
            platform: config.getText(response?.platform) || response?.platform || "-",
            question: config.getTranslatedText(question?.questionTitle)
        }

        return basicEntry;
    }

    const buildQuestionEntry = (index, question, response, answer) => {
        let optionText = "";
        let suboptionText = "";

        (answer?.options || []).map(optionRes => {
            let option = getOptionByRef(question, optionRes?.optionReference);
            optionText += (optionText === "" ? "" : ",") + config.getTranslatedText(option?.option);

            (optionRes?.suboptionsResponses || []).map(suboptionRes => {
                let suboption = getSuboptionByRef(option, suboptionRes?.optionReference);
                if (suboption) {
                    suboptionText += (suboptionText === "" ? "" : ",") + config.getTranslatedText(suboption?.option)
                }
            });
        });

        let questionEntry = {
            ...buildBasicEntry(index, question, response, answer),
            option: optionText || "-",
            suboptionText: suboptionText || "-"
        }

        return questionEntry;
    }

    const buildEmailEntry = (index, question, response, answer, isExcel) => {
        let email = answer?.freeResponses?.email;
        let name = answer?.freeResponses?.name;
        let lopd = answer?.freeResponses?.checkedLOPD;
        let newsletter = answer?.freeResponses?.checkedNewsletter;

        let emailEntry = {
            ...buildBasicEntry(index, question, response, answer),
            option: email || "-",
            suboptionText: name || "-"

        }

        if (isExcel) {
            emailEntry.lopdAccepted = lopd ? true : false;
            emailEntry.newsletterAccepted = newsletter ? true : false;
        }

        return emailEntry;
    }

    const buildFreeTextEntry = (index, question, response, answer) => {
        let text = answer?.freeResponses?.freeText;

        let freeTextEntry = {
            ...buildBasicEntry(index, question, response, answer),
            option: text || "-",
            suboption: "-"
        }

        return freeTextEntry;
    }

    const getReponseEntry = (index, question, response, isExcel) => {
        let answer = (response?.answers || []).find(ans => ans?.questionReference?.path === question?.ref?.path)

        if (question?.type === "EMAIL") {
            return buildEmailEntry(index, question, response, answer, isExcel);
        } else if (question?.type === "FREE_TEXT") {
            return buildFreeTextEntry(index, question, response, answer);
        } else {
            return buildQuestionEntry(index, question, response, answer);
        }
    }

    const isEmptyEntryResponse = (entry) => {
        let emptyValues = [null, undefined, "null", "undefined", "-"];

        let optionIsEmpty = emptyValues.includes(entry?.option);
        let suboptionIsEmpty = emptyValues.includes(entry?.suboption);

        return optionIsEmpty && suboptionIsEmpty;
    }

    const isAllEmptyEntriesResponses = (entries) => {
        return entries.every(isEmptyEntryResponse);
    }

    const getRawData = (isExcel) => {
        let rawData = {};
        let lastRawData = 0
        let responseIndex = 1;

        (responses || []).forEach((response) => {
            let reponseEntries = [];

            (survey.screens || []).forEach(screen => {
                (screen.questions || []).forEach(question => {

                    let entry = getReponseEntry(responseIndex, question, response, isExcel);

                    if (includeEmptyResponses || !isEmptyEntryResponse(entry)) {
                        reponseEntries.push(entry);
                    }

                });
            });

            if (!isAllEmptyEntriesResponses(reponseEntries)) {
                reponseEntries.forEach(entry => {
                    rawData["row" + (lastRawData++)] = entry;
                })
                responseIndex++;
            }
        });

        return rawData;
    }

    return (
        <RawReportComponent
            config={config}
            survey={survey}
            responses={responses}
            reportData={reportData}
            includeEmptyResponses={includeEmptyResponses}
            actions={{
                ...actions,
                getRawData: getRawData,
                dowloadExcel: tableActions?.export,
                setTableActions: setTableActions,
                setIncludeEmptyResponses: setIncludeEmptyResponses
            }} />
    );
}

export default RawReportEngine;
import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import HomeComponent from "./HomeComponent";

const HomeEngine = () => {
  const config = useOutletContext();

  return (
    <HomeComponent config={config} />
  );
};

export default HomeEngine;

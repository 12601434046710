import React, { useState, useEffect } from "react";
import FormComponent from "./SurveyFormComponent";
import { useOutletContext, useNavigate, useParams } from "react-router-dom";
import LoadingScreen from "../../../../../shared/LoadingScreen";
import DownloadingPopup from "../../../../../shared/DownloadingPopup";
import Firebase from "../../../../../../assets/Firebase";
import DefaultSurveyProps from "../../../../../../assets/utils/DefaultSurveyProps";
import ButtonPanelDefaultScreens from "../../../../../../assets/utils/ButtonPanelDefaultScreens";
import { validateSurvey } from "./SurveyValidator";
import { getEndOfCurrentMonth, getStartOfCurrentMonth } from "../../../../../../assets/utils/DateUtils";
import Config from "../../../../../../assets/Config";

const SurveyFormEngine = () => {
    const config = useOutletContext();
    const { surveyId } = useParams();
    const navigate = useNavigate();
    const f = new Firebase();

    let loadingSurvey = false;
    const [survey, setSurvey] = useState(null);
    const [loadingText, setLoadingText] = useState(null);
    const [configVisible, setConfigVisible] = useState(false);
    const [URL, setURL] = useState(null);

    const SurveyTypes = {
        /* "notSelected": config.strings.notSelected, */
        "BUTTON_PANEL": config.strings.botonera,
        "KIOSK": config.strings.kiosco,
        "INTERVIEWER": config.strings.entrevistador,
        "WEB": config.strings.web
    }

    const loadSurvey = () => {
        loadingSurvey = true;
        f.loadCompleteSurvey(surveyId, (survey) => {
            console.debug("Survey loaded: ", survey);
            setSurvey({
                ...DefaultSurveyProps,
                ...survey,
                toDate: survey?.toDate?.toDate(),
                fromDate: survey?.fromDate?.toDate()
            });
            loadingSurvey = false;
        }, (error) => {
            console.error("Error loading survey: ", error);
            loadingSurvey = false;
        });
    }

    const createSurvey = () => {
        console.debug("Survey created");
        setSurvey({
            ...DefaultSurveyProps,
        });
        loadingSurvey = false;
    }

    useEffect(() => {
        if (survey == null && !loadingSurvey) {
            if (surveyId == null) {
                createSurvey();
            } else {
                loadSurvey();
            }
        }
    }, [surveyId]);

    const confirmAction = () => {
        setLoadingText(config.strings.guardando);

        let error = validateSurvey(config, survey);

        if (error != null) {
            setLoadingText(null);
            config.popupAlert(config.strings.error, error);
        } else {
            console.log("Trying to save: ", survey);
            f.saveSurvey(survey, onSuccess, onError);
        }
    }

    const onSuccess = () => {
        setLoadingText(null);

        config.popupAlert(config.strings.correct, config.strings.successMsg, () => {
            navigate(`/dashboard/surveys/list`);
        });
    };

    const onError = (error) => {
        console.log("Error saving survey:", error);
        setLoadingText(null);
        config.popupAlert(config.strings.error, config.strings.alertError);
    }

    const cancelAction = () => {
        navigate(`/dashboard/surveys/list`);
    };

    const updateSurvey = (key, value) => {
        setSurvey((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const updatePreScreen = (key, value) => {
        setSurvey((prevState) => ({
            ...prevState,
            preScreen: {
                ...prevState?.preScreen,
                [key]: value
            }
        }));
    };

    const addScreen = () => {
        config?.popupAlert(config.strings.nameSurvey, config.strings.questionMod, null, false, null, null, null, true, () => {
            succesScreenAdd(true);
        },
            () => {
                succesScreenAdd(false);
            });
    }

    const succesScreenAdd = (multiple) => {
        let questionsNum = multiple ? 2 : 1;
        let newScreen = {
            questions: []
        };
        for (let i = 0; i < questionsNum; i++) {
            newScreen.questions.push({
                options: []
            });
        }
        let screens = survey?.screens || [];
        screens.push(newScreen);
        updateSurvey("screens", screens);
        scrollToScreen(screens.length - 1);
        updateSurvey("metadata", { maximizedScreen: screens.length - 1 });
    }

    const moveScreenUp = (index) => {
        if (index > 0) {
            let screens = survey?.screens;
            let screen = screens[index];
            screens.splice(index, 1);
            screens.splice((index - 1), 0, screen);
            updateSurvey("screens", screens);
            scrollToScreen(index - 1);
        }
    }

    const moveScreenDown = (index) => {
        let screens = survey?.screens;
        if (index < screens.length) {
            let screens = survey?.screens;
            let screen = screens[index];
            screens.splice(index, 1);
            screens.splice((index + 1), 0, screen);
            updateSurvey("screens", screens);
            scrollToScreen(index + 1);
        }
    }

    const deleteScreen = (index) => {
        config?.popupAlert(config?.strings.delete, config?.strings.deleteConfirm, null, true, () => {
            let screens = survey?.screens;
            screens.splice(index, 1);
            updateSurvey("screens", screens);
        });
    }

    const scrollToScreen = (index) => {
        config.focusComponent(`questionTitle-${index}-0`)
    }

    const isAddQuestionAllowed = () => {
        return survey?.type !== "BUTTON_PANEL"
    }

    const isCustomBackgroundAllowed = () => {
        return (survey?.type !== "BUTTON_PANEL") && (survey?.type !== "KIOSK")
    }

    const openApp = () => {
        let url = `https://play.google.com/store/apps/details?id=com.HCB.surveyfactory&hl=es`;
        window.open(url);
    }

    const openSurveyApp = () => {
        new Config();
        let subdomain = Config.ENV === "DEV" || Config.ENV === "LOCAL" ? "dev" : "www";
        let url = `https://${subdomain}.quickratesurvey.com/survey/${f.getUid()}/${surveyId}`;
        window.open(url);
    }

    const showSurveyApp = (result) => {
        new Config();
        let subdomain = Config.ENV === "DEV" || Config.ENV === "LOCAL" ? "dev" : "www";
        let url = `https://${subdomain}.quickratesurvey.com/survey/${f.getUid()}/${surveyId}`;
        result(url)
    }

    useEffect(() => {
        if (survey?.type === "BUTTON_PANEL") {
            if (survey?.screens?.[0]?.id != "DEFAULT_BUTTONS_SCREEN") {
                updateSurvey("screens", ButtonPanelDefaultScreens);
                updateSurvey("metadata", { maximizedScreen: 0 });
                scrollToScreen(1);
            }
            if (!survey?.fromDate) {
                updateSurvey("fromDate", getStartOfCurrentMonth());
            }
            if (!survey?.toDate) {
                updateSurvey("toDate", getEndOfCurrentMonth());
            }
        }
        showSurveyApp((result) => { setURL(result) })
    }, [survey?.type]);

    return (
        <>
            {survey != null ? (
                <FormComponent
                    config={config}
                    survey={survey}
                    surveyTypes={SurveyTypes}
                    configVisible={configVisible}
                    surveyActions={{
                        update: updateSurvey,
                        updatePreScreen: updatePreScreen,
                        isAddQuestionAllowed: isAddQuestionAllowed,
                        isCustomBackgroundAllowed: isCustomBackgroundAllowed,
                        setConfigVisible: setConfigVisible,
                        showSurveyApp: showSurveyApp,
                        openSurveyApp: openSurveyApp,
                        openApp: openApp
                    }}
                    screenActions={{
                        add: addScreen,
                        delete: deleteScreen,
                        moveDown: moveScreenDown,
                        moveUp: moveScreenUp
                    }}
                    confirmAction={confirmAction}
                    cancelAction={cancelAction}
                    URL={URL}
                    surveyId={surveyId}/>
            ) : (
                <LoadingScreen />
            )}
            {loadingText != null ? <DownloadingPopup strings={config.strings} headerText={loadingText} /> : <></>}
        </>
    );
};

export default SurveyFormEngine;

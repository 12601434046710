import { isNullString, isNullSelecction, isNullMap, isNullOrUndefined, isInvalidBoolean, isInvalidFloat } from "../../../../../../assets/utils/FormsUtils";

export const validateDevice = (config, device) => {

    if (device == null) return config.getText("device") + " " + config.getText("cantBeNull");


    if (isNullString(device.alias)) return config.getText("device") + ' ' + config.getText("cantBeNull");

    if (device.alarmEnabled) {

        if (isNullSelecction(device.alertType)) return config.getText("type") + ' ' + config.getText("cantBeNull");

        for (let index in (device.alertEmails || [])) {
            let email = device.alertEmails?.[index];
            let isValid = email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if (!isValid) return config.getText("email") + ' ' + (index) + ' ' + config.getText("cantBeNull");
        }

    }

    return null;
};
import { isNullString, isNullSelecction, isNullMap, isNullOrUndefined, isInvalidBoolean, isInvalidFloat } from "../../../../../../assets/utils/FormsUtils";

export const validateSurvey = (config, survey) => {
    if (survey == null) return config.getText("survey") + " " + config.getText("cantBeNull");

    // Check survey atributes
    if (isNullString(survey.name)) return config.getText("nameEnquesta") + ' ' + config.getText("cantBeNull");
    if (isNullSelecction(survey.type)) return config.getText("typeEnquesta") + ' ' + config.getText("cantBeNull");
    if (survey?.screens?.length <= 0) return config.getText("mustHaveOneQuestion");

    for (let screenIndex in (survey?.screens || [])) {
        let screen = survey?.screens?.[screenIndex];

        // Check screen atributes
        if (screen?.questions?.length <= 0) return config.getText("mustHaveOneQuestion");

        for (let questionIndex in (screen?.questions || [])) {
            let question = screen?.questions?.[questionIndex];

            let questionNum;
            if (screen?.questions?.length > 1) {
                questionNum = `${(parseInt(screenIndex) + 1)}.${(parseInt(questionIndex) + 1)}`;
            } else {
                questionNum = `${(parseInt(screenIndex) + 1)}`;
            }

            let questionParentText = config.getText("ofTheFem") + ' ' + config.getText("question") + ' ' + questionNum;

            // Check question atributes
            if (isNullMap(question.questionTitle))
                return config.getText("questionTitle") + ' ' + questionParentText + ' ' + config.getText("cantBeNull");

            if (isNullSelecction(question.type))
                return config.getText("questionType") + ' ' + questionParentText + ' ' + config.getText("cantBeNull");


            for (let optionIndex in (question?.options || [])) {
                let option = question?.options?.[optionIndex];

                let optionNum = `${(parseInt(optionIndex) + 1)}`;
                let optionParentParentText = config.getText("ofTheFem") + ' ' + config.getText("option") + ' ' + optionNum + ' ' + questionParentText;

                // Check option atributes
                if (isNullMap(option.option))
                    return config.getText("nameOption") + ' ' + optionParentParentText + ' ' + config.getText("cantBeNull");

                for (let suboptionIndex in (option?.suboptions || [])) {
                    let suboption = option?.suboptions?.[suboptionIndex];

                    let suboptionNum = `${(parseInt(suboptionIndex) + 1)}`;
                    let suboptionParentParentText = config.getText("ofTheFem") + ' ' + config.getText("suboption") + ' ' + suboptionNum + ' ' + optionParentParentText;

                    // Check suboption atributes
                    if (isNullMap(suboption.option))
                        return config.getText("nameSuboption") + ' ' + suboptionParentParentText + ' ' + config.getText("cantBeNull");
                }
            }
        };
    }
    return null;
};

export const adaptButtonPanelResponses = (survey, buttonPanelResponses) => {
    let responses = [];

    buttonPanelResponses.forEach((buttonPanelResponse, resIndex) => {
        let response = {
            ref: {
                id: "AdaptedResponse-" + resIndex
            },
            deleted: false,
            surveyReference: getSurveyRef(survey),
            platform: "BUTTON_PANEL",
            macaddress: buttonPanelResponse?.deviceId,
            creationDate: buttonPanelResponse?.creationDate,
            lastUpdate: buttonPanelResponse?.lastUpdate,
            responseLanguage: null,
            answers: [
                {
                    ref: {
                        id: "AdaptedAnswer-" + resIndex + ".1"
                    },
                    deleted: false,
                    questionReference: getQuestionReference(survey),
                    options: [
                        {
                            optionReference: getOptionReference(survey, buttonPanelResponse?.value)
                        }
                    ]
                }
            ]
        }

        responses.push(response);
    });

    return responses;
};

const getSurveyRef = (survey) => {
    return survey?.ref;
};

const getQuestionReference = (survey) => {
    return survey?.screens?.[0]?.questions?.[0]?.ref;
};

const getOptionReference = (survey, value) => {
    return survey?.screens?.[0]?.questions?.[0]?.options?.[value - 1]?.ref;
};
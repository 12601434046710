import React from "react";
import styled from "@emotion/styled";
import TranslatableInput from "../../../../../../shared/TranslatableInput";
import DeleteIcon from "../../../../../../../assets/images/delete-icon.svg";
import TopIcon from "../../../../../../../assets/images/ic_contract.png";
import BottomIcon from "../../../../../../../assets/images/ic_expand.png";
import AddIcon from "../../../../../../../assets/images/add-icon.svg";

const OptionComponent = ({ config, screenIndex, questionIndex, optionIndex, option, isIconType, isNPSType, optionActions, suboptionActions, }) => {
    const Wrapper = {
        padding: "1vh 0",
        display: "flex",
        flexDirection: "column",
        height: isIconType ? (isNPSType && (!(optionIndex == 0 || optionIndex == 9)) ? ("92.5%") : ("auto")) : ("auto"),
        flex: isIconType ? (isNPSType && (!(optionIndex == 0 || optionIndex == 9)) ? ("0 1 calc(12% - 0.75vh)") : ("0 1 calc(25% - 0.75vh)")) : ("0 1 calc(50% - 0.5vh)"),
        minWidth: isIconType ? ( isNPSType && (!(optionIndex == 0 || optionIndex == 9)) ? ("calc(12% - 0.75vh)") : ("calc(25% - 0.75vh)")) : ("calc(50% - 0.5vh)"),
        background: "rgb(237, 237, 237)",
        borderRadius: "1vh",
    };

    const Row = {
        display: "flex",
        flexDirection: "row",
        width: "100%",
    };

    const OptionWrapper = {
        position: "relative",
        display: "flex",
        width: "calc(100% - 2vh)",
        padding: "1vh",
        flexDirection: isIconType ? "column" : "row",
    }

    const SubOptionsWrapper = {
        ...Row,
        flexDirection: "column",
    };

    const SubOptionWrapper = {
        ...Row,
        width: "calc(100% - 3vh)",
        margin: "0.5vh 0 0.5vh 3vh"
    }

    const Index = styled.div`
        width: 4vh;
        margin: auto 0.5vh auto 0;
        text-align: center;
        font-size: 2.5vh;
    `;

    const SubIndex = styled.div`
        width: 3vh;
        margin: auto 1vh auto 0;
        text-align: center;
        font-size: 2.2vh;
    `;

    const InputContainer = {
        flex: "1",
        margin: "auto 0.5vh auto 0",
    }

    const IconWrapper = {
        margin: "0.3vh 1.5vh 0.3vh 0.5vh",
        marginRight: "2vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
    }

    const OptionIconWrapper = styled.div`    
        position: relative;
        justify-content: center;
        align-items: center;
        display: flex;
        height: 15vh;
        width: 15vh;
        
        margin: 0 auto 1vh auto;
    `;

    const OptionIcon = styled.div`
        ${props => `
            height: 90%;
            width: 90%;
            margin: auto;
            background-image: url("${props.src}");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        `};
    `;

    const InsideOptionText = styled.div`
        ${props => `
            position: absolute;
            max-width: 70%;
            font-size: 3.5vh;
            font-weight: bold;
            color:  ${option?.style?.optionTextColor || "white"};
        `};
    `;

    const BigIcon = styled.img`
        height: 2.5vh;
        width: 2.5vh;
        cursor: pointer;
        margin: auto 0.5vh;
    `;

    const Icon = styled.img`
        height: 2vh;
        width: 2vh;
        padding: 0.3vh 0;
        cursor: pointer;
    `

    return (
        <div style={Wrapper}>
            <div style={OptionWrapper}>
                {isIconType ? (
                    <OptionIconWrapper>
                        <OptionIcon src={optionActions.getImage()} />
                        {option?.style?.showInsideOptionText ? (
                            isNPSType ? (<InsideOptionText>{optionIndex + 1}</InsideOptionText>) : (<InsideOptionText>{config.getTranslatedText(option?.option)}</InsideOptionText>)
                        ) : (
                            <></>
                        )}
                    </OptionIconWrapper>
                ) : (
                    <Index>{optionIndex + 1}</Index>
                )}

                {(isNPSType && (!(optionIndex == 0 || optionIndex == 9)) ?
                    (<></>) : (
                        <div style={InputContainer}>
                            <TranslatableInput
                                compId={`option-${screenIndex}-${questionIndex}-${optionIndex}`}
                                id={isNPSType ? "cardOption" : "option"}
                                config={config}
                                rootStyles={{ width: "100%" }}
                                texts={isNPSType ? option?.cardOption : option?.option}
                                focused={option?.metadata?.focused}
                                onChange={optionActions.update}
                            />
                        </div>
                    ))}
                {!isIconType ? (
                    <>
                        <BigIcon src={AddIcon} onClick={suboptionActions.add} />
                        <BigIcon src={DeleteIcon} onClick={optionActions.delete} />
                        <div style={IconWrapper}>
                            <Icon src={TopIcon} onClick={optionActions.moveUp} />
                            <Icon src={BottomIcon} onClick={optionActions.moveDown} />
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </div>
            <div style={SubOptionsWrapper}>
                {(option?.suboptions || []).map((suboption, i) => {
                    return (
                        <div style={SubOptionWrapper}>
                            <SubIndex>{(optionIndex + 1) + "." + (i + 1)}</SubIndex>
                            <div style={InputContainer}>

                                <TranslatableInput
                                    compId={`suboption-${screenIndex}-${questionIndex}-${optionIndex}-${i}`}
                                    id={"option"}
                                    config={config}
                                    rootStyles={{ width: "100%" }}
                                    texts={suboption?.option}
                                    onChange={(id, texts) => suboptionActions.update(i, id, texts)} />
                            </div>

                            <BigIcon src={DeleteIcon} onClick={() => { suboptionActions.delete(i) }} />
                            <div style={IconWrapper}>
                                <Icon src={TopIcon} onClick={() => { suboptionActions.moveUp(i) }} />
                                <Icon src={BottomIcon} onClick={() => { suboptionActions.moveDown(i) }} />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default OptionComponent;

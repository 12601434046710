export default {
    "SATISFACTION": [
        {
            "icon": "face_4",
            "style": {
                "optionTextBackgroundColor": "#e20613",
                "optionTextColor": "#FFFFFF",
                "showOptionText": true
            },
            "option": {
                "es_ES": "Muy mal",
                "ca_ES": "Molt malament",
                "en_GB": "Very bad",
                "de_DE": "Sehr schlecht",
                "fr_FR": "Très mauvais",
                "pt_PR": "Muito ruim",
                "it_IT": "Molto male",
                "ru_RU": "Очень плохо",
                "zh_CN": "非常糟糕",
                "ar_SA": "سيئ جدا",
            }
        },
        {
            "icon": "face_3",
            "style": {
                "optionTextBackgroundColor": "#e9541b",
                "optionTextColor": "#FFFFFF",
                "showOptionText": true
            },
            "option": {
                "es_ES": "Mal",
                "ca_ES": "Malament",
                "en_GB": "Bad",
                "de_DE": "Mal",
                "fr_FR": "Mal",
                "pt_PR": "Mal",
                "it_IT": "Mal",
                "ru_RU": "Мал",
                "zh_CN": "不正确",
                "ar_SA": "خاطئ - ظلم - يظلم",
            }
        },
        {
            "icon": "face_2",
            "style": {
                "optionTextBackgroundColor": "#90c162",
                "optionTextColor": "#FFFFFF",
                "showOptionText": true
            },
            "option": {
                "es_ES": "Bien",
                "ca_ES": "Bé",
                "en_GB": "Good",
                "de_DE": "Gut",
                "fr_FR": "Bien",
                "pt_PR": "Bem",
                "it_IT": "Bene",
                "ru_RU": "Хорошо",
                "zh_CN": "好的",
                "ar_SA": "جيد",
            }
        },
        {
            "icon": "face_1",
            "style": {
                "optionTextBackgroundColor": "#009540",
                "optionTextColor": "#FFFFFF",
                "showOptionText": true
            },
            "option": {
                "es_ES": "Muy bien",
                "ca_ES": "Molt bé",
                "en_GB": "Very good",
                "de_DE": "Sehr gut",
                "fr_FR": "Très bien",
                "pt_PR": "Muito bem",
                "it_IT": "Molto bene",
                "ru_RU": "Очень хорошо",
                "zh_CN": "非常好",
                "ar_SA": "جيد جدا",
            },
        },
    ],
    "SATISFACTION_EXTENDED": [
        {
            "icon": "face_4",
            "style": {
                "optionTextBackgroundColor": "#e20613",
                "optionTextColor": "#FFFFFF",
                "showOptionText": true
            },
            "option": {
                "es_ES": "Muy mal",
                "ca_ES": "Molt malament",
                "en_GB": "Very bad",
                "de_DE": "Sehr schlecht",
                "fr_FR": "Très mauvais",
                "pt_PR": "Muito ruim",
                "it_IT": "Molto male",
                "ru_RU": "Очень плохо",
                "zh_CN": "非常糟糕",
                "ar_SA": "سيئ جدا",
            }
        },
        {
            "icon": "face_3",
            "style": {
                "optionTextBackgroundColor": "#e9541b",
                "optionTextColor": "#FFFFFF",
                "showOptionText": true
            },
            "option": {
                "es_ES": "Mal",
                "ca_ES": "Malament",
                "en_GB": "Bad",
                "de_DE": "Mal",
                "fr_FR": "Mal",
                "pt_PR": "Mal",
                "it_IT": "Mal",
                "ru_RU": "Мал",
                "zh_CN": "不正确",
                "ar_SA": "خاطئ - ظلم - يظلم",
            }
        },
        {
            "icon": "face_2_bis",
            "style": {
                "optionTextBackgroundColor": "#dbb021",
                "optionTextColor": "#FFFFFF",
                "showOptionText": true
            },
            "option": {
                "es_ES": "Regular",
                "ca_ES": "Regular",
                "en_GB": "Regular",
                "de_DE": "Regular",
                "fr_FR": "Régulier",
                "pt_PR": "Regular",
                "it_IT": "Regolare",
                "ru_RU": "Обычный",
                "zh_CN": "常规的",
                "ar_SA": "Regular",
            }
        },
        {
            "icon": "face_2",
            "style": {
                "optionTextBackgroundColor": "#90c162",
                "optionTextColor": "#FFFFFF",
                "showOptionText": true
            },
            "option": {
                "es_ES": "Bien",
                "ca_ES": "Bé",
                "en_GB": "Good",
                "de_DE": "Gut",
                "fr_FR": "Bien",
                "pt_PR": "Bem",
                "it_IT": "Bene",
                "ru_RU": "Хорошо",
                "zh_CN": "好的",
                "ar_SA": "جيد",
            }
        },
        {
            "icon": "face_1",
            "style": {
                "optionTextBackgroundColor": "#009540",
                "optionTextColor": "#FFFFFF",
                "showOptionText": true
            },
            "option": {
                "es_ES": "Muy bien",
                "ca_ES": "Molt bé",
                "en_GB": "Very good",
                "de_DE": "Sehr gut",
                "fr_FR": "Très bien",
                "pt_PR": "Muito bem",
                "it_IT": "Molto bene",
                "ru_RU": "Очень хорошо",
                "zh_CN": "非常好",
                "ar_SA": "جيد جدا",
            },
        },
    ],
    "YES_NO": [
        {
            "icon": "default_icon_no",
            "option": {
                "es_ES": "No",
                "ca_ES": "No",
                "en_GB": "No",
                "de_DE": "Nein",
                "fr_FR": "Non",
                "pt_PR": "Não",
                "it_IT": "No",
                "ru_RU": "Нет",
                "zh_CN": "没有",
                "ar_SA": "لا",
            },
            "style": {
                "showInsideOptionText": true,
                "showOptionText": false
            },
        },
        {
            "icon": "default_icon_yes",
            "option": {
                "es_ES": "Sí",
                "ca_ES": "Sí",
                "en_GB": "Yes",
                "de_DE": "Ja",
                "fr_FR": "Oui",
                "pt_PR": "Sim",
                "it_IT": "Sì",
                "ru_RU": "Да",
                "zh_CN": "是",
                "ar_SA": "نعم",
            },
            "style": {
                "showInsideOptionText": true,
                "showOptionText": false
            },
        },
    ],
    "NPS": [
        {
            "icon": "default_icon_nps_1",
            "option": {
                "es_ES": "1",
                "ca_ES": "1",
                "en_GB": "1",
                "de_DE": "1",
                "fr_FR": "1",
                "pt_PR": "1",
                "it_IT": "1",
                "ru_RU": "1",
                "zh_CN": "1",
                "ar_SA": "1",
            },
            "style": {
                "showInsideOptionText": true,
                "showOptionText": false
            },
            "cardOption":{
                "es_ES": "Nada Probable",
                "ca_ES": "Gens Probable",
                "en_GB": "Not likely at all",
                "de_DE": "Überhaupt nicht",
                "fr_FR": "Pas du tout probable",
                "pt_PR": "Não é de todo provável",
                "it_IT": "Non è affatto probabile",
                "ru_RU": "Маловероятно",
                "zh_CN": "完全不可能",
                "ar_SA": "غير محتمل",
            }
        },
        {
            "icon": "default_icon_nps_2",
            "option": {
                "es_ES": "2",
                "ca_ES": "2",
                "en_GB": "2",
                "de_DE": "2",
                "fr_FR": "2",
                "pt_PR": "2",
                "it_IT": "2",
                "ru_RU": "2",
                "zh_CN": "2",
                "ar_SA": "2",
            },
            "style": {
                "showInsideOptionText": true,
                "showOptionText": false
            },
        },
        {
            "icon": "default_icon_nps_3",
            "option": {
                "es_ES": "3",
                "ca_ES": "3",
                "en_GB": "3",
                "de_DE": "3",
                "fr_FR": "3",
                "pt_PR": "3",
                "it_IT": "3",
                "ru_RU": "3",
                "zh_CN": "3",
                "ar_SA": "3",
            },
            "style": {
                "showInsideOptionText": true,
                "showOptionText": false
            },
        },
        {
            "icon": "default_icon_nps_4",
            "option": {
                "es_ES": "4",
                "ca_ES": "4",
                "en_GB": "4",
                "de_DE": "4",
                "fr_FR": "4",
                "pt_PR": "4",
                "it_IT": "4",
                "ru_RU": "4",
                "zh_CN": "4",
                "ar_SA": "4",
            },
            "style": {
                "showInsideOptionText": true,
                "showOptionText": false
            },
        },
        {
            "icon": "default_icon_nps_5",
            "option": {
                "es_ES": "5",
                "ca_ES": "5",
                "en_GB": "5",
                "de_DE": "5",
                "fr_FR": "5",
                "pt_PR": "5",
                "it_IT": "5",
                "ru_RU": "5",
                "zh_CN": "5",
                "ar_SA": "5",
            },
            "style": {
                "showInsideOptionText": true,
                "showOptionText": false
            },
        },
        {
            "icon": "default_icon_nps_6",
            "option": {
                "es_ES": "6",
                "ca_ES": "6",
                "en_GB": "6",
                "de_DE": "6",
                "fr_FR": "6",
                "pt_PR": "6",
                "it_IT": "6",
                "ru_RU": "6",
                "zh_CN": "6",
                "ar_SA": "6",
            },
            "style": {
                "showInsideOptionText": true,
                "showOptionText": false
            },
        },
        {
            "icon": "default_icon_nps_7",
            "option": {
                "es_ES": "7",
                "ca_ES": "7",
                "en_GB": "7",
                "de_DE": "7",
                "fr_FR": "7",
                "pt_PR": "7",
                "it_IT": "7",
                "ru_RU": "7",
                "zh_CN": "7",
                "ar_SA": "7",
            },
            "style": {
                "showInsideOptionText": true,
                "showOptionText": false
            },
        },
        {
            "icon": "default_icon_nps_8",
            "option": {
                "es_ES": "8",
                "ca_ES": "8",
                "en_GB": "8",
                "de_DE": "8",
                "fr_FR": "8",
                "pt_PR": "8",
                "it_IT": "8",
                "ru_RU": "8",
                "zh_CN": "8",
                "ar_SA": "8",
            },
            "style": {
                "showInsideOptionText": true,
                "showOptionText": false
            },
        },
        {
            "icon": "default_icon_nps_9",
            "option": {
                "es_ES": "9",
                "ca_ES": "9",
                "en_GB": "9",
                "de_DE": "9",
                "fr_FR": "9",
                "pt_PR": "9",
                "it_IT": "9",
                "ru_RU": "9",
                "zh_CN": "9",
                "ar_SA": "9",
            },
            "style": {
                "showInsideOptionText": true,
                "showOptionText": false
            },
        },
        {
            "icon": "default_icon_nps_10",
            "option": {
                "es_ES": "10",
                "ca_ES": "10",
                "en_GB": "10",
                "de_DE": "10",
                "fr_FR": "10",
                "pt_PR": "10",
                "it_IT": "10",
                "ru_RU": "10",
                "zh_CN": "10",
                "ar_SA": "10",
            },
            "style": {
                "showInsideOptionText": true,
                "showOptionText": false
            },
            "cardOption":{
                "es_ES": "Muy probable",
                "ca_ES": "Molt probable",
                "en_GB": "Very Likely",
                "de_DE": "Höchstwahrscheinlich",
                "fr_FR": "Très probablement",
                "pt_PR": "Muito provavelmente",
                "it_IT": "Molto probabilmente",
                "ru_RU": "Вполне вероятно",
                "zh_CN": "很有可能",
                "ar_SA": "من المرجح جدا",
            }
        }
    ],
    "NPS_LITE": [
        {
            "icon": "default_icon_nps_1",
            "option": {
                "es_ES": "1",
                "ca_ES": "1",
                "en_GB": "1",
                "de_DE": "1",
                "fr_FR": "1",
                "pt_PR": "1",
                "it_IT": "1",
                "ru_RU": "1",
                "zh_CN": "1",
                "ar_SA": "1",
            },
            "style": {
                "showInsideOptionText": true,
                "showOptionText": false
            },
        },
        {
            "icon": "default_icon_nps_3",
            "option": {
                "es_ES": "2",
                "ca_ES": "2",
                "en_GB": "2",
                "de_DE": "2",
                "fr_FR": "2",
                "pt_PR": "2",
                "it_IT": "2",
                "ru_RU": "2",
                "zh_CN": "2",
                "ar_SA": "2",
            },
            "style": {
                "showInsideOptionText": true,
                "showOptionText": false
            },
        },
        {
            "icon": "default_icon_nps_6",
            "option": {
                "es_ES": "3",
                "ca_ES": "3",
                "en_GB": "3",
                "de_DE": "3",
                "fr_FR": "3",
                "pt_PR": "3",
                "it_IT": "3",
                "ru_RU": "3",
                "zh_CN": "3",
                "ar_SA": "3",
            },
            "style": {
                "showInsideOptionText": true,
                "showOptionText": false
            },
        },
        {
            "icon": "default_icon_nps_8",
            "option": {
                "es_ES": "4",
                "ca_ES": "4",
                "en_GB": "4",
                "de_DE": "4",
                "fr_FR": "4",
                "pt_PR": "4",
                "it_IT": "4",
                "ru_RU": "4",
                "zh_CN": "4",
                "ar_SA": "4",
            },
            "style": {
                "showInsideOptionText": true,
                "showOptionText": false
            },
        },
        {
            "icon": "default_icon_nps_10",
            "option": {
                "es_ES": "5",
                "ca_ES": "5",
                "en_GB": "5",
                "de_DE": "5",
                "fr_FR": "5",
                "pt_PR": "5",
                "it_IT": "5",
                "ru_RU": "5",
                "zh_CN": "5",
                "ar_SA": "5",
            },
            "style": {
                "showInsideOptionText": true,
                "showOptionText": false
            },
        }
    ]
}
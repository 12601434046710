import React from "react";
import styled from "@emotion/styled";
import Video from "../../../../../assets/videos/camera.mp4";

const HomeComponent = ({ config }) => {

  const Container = styled.div`
    position: relative;
    display: flex;
    min-width: 100%;
    min-height: 100%;
    overflow: hidden;
  `;

  const BackgroundVideo = styled.video`
    position: absolute;
    min-height: 100%;
    min-width: 100%;
  `;

  const Content = styled.div`
    display: flex;
    width: 100%;
    z-index: 2;
    background: #000000d6;
  `;

  const Message = styled.div`
    color: white;
    font-size: 8vh;
    margin: auto 10vh;
    font-weight: bold;
    text-shadow: grey 1px 5px 8px;
  `;

  return (
    <Container>
      <BackgroundVideo autoPlay muted loop src="http://www.happycustomerbox.com/es/mp4/camera.mp4" type="video/mp4" />
      <Content>
        <Message>{config?.strings?.bienvenido}<br /> {config?.user?.name || config?.user?.email || "-"}</Message>
      </Content>
    </Container>
  );
};

export default HomeComponent;

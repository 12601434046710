import React from "react";
import styled from "@emotion/styled";
import Table from "../../../../../shared/Table";
import EyeIcon from "../../../../../../assets/images/icon_eye.svg";
import Tooltip from "@material-ui/core/Tooltip";

const DeviceListComponent = ({ config, devices, actions }) => {
  const Container = styled.div`
    width: 100%;
  `;

  const Title = styled.div`
    background-color: darkgray;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    padding: 2vh 5vh;
  `;

  const Wrapper = styled.div`
    flex-grow: 1;
    padding: 2vh 5vh;
    > * {
      margin: 2vh 0;
    }
  `;

  const Icon = styled.img``;
  let listActions = [
    {
      title: (
        <Tooltip title={config.strings.seeDetails} aria-label="add">
          <Icon src={EyeIcon} />
        </Tooltip>
      ),
      action: actions.showDevice,
    }
  ];

  return (
    <Container>
      <Title>{config.strings.devicesList}</Title>
      <Wrapper>
        <Table
          header={config?.strings?.deviceTableHeaders}
          data={devices}
          actions={listActions}
          noElementsText={config.strings.noneListDisp}
          sortByField={"lastUpdate"}
          sortOrder={"descending"} />
      </Wrapper>
    </Container>
  );
};

export default DeviceListComponent;

import React, { useState, useEffect } from "react";
import { useOutletContext, useParams, useNavigate } from "react-router-dom";
import Firebase from "../../../../../../assets/Firebase";
import LoadingScreen from "../../../../../shared/LoadingScreen";
import DeviceComponent from "./DeviceComponent";
import { validateDevice } from "./DeviceValidator";


const DeviceEngine = () => {
    const config = useOutletContext();
    const navigate = useNavigate();

    const { deviceId } = useParams();
    const f = new Firebase();

    let loadingDevice = false;

    const [device, setDevice] = useState(null);

    const alertTypes = {
        notSelected: config.getText("notSelected"),
        "12H": "12H",
        "24H": "24H",
        "48H": "48h"
    }

    const loadDevice = () => {
        loadingDevice = true;
        f.getDeviceById(deviceId, (device) => {
            setDevice(device);
            loadingDevice = false;
        }, (error) => {
            console.error("Error loading device: ", error);
            loadingDevice = false;
        });
    }

    const updateDevice = (key, value) => {
        setDevice((prev) => ({
            ...prev,
            [key]: value
        }))
    }

    useEffect(() => {
        if (!deviceId) return;
        if (device == null && !loadingDevice) loadDevice();
    }, [deviceId]);

    const getFormatedStatus = () => {
        let formatedStatus = {};
        (device?.status || []).forEach(status => {
            formatedStatus[status?.id] = {
                creationDate: status?.creationDate?.toDate() || "-",
                ipAddress: status?.ipAddress || "-",
                battery: status?.battery || "-",
                ssid: status?.ssid || "-",

            }
        });
        return formatedStatus;
    }


    const confirmAction = () => {
        config.showDownloadingPopup(config.strings.guardando);

        let error = validateDevice(config, device);

        if (error != null) {
            config.hideDownloadingPopup();
            config.popupAlert(config.strings.error, error);
        } else {
            console.log("Trying to save: ", device);
            f.updateDevice(device, onSuccess, onError);
        }
    }

    const onSuccess = () => {
        config.hideDownloadingPopup();

        config.popupAlert(config.strings.correct, config.strings.successMsg, () => {
            navigate(`/dashboard/devices/list`);
        });
    };

    const onError = (error) => {
        config.hideDownloadingPopup();
        console.log("Error saving reportSenders:", error);
        config.popupAlert(config.strings.error, config.strings.alertError);
    }

    const cancelAction = () => {
        navigate(`/dashboard/devices/list`);
    };

    const showCompleteStatus = (id) => {
        let status = (device?.status || []).find(st => st.id === id);
        let ignorableParams = ["ref", "path", "lastUpdate", "creationDate", "deleted"];

        if (!status) return;

        let text = "";
        Object.entries(status).forEach(([key, value]) => {
            if (ignorableParams.includes(key)) return;
            text += "<b>" + key + "</b>" + ": " + value + "<br/>";
        });

        config.popupAlert("Status", text);
    }

    const addAlertEmail = () => {
        let alertEmails = device?.alertEmails || [];
        alertEmails.push("");
        updateDevice("alertEmails", alertEmails);
    }

    const deleteAlertEmail = (index) => {
        let alertEmails = device?.alertEmails || [];
        alertEmails.splice(index, 1);
        updateDevice("alertEmails", alertEmails);
    }

    const editAlertEmail = (index, value) => {
        let alertEmails = device?.alertEmails || [];
        alertEmails[index] = value;
        updateDevice("alertEmails", alertEmails);
    }

    useEffect(() => {
        if (device?.alarmEnabled && !device?.alertEmails) {
            updateDevice("alertEmails", [config?.user?.email || ""]);
        }
    }, [device?.alarmEnabled])


    return (
        <>
            {device ? (
                <DeviceComponent
                    config={config}
                    device={device}
                    alertTypes={alertTypes}
                    actions={{
                        confirmAction: confirmAction,
                        cancelAction: cancelAction,
                        updateDevice: updateDevice,
                        getFormatedStatus: getFormatedStatus,
                        showCompleteStatus: showCompleteStatus,
                        addAlertEmail: addAlertEmail,
                        deleteAlertEmail: deleteAlertEmail,
                        editAlertEmail: editAlertEmail
                    }} />
            ) : (
                <LoadingScreen />
            )}
        </>
    );
};

export default DeviceEngine;

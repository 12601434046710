import React, { useState } from "react";
import styled from "@emotion/styled";
import GraphComponent from "./GraphComponent";
import Button from "../../../../../../../shared/Button";
import SecondaryButton from "../../../../../../../shared/SecondaryButton";

const QuestionGraphComponent = ({ config, screenIndex, questionIndex, isMultipleQuestion, question, actions }) => {
    const [state, setState] = useState({
        tableExpanded: false
    });

    const Wrapper = {
        background: "white",
        borderRadius: "1vh",
        width: "calc(100% - 4vh)",
        margin: "2vh 0",
        marginTop: (screenIndex === 0) ? "2vh" : "0vh",
        padding: "2vh",
        display: "flex",
        flexDirection: "row",
    };

    const Row = {
        display: "flex",
        flexDirection: "row",
        width: "100%"
    }

    const Column = {
        ...Row,
        flexDirection: "column",
    }

    const CenterColumn = {
        ...Column,
        margin: "auto 0",
    }

    const Cell = {
        ...Row,
        justifyContent: "center",
        color: "lightgray",
        fontStyle: "italic",
        fontSize: "2.2vh"
    }

    const QuestionIndex = styled.div`
        display: flex;
        background: lightgray;
        color: white;
        border-radius: 1vh;
        min-height: 4vh;
        min-width: 4vh;
        margin: auto 0;
        font-size: 2.5vh;
        font-weight: bold;
        align-items: center;
        justify-content: center;
    `;

    const QuestionTag = styled.div`
        font-size: 2.3vh;
        margin: auto 2vh;
        flex: 1;
        font-weight: bold;
    `;

    const QuestionDetail = styled.div`
        font-size: 2.3vh;
        color: gray;
        margin: auto 2vh;
        flex: 1;
    `;

    const TotalResponses = styled.div`
        font-size: 2.2vh;
        color: black;
        margin: 0.5vh auto;
        font-weight: bold;
    `;

    const ResultsTable = styled.table`
        border-collapse: separate; 
        table-layout: fixed;
        width: 100%;
        margin: auto 0;
        background-color: white;
        border: 2px solid lightgray;
        border-radius: 1vh;
        border-spacing: 0px;
        td {
            padding: ${state.tableExpanded ? "1.5vh" : "2vh"};
            width: ${state.tableExpanded ? "30%" : "25%"};
            max-width: ${state.tableExpanded ? "30%" : "25%"};
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
        }
        th {
            padding: ${state.tableExpanded ? "1.5vh" : "2vh"};
            width: ${state.tableExpanded ? "20%" : "50%"};
            max-width: ${state.tableExpanded ? "20%" : "50%"};
            text-align: left;
            background-color: lightgray;
            font-weight: normal;
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
        }
        tr:nth-of-type(even) {
            background-color: rgb(0 0 0 / 5%);
        }
        tr:first-child {
            th:first-child { border-top-left-radius: 0.5vh; }
            td:last-child { border-top-right-radius: 0.5vh;  }
        }
        tr:last-child {
            th:first-child { border-bottom-left-radius: 0.5vh; }
            td:last-child {  border-bottom-right-radius: 0.5vh; }
        }
    `;

    const SmallTd = {
        width: "3vw",
        maxWidth: "3vw"
    }

    let questionNum = "" + (screenIndex + 1) + (isMultipleQuestion ? ("." + (questionIndex + 1)) : "")
    let questionData = actions.getQuestionData(screenIndex, questionIndex);

    return (
        <div style={Wrapper}>
            <div style={Column}>
                <div style={{ ...Row, marginBottom: "2vh" }}>
                    <QuestionIndex>{questionNum}</QuestionIndex>
                    <QuestionTag>{config.getTranslatedText(question?.questionTitle)}</QuestionTag>
                    <QuestionDetail>{config?.strings?.[question?.type]}</QuestionDetail>
                </div>
                {question?.options?.length == 0 ? (
                    <div style={Cell}>{config.getText("noOptions")}</div>
                ) : (
                    <>
                        <ResultsTable>
                            {(question?.options || []).map((option, optionIndex) => {
                                let optionData = questionData?.options?.[option.ref.id];
                                let suboptionCount = option?.suboptions?.length;
                                let firstSuboptionData = optionData?.suboptions?.[option?.suboptions?.[0]?.ref.id];

                                if (state.tableExpanded) {
                                    return (
                                        <>
                                            <tr>
                                                <th rowSpan={suboptionCount || 1}>{config.getTranslatedText(option?.option)}</th>
                                                <td rowSpan={suboptionCount || 1} style={SmallTd}>{optionData?.total || 0}</td>
                                                <td rowSpan={suboptionCount || 1} style={SmallTd}>{optionData?.percent || 0}%</td>

                                                {suboptionCount > 0 ? (
                                                    <>
                                                        <td>{config.getTranslatedText(option?.suboptions?.[0]?.option)}</td>
                                                        <td style={SmallTd}>{firstSuboptionData?.total || 0}</td>
                                                        <td style={SmallTd}>{firstSuboptionData?.percent || 0}%</td>
                                                    </>
                                                ) : (
                                                    <td colSpan={"3"} style={{ width: "auto" }}></td>
                                                )}
                                            </tr>

                                            {option?.suboptions?.map((subOption, i) => {
                                                if (i > 0) {
                                                    let suboptionData = optionData?.suboptions?.[subOption.ref.id];
                                                    return (
                                                        <tr>
                                                            <td>{config.getTranslatedText(subOption.option)}</td>
                                                            <td style={SmallTd}>{suboptionData?.total || 0}</td>
                                                            <td style={SmallTd}>{suboptionData?.percent || 0}%</td>
                                                        </tr>
                                                    )
                                                }
                                            })}

                                        </>
                                    )
                                } else {
                                    return (
                                        <tr>
                                            <th colSpan="2">{config.getTranslatedText(option?.option)}</th>
                                            <td>{optionData?.total || 0} {config?.strings?.response}</td>
                                            <td>{optionData?.percent || 0} %</td>
                                        </tr>
                                    )
                                }

                            })}
                        </ResultsTable>

                        {actions.containsAnySuboption(question) ? (
                            <SecondaryButton
                                value={state?.tableExpanded ? config.getText("showSimpleTable") : config.getText("showCompleteTable")}
                                style={{ margin: "1.5vh auto", width: "fit-content" }}
                                action={() => {
                                    setState((prev) => ({
                                        ...prev,
                                        tableExpanded: !prev.tableExpanded
                                    }))
                                }} />
                        ) : (
                            <></>
                        )}

                        <TotalResponses>
                            {config.getText("total") + ": " + (questionData?.totalResponses || 0) + " " + config.getText("responses")}
                        </TotalResponses>

                    </>
                )}
            </div>
            {question?.options?.length > 0 ? (
                <div style={CenterColumn}>
                    <GraphComponent
                        config={config}
                        question={question}
                        questionData={questionData}
                        actions={actions}
                        isLastNPS={question?.options[0].icon === "face_1"} />
                </div>
            ) : (
                <></>
            )}
        </div>
    );

};
export default QuestionGraphComponent;
import Firebase from "./Firebase";

const apiServersUrls = {
  prod: "https://qrs-api.herokuapp.com",
  staging: "https://qrs-api-dev.herokuapp.com",
  dev: "https://qrs-api-dev.herokuapp.com",
  local: "http://localhost:8080"
}

class BackendAccessor {
  constructor() {
    // Singleton instance
    if (typeof BackendAccessor.instance === "object") {
      return BackendAccessor.instance;

    } else {
      const f = new Firebase();
      const env = process.env.REACT_APP_ENVIRONMENT;

      this.backendUrl = apiServersUrls[env] || apiServersUrls.prod;

      f.runWhenSignUp(() => {
        BackendAccessor.instance.uid = f.getUid();
        f.getTokenId(
          (tokenId) => {
            BackendAccessor.instance.tokenId = tokenId;
          },
          (error) => {
            console.error("Error loading tokenId: " + error);
          }
        );
      });

      BackendAccessor.instance = this;
      return this;
    }
  }

  fetchBackend(method, path, body, onSuccess, onError) {
    const controller = new AbortController();
    var requestOptions = {
      method: method,
      headers: {
        Authorization: "Bearer " + this.tokenId,
      },
      redirect: "follow",
      signal: controller.signal
    };

    if (body != null) {
      if (body instanceof FormData) {
        requestOptions.body = body;
      } else {
        requestOptions.headers["Content-Type"] = "application/json";
        requestOptions.body = JSON.stringify(body);
      }
    }

    const id = setTimeout(() => {
      controller.abort();
    }, 120000);

    fetch(this.backendUrl + path, requestOptions)
      .then((response) => {
        clearTimeout(id);
        return response.text()
      }).then((result) => {
        var resultObj = JSON.parse(result);
        if (resultObj.error) {
          onError(resultObj);
        } else {
          onSuccess(resultObj);
        }
      })
      .catch((error) => onError(error));
  }

  postBackend(path, body, onSuccess, onError) {
    this.fetchBackend("POST", path, body, onSuccess, onError);
  }

  getBackend(path, body, onSuccess, onError) {
    this.fetchBackend("GET", path, body, onSuccess, onError);
  }

  deleteBackend(path, body, onSuccess, onError) {
    this.fetchBackend("DELETE", path, body, onSuccess, onError);
  }

  sendReport(body, onSuccess, onError) {
    this.postBackend("/report/sendByReportSender", body, onSuccess, onError);
  }

  /* Auxiliary methods */

  getUid() {
    return this.uid;
  }

  getIdFromPath(path) {
    let pathParts = path?.split("/");
    let id = pathParts[pathParts.length - 1];
    return id;
  }

  createUid(length = 20) {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
export default BackendAccessor;

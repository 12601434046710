import React from "react";
import styled from "@emotion/styled";
import DateTimeTicker from "../../../../../shared/DateTimeTicker";
import Button from "../../../../../shared/Button";
import ReportGraphsEngine from "./Graphs/ReportGraphsEngine";
import RawReportEngine from "./Raw/RawReportEngine";

const ReportComponent = ({ config, survey, responses, filtersConfig, devices, reportData, actions, filterActions }) => {

    const Title = styled.div`
        background-color: darkgray;
        color: white;
        text-transform: uppercase;
        font-weight: bold;
        padding: 2vh 5vh;
    `;

    const Wrapper = {
        width: "calc(100% - 12vh)",
        display: "flex",
        padding: "4vh 6vh",
        marginBottom: "0.5rem",
        justifyContent: "center",
        flexDirection: "column",
        rowGap: "2vh",
        columnGap: "3vh"
    }

    const DateWrapper = {
        display: "flex",
        flexDirection: "row",
        padding: "1.5vh 6vh",
        columnGap: "3vh",
        backgroundColor: "lightgray"
    }

    const DateItem = {
        display: "flex",
        flexDirection: "row",
        flex: "1"
    }

    const Label = styled.div`
        flex: 1;
        margin: auto;
        display: flex;
        justify-content: center;
    `;

    return (
        <div style={{ width: "100%" }}>
            <Title>{config?.strings?.report}</Title>
            <div style={DateWrapper}>
                <div style={DateItem}>
                    <Label>{config?.strings?.desde}</Label>
                    <DateTimeTicker
                        id="fromDate"
                        value={reportData?.fromDate}
                        onChange={actions.updateReportData}
                        width={"calc(99% - 1rem) "}
                        disabled={!reportData.dateEdiatble} />
                </div>

                <div style={DateItem}>
                    <Label>{config?.strings?.hasta}</Label>
                    <DateTimeTicker
                        id="toDate"
                        value={reportData?.toDate}
                        onChange={actions.updateReportData}
                        width={"calc(99% - 1rem)"}
                        disabled={!reportData.dateEdiatble} />
                </div>
                <Button
                    value={config?.strings?.addData}
                    style="display: flex; align-items: center;"
                    action={actions.applyNewDates}
                    enabled={reportData.dateEdiatble} />
            </div>

            <div style={Wrapper}>
                {reportData?.mode === "GRAPHS" ? (
                    <ReportGraphsEngine
                        config={config}
                        survey={survey}
                        responses={actions.getFilteredResponses()}
                        filtersConfig={filtersConfig}
                        reportData={reportData}
                        actions={actions}
                        filterActions={filterActions} />
                ) : reportData?.mode === "RAW" ? (
                    <RawReportEngine
                        config={config}
                        survey={survey}
                        responses={responses}
                            devices={devices}
                        reportData={reportData}
                        actions={actions} />
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default ReportComponent;

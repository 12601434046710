import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import ScreenComponent from "./ScreenComponent";

const ScreenEngine = ({ config, survey, surveyActions, screenActions }) => {
    const Wrapper = {
        width: "100%",
    }

    const EmptyTag = {
        margin: "4vh 0 8vh",
        fontSize: "2.5vh",
        color: "gray",
        textAlign: "center",
    }

    const updateCurrentScreen = (index, key, value) => {
        let screens = survey?.screens;
        let screen = screens[index];
        if (screen) screen[key] = value;
        surveyActions.update("screens", screens);
    }

    const updateQuestion = (screenIndex, questionIndex, key, value) => {
        let screens = survey?.screens;
        let screen = screens[screenIndex];
        let questions = screen?.questions;
        let question = questions?.[questionIndex];
        if (question) {
            question[key] = value;
        }
        updateCurrentScreen(screenIndex,"questions", questions);
    }

    const addQuestion = (screenIndex) => {
        let questions = survey?.screens?.[screenIndex]?.questions;
        if (!questions) questions = [];

        questions.push({
            options: [],
        });
        updateCurrentScreen("questions", questions);
    }

    return (
        <div style={Wrapper}>
            {survey?.screens?.length > 0 ? (
                survey?.screens?.map((screen, index) => {
                    return (
                        <ScreenComponent
                            key={"screen-" + index}
                            id={"screen-" + index}
                            config={config}
                            survey={survey}
                            screenIndex={index}
                            screenCount={survey?.screens?.length}
                            screen={screen}
                            maximized={survey?.metadata?.maximizedScreen === index}
                            surveyActions={surveyActions}
                            screenActions={screenActions}
                            questionActions={{
                                update: updateQuestion,
                                add: addQuestion
                            }} />
                    );
                })
            ) : ( 
                <div style={EmptyTag}>{config?.strings?.noPreguntas}</div>
            )}
        </div>
    );
};

export default ScreenEngine;

import React from "react";
import styled from "@emotion/styled";
import Button from "../../../../../shared/Button";
import SecondaryButton from "../../../../../shared/SecondaryButton";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const RGPDComponent = ({ config, RGPD, updateRGPD, confirmAction, cancelAction }) => {

  const Title = styled.div`
    background-color: darkgray;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    padding: 2vh 5vh;
  `;

  const Wrapper = {
    width: "calc(100% - 12vh)",
    display: "flex",
    justifyContent: "center",
    padding: "4vh 6vh",
    flexDirection: "column",
  }

  const InputTextWrapper = {
    margin: "1vh 0vh",
    display: "flex",
    width: "calc(100% - 6px)",
    height: "30%",
    maxHeight: "30%",
    backgroundColor: "white",
    border: "3px solid gray"
  }

  const InputTextStyle = {
    height: "100%",
    width: "100%",
  }

  const ButtonWrapper = styled.div`
    display: flex;
    place-items: center;
    border-top: 1px solid grey;
    padding-top: 0.5rem;
    margin-top: 0.5rem;
  `;

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
  ];

  return (
    <div style={{ width: "100%" }}>
      <Title>{config.getText("editRGPD")}</Title>
      <div style={Wrapper}>
        <div style={InputTextWrapper}>
          <ReactQuill theme="snow" value={RGPD?.content || ""} modules={modules}
            formats={formats} onChange={updateRGPD} style={InputTextStyle} />
        </div>

        <ButtonWrapper>
          <SecondaryButton value={config.strings.cancel} action={cancelAction} style="margin: 0 1vh 0 auto" />
          <Button value={config.strings.confirm} action={confirmAction} style="margin: 0 auto 0 0" />
        </ButtonWrapper>

      </div>
    </div >
  );
};

export default RGPDComponent;

import React, { useState, useEffect } from "react";
import ListComponent from "./ReportSenderListComponent";
import LoadingScreen from "../../../../../shared/LoadingScreen";
import { useNavigate, useOutletContext } from "react-router-dom";
import Firebase from "../../../../../../assets/Firebase";

const ReportSenderListEngine = () => {
  const config = useOutletContext();
  const navigate = useNavigate();
  let fdb = new Firebase();

  const [reportSenders, setReportSenders] = useState(null);

  const loadReportSenders = () => {

    fdb.getReportSendersList((data) => {
      let parsedData = {};
      Object.entries(data).forEach(([id, value]) => {
        parsedData[id] = {
          alias: value?.alias || "-",
          type: config.getText(value?.type),
          emails: parseEmails(value?.emails) || "-",
          creationDate: value?.creationDate?.toDate() || "-",
          lastUpdate: value?.lastUpdate?.toDate() || "-",
        }
      });
      setReportSenders(parsedData);

    }, (error) => {
      console.error("Error loading report senders: ", error);
      setReportSenders({});
    });
  }

  const parseEmails = (emails) => {
    let value = null;
    (emails || []).forEach((email) => {
      if (value == null) value = email;
      else value += "\n" + email;
    })
    return value;
  }

  useEffect(() => {
    if (reportSenders == null) {
      loadReportSenders();
    }
  }, []);

  const showReportSender = (id) => {
    navigate(`/dashboard/reportSenders/edit/${id}`);
  }

  const createReportSender = () => {
    navigate(`/dashboard/reportSenders/new`);
  }

  const deleteReportSender = (key) => {
    config.popupAlert(config.getText("delete"), config.getText("deleteConfirm"), null, true, () => {
      fdb.deleteReportSender(key,
        (response) => {
          console.log("Report sender with ID " + key + " has been deleted", response);
          setReportSenders((prevState) => {
            const state = { ...prevState };
            delete state[key];
            return state;
          });
        },
        (error) => {
          console.error("Error deleting report sender with ID " + key, error);
          config.popupAlert("Error", "Error deleting report sender");
        });
    });
  }

  return (
    <>
      {reportSenders != null ? (
        <ListComponent
          config={config}
          reportSenders={reportSenders}
          actions={{
            showReportSender: showReportSender,
            createReportSender: createReportSender,
            deleteReportSender: deleteReportSender
          }} />
      ) : (
        <LoadingScreen />
      )}
    </>
  );
};

export default ReportSenderListEngine;

import React, { useState, useEffect } from "react";
import ListComponent from "./DeviceListComponent";
import LoadingScreen from "../../../../../shared/LoadingScreen";
import { useNavigate, useOutletContext } from "react-router-dom";
import Firebase from "../../../../../../assets/Firebase";

const DeviceListEngine = () => {
  const config = useOutletContext();
  const navigate = useNavigate();
  let fdb = new Firebase();

  const [devices, setDevices] = useState(null);

  const loadDevices = () => {
    fdb.getDevicesList((data) => {
      let parsedData = {};
      Object.entries(data).forEach(([id, value]) => {
        parsedData[id] = {
          alias: value?.alias || "-",
          MAC: id || "-",
          lastStatus: value?.status?.[0]?.creationDate?.toDate() || "-",
        }
      });
      setDevices(parsedData);

    }, (error) => {
      console.error("Error loading devices: ", error);
      setDevices({});
    });
  }

  useEffect(() => {
    if (devices == null) {
      loadDevices();
    }
  }, []);

  const showDevice = (id) => {
    navigate(`/dashboard/devices/${id}`);
  }

  return (
    <>
      {devices != null ? (
        <ListComponent
          config={config}
          devices={devices}
          actions={{
            showDevice: showDevice
          }} />
      ) : (
        <LoadingScreen />
      )}
    </>
  );
};

export default DeviceListEngine;

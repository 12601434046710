import React, { useState, useEffect } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import Firebase from "../../../../../../assets/Firebase";
import RGPDComponent from "./RGPDComponent";

const RGPDEngine = () => {
  const config = useOutletContext();
  const navigate = useNavigate();
  const f = new Firebase();

  const [RGPD, setRGPD] = useState(null);

  const loadRGPD = () => {
    f.getRGPD((data) => {
      setRGPD(data);
    }, (error) => {
      console.error("Error loading RGPD", error);
    })
  }

  const confirmAction = () => {
    f.saveRGPD(RGPD, (response) => {
      console.error("RGPD saved: ", response);
      config.popupAlert(config.getText("correct"), config.getText("successMsg"), cancelAction);
    }, (error) => {
      config.popupAlert(config.getText("error"), config.getText("alertError"));
      console.error("Error saving RGPD: ", error);
    })
  }

  const cancelAction = () => {
    window.close();
  }

  const updateRGPD = (text) => {
    setRGPD((prev) => ({
      ...prev,
      content: text
    }));
  }

  useEffect(() => {
    if (RGPD == null) {
      loadRGPD();
    }
  }, []);

  return (
    <RGPDComponent
      config={config}
      RGPD={RGPD}
      updateRGPD={updateRGPD}
      confirmAction={confirmAction}
      cancelAction={cancelAction} />
  );
};

export default RGPDEngine;

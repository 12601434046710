import React, { useState } from "react";
import styled from "@emotion/styled";
import QuestionEngine from "./QuestionEngine";
import DeleteIcon from "../../../../../../../assets/images/delete-icon.svg";
import TopIcon from "../../../../../../../assets/images/ic_contract.png";
import BottomIcon from "../../../../../../../assets/images/ic_expand.png";

const ScreenComponent = ({ id, config, survey, maximized, screenIndex, screenCount, screen, surveyActions, screenActions, questionActions }) => {
    const Wrapper = {
        background: "lightgray",
        borderRadius: "1vh",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        margin: "0.75vh 0",
    };

    const QuestionWrapper = {
        display: "flex",
        flexDirection: "column",
        flex: "1"
    }

    const IconWrapper = {
        margin: "0.5vh",
        marginRight: "2vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
    }

    const Icon = styled.img`
        ${props =>`
            height: 2.5vh;
            width: 2.5vh;
            padding: 0.5vh 0;
            cursor: ${props?.invisible ? "default" : "pointer"};
            opacity: ${props?.invisible ? "0" : "1"};
        `}
    `;

    return (
        <div id={id} style={Wrapper}>
            <div style={QuestionWrapper}>
                {(screen?.questions || []).map((question, i) => {
                    return (
                        <QuestionEngine
                            config={config}
                            survey={survey}
                            screenIndex={screenIndex}
                            questionIndex={i}
                            question={question}
                            maximized={maximized}
                            isMultipleQuestion={screen?.questions?.length > 1}
                            questionActions={questionActions} />
                    );
                })}
            </div>

            {surveyActions.isAddQuestionAllowed() ? (
                <div style={IconWrapper}>
                    <Icon src={TopIcon} onClick={() => { screenActions.moveUp(screenIndex) }} invisible={screenIndex <= 0} />
                    <Icon src={DeleteIcon} onClick={() => { screenActions.delete(screenIndex) }} />
                    <Icon src={BottomIcon} onClick={() => { screenActions.moveDown(screenIndex) }} invisible={screenIndex >= (screenCount - 1)} />
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default ScreenComponent;

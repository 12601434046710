import React from "react";
import ReportGraphsComponent from "./ReportGraphsComponent";

const ReportGraphsEngine = ({ config, survey, responses, filtersConfig, reportData, actions, filterActions }) => {

    const roundDecimal = (num) => {
        return Math.round((num + Number.EPSILON) * 100) / 100
    }

    const getQuestionData = (screenIndex, questionIndex) => {
        let questionData = {}
        let question = survey?.screens?.[screenIndex]?.questions?.[questionIndex];
        let responsesOfQuestion = [];

        responses.forEach(responses => {
            let resAnswer = responses?.answers?.filter(ans => ans?.questionReference?.path === question?.ref?.path) || [];
            responsesOfQuestion.push(...resAnswer);
        });

        let totalOfQuestion = 0;

        responsesOfQuestion?.forEach((response) => {
            response?.options?.forEach((responseOption) => {
                let isValid = question?.options?.some((questionOp) => {
                    return responseOption.optionReference.path == questionOp.ref.path;
                });

                if (isValid) totalOfQuestion++;
            })
        });

        questionData.totalResponses = totalOfQuestion;
        questionData.options = {};

        (question?.options || []).forEach(option => {
            let responsesOfOption = responsesOfQuestion?.filter(ans => {
                return ans?.options?.some((ansOp) => ansOp.optionReference.path == option.ref.path);
            });

            let totalOfOption = responsesOfOption?.length || 0;
            let percentOfOption = totalOfQuestion > 0 ? (totalOfOption / totalOfQuestion) * 100 : 0;
            let suboptions = {};

            (option?.suboptions || []).forEach(suboption => {
                let responsesOfSubption = responsesOfOption?.filter(ans => {
                    return ans?.options?.some((ansOp) => {                        
                        return ansOp?.suboptionsResponses?.some((ansSubop) => ansSubop.optionReference.path == suboption.ref.path);
                    });
                });

                let totalOfSuboption = responsesOfSubption?.length || 0;
                let percentOfSuboption = totalOfOption > 0 ? (totalOfSuboption / totalOfOption) * 100 : 0;
                
                suboptions[suboption.ref.id] = {
                    total: totalOfSuboption,
                    percent: roundDecimal(percentOfSuboption)
                }
            });

            questionData.options[option.ref.id] = {
                total: totalOfOption,
                percent: roundDecimal(percentOfOption),
                suboptions: suboptions
            }
        });
        return questionData;
    }

    const getGraphData = (question, questionData) => {
        let labels = [];
        let values = [];

        (question?.options || []).forEach((option, i) => {
            labels[i] = config.getTranslatedText(option?.option);
            values[i] = questionData?.options?.[option?.ref?.id]?.total || 0;
        });

        return {
            labels: labels,
            values: values,
        }
    }

    const getStackedGraphData = (question, stackedBy, grouped) => {
        let groupedByHour = stackedBy === "hour" && grouped;
        let groupedByDay = stackedBy === "day" && grouped;
        let stackedByDay = stackedBy === "day" && !grouped;
        let stackedByMonth = stackedBy === "month" && !grouped;

        let startDate = reportData?.fromDate;
        let endDate = reportData?.toDate;
        let labels = [];
        let datasets = [];

        (question?.options || []).forEach((option, i) => {
            let data = [];
            let loop = new Date(startDate);
            if (groupedByHour) {
                loop.setHours(0, 0, 0, 0);
                endDate = new Date(loop);
                endDate.setHours(23, 0, 0, 0);
            } else if (groupedByDay) {
                let mondayDate = new Date(loop);
                mondayDate = mondayDate.setDate(mondayDate.getDate() - mondayDate.getDay() + 1);
                let sundayDate = new Date(loop);
                sundayDate.setDate(sundayDate.getDate() - sundayDate.getDay() + 7);
                loop = new Date(mondayDate);
                endDate = new Date(sundayDate);
            }

            while (loop <= endDate ||
                (stackedByMonth && loop.getMonth() <= endDate.getMonth() && loop.getFullYear() <= endDate.getFullYear()) || //
                (groupedByHour && loop.getHours() < 24 && loop.getDate() == endDate.getDate()) ||
                (stackedByDay && loop <= endDate)) {

                if (i == 0) {
                    if (groupedByHour) {
                        labels.push(loop.toLocaleString('default', { hour: '2-digit', minute: '2-digit' }));
                    } else if (groupedByDay) {
                        labels.push(loop.toLocaleString('default', { weekday: 'long' }));
                    } else if (stackedByMonth) {
                        labels.push(loop.toLocaleDateString('default', { month: 'long' }));
                    } else {
                        labels.push(loop.toLocaleDateString("default", { year: 'numeric', month: 'numeric', day: 'numeric' }));
                    }
                }

                let responsesOfQuestion = [];
                responses.forEach(response => {
                    let date = response?.creationDate?.toDate();
                    let isSameMonth = date.getFullYear() === loop.getFullYear() && date.getMonth() === loop.getMonth();
                    let isSameDayOfWeek = date.getDay() === loop.getDay();
                    let isSameDay = date.getDate() === loop.getDate();
                    let isSameHour = date.getHours() === loop.getHours();

                    if ((stackedByMonth && isSameMonth) || (stackedByDay && isSameDay && isSameMonth) || (groupedByHour && isSameHour) || (groupedByDay && isSameDayOfWeek)) {
                        let resAnswer = response?.answers?.filter(ans => ans.questionReference.path === question.ref.path) || [];
                        responsesOfQuestion.push(...resAnswer);
                    }
                });
                let responsesOfOption = responsesOfQuestion?.filter(ans => {
                    return ans?.options?.some((ansOp) => ansOp.optionReference.path == option.ref.path);
                });
                data.push(responsesOfOption?.length | 0);

                let newDate;
                if (groupedByHour) {
                    newDate = loop.setHours(loop.getHours() + 1);
                } else if (stackedByMonth) {
                    newDate = loop.setMonth(loop.getMonth() + 1);
                } else {
                    newDate = loop.setDate(loop.getDate() + 1);
                }
                loop = new Date(newDate);
            }

            datasets[i] = {
                label: config.getTranslatedText(option?.option),
                data: data,
            };
        });

        return {
            labels: labels,
            datasets: datasets,
        }
    }

    const getFreeResponses = (question) => {
        let responsesOfQuestion = [];
        responses.forEach(responses => {
            let resAnswer = responses?.answers?.filter(ans => ans?.questionReference?.path === question?.ref?.path) || [];
            responsesOfQuestion.push(...resAnswer);
        });
        responsesOfQuestion.filter(res => Object.keys(res?.freeResponses || {}).length > 0);
        return responsesOfQuestion;
    }

    const containsAnySuboption = (question) => {
        return question?.options?.some(option => {
            return option?.suboptions != null && option?.suboptions?.length > 0;
        });
    } 

    const getTotalResponses = () => {
        return 0;
    }

    return (
        <ReportGraphsComponent
            config={config}
            survey={survey}
            responses={responses}
            filtersConfig={filtersConfig}
            reportData={reportData}
            filterActions={filterActions}
            actions={{
                ...actions,
                getQuestionData: getQuestionData,
                getGraphData: getGraphData,
                getStackedGraphData: getStackedGraphData,
                getFreeResponses: getFreeResponses,
                containsAnySuboption: containsAnySuboption,
                getTotalResponses: getTotalResponses,
            }}
        />
    );
}

export default ReportGraphsEngine;
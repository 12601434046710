import React from "react";
import { Outlet, useOutletContext } from "react-router-dom";

const SurveysEngine = () => {
  const config = useOutletContext();

  return <Outlet context={config} />;
};

export default SurveysEngine;

import React, { useState, useEffect } from "react";
import { useOutletContext, useParams, useNavigate } from "react-router-dom";
import Firebase from "../../../../../../assets/Firebase";
import BackendAccessor from "../../../../../../assets/BackendAccessor";

import LoadingScreen from "../../../../../shared/LoadingScreen";
import DownloadingPopup from "../../../../../shared/DownloadingPopup";
import ReportSenderComponent from "./ReportSenderComponent";
import { validateReportSender } from "./ReportSenderValidator";
import { isNullOrUndefined } from "../../../../../../assets/utils/FormsUtils";
import { parseCronExpression } from 'cron-schedule';

const ReportSenderEngine = () => {
    const config = useOutletContext();
    const navigate = useNavigate();

    const { reportSenderId } = useParams();
    const f = new Firebase();
    const ba = new BackendAccessor();

    let loadingReportSender = false;
    let loadingSurveys = false;
    let loadingRegisters = false;

    const [reportSender, setReportSender] = useState(null);
    const [registers, setRegisters] = useState(null);
    const [surveys, setSurveys] = useState(null);
    const [loadingText, setLoadingText] = useState(null);

    const types = {
        "notSelected": config.getText("notSelected"),
        "DISABLED": config.getText("DISABLED"),
        "WEEKLY": config.getText("WEEKLY"),
        "MONTHLY": config.getText("MONTHLY")
    }

    const loadReportSender = () => {
        loadingReportSender = true;
        f.getReportSenderById(reportSenderId, (reportSender) => {
            setReportSender(reportSender);
            loadingReportSender = false;
        }, (error) => {
            console.error("Error loading ReportSender: ", error);
            loadingReportSender = false;
        });
    }

    const loadSurveys = () => {
        loadingSurveys = true;
        f.loadSurveys((surveys) => {
            const formatedSurveys = {
                "notSelected": config.strings.notSelected
            };
            Object.entries(surveys || {}).forEach(([surveyId, survey]) => {
                formatedSurveys[surveyId] = survey?.name;
            })
            setSurveys(formatedSurveys);
            loadingSurveys = false;
        }, (error) => {
            console.error("Error loading surveys: ", error);
            loadingSurveys = false;
        });
    }

    const loadRegisters = () => {
        if (isNullOrUndefined(reportSenderId)) {
            setRegisters({});
        } else {
            loadingRegisters = true;
            f.getReportSendersRegisterList(reportSenderId, (registers) => {
                const formatedRegisters = {};
                Object.entries(registers || {}).forEach(([id, register]) => {
                    formatedRegisters[id] = {
                        creationDate: register?.creationDate?.toDate() || "-",
                        sent: register?.sent ? config.getText("yes") : config.getText("no"),
                        error: register?.error ? config.getText("yes") : config.getText("no"),
                        emails: register?.emails?.join(", ") || "-"
                    }
                });
                setRegisters(formatedRegisters);
                loadingRegisters = false;
            }, (error) => {
                console.error("Error loading registers: ", error);
                loadingRegisters = false;
            });
        }
    }

    const createReportSender = () => {
        setReportSender({
            type: "MONTHLY",
            emails: [
                config?.user?.email || ""
            ]
        });
    }

    const updateReportSender = (key, value) => {
        setReportSender((prev) => ({
            ...prev,
            [key]: value
        }))
    }

    useEffect(() => {
        if (reportSenderId && reportSender == null && !loadingReportSender) {
            loadReportSender();
        } else {
            createReportSender();
        }
    }, [reportSenderId]);

    useEffect(() => {
        if (surveys == null && !loadingSurveys) {
            loadSurveys();
        }
        if (registers == null && !loadingRegisters) {
            loadRegisters();
        }
    }, []);

    const editEmail = (index, value) => {
        let emails = reportSender.emails;
        emails[index] = value;
        updateReportSender("emails", emails);
    }

    const addEmail = () => {
        let emails = reportSender.emails;
        emails.push("");
        updateReportSender("emails", emails);
    }

    const deleteEmail = (index) => {
        let emails = reportSender.emails;
        emails.splice(index, 1);
        updateReportSender("emails", emails);
    }

    const confirmAction = () => {
        setLoadingText(config.strings.guardando);

        let error = validateReportSender(config, reportSender);

        if (error != null) {
            setLoadingText(null);
            config.popupAlert(config.strings.error, error);
        } else {
            console.log("Trying to save: ", reportSender);
            f.saveReportSender(reportSender, onSuccess, onError);
        }
    }

    const onSuccess = () => {
        setLoadingText(null);

        config.popupAlert(config.strings.correct, config.strings.successMsg, () => {
            navigate(`/dashboard/reportSenders/list`);
        });
    };

    const onError = (error) => {
        console.log("Error saving reportSenders:", error);
        setLoadingText(null);
        config.popupAlert(config.strings.error, config.strings.alertError);
    }

    const cancelAction = () => {
        navigate(`/dashboard/reportSenders/list`);
    };

    const isSendReportSenderAllowed = () => {
        return !isNullOrUndefined(ba.getUid()) && !isNullOrUndefined(reportSender?.id);
    }

    const sendReportSender = () => {
        setLoadingText(config.getText("sendingEmail"));

        ba.sendReport({
            uid: ba.getUid(),
            reportSenderId: reportSender?.id
        }, (success) => {
            setLoadingText(null);
            config.popupAlert(config.getText("success"), config.getText("emailSentOk"));
            console.log("Report sent: ", success);
            loadRegisters();
        }, (fail) => {
            setLoadingText(null);
            config.popupAlert(config.getText("error"), config.getText("emailSentError"));
            console.error("Report fail: ", fail);
        });
    }

    const getNextExecutionDate = () => {
        let cronExpession = null;
        if (reportSender?.type === "WEEKLY") {
            cronExpession = "0 0 * * 1";
        } else if (reportSender?.type === "MONTHLY") {
            cronExpession = "0 0 1 * *"
        }

        if (cronExpession != null) {
            let cron = parseCronExpression(cronExpession);
            let date = cron.getNextDate(new Date());
            return date.toLocaleDateString();
        }
        return "-";
    }

    return (
        <>
            {surveys && reportSender ? (
                <ReportSenderComponent
                    config={config}
                    types={types}
                    reportSender={reportSender}
                    surveys={surveys}
                    registers={registers}
                    actions={{
                        confirm: confirmAction,
                        cancel: cancelAction,
                        updateReportSender: updateReportSender,
                        editEmail: editEmail,
                        addEmail: addEmail,
                        deleteEmail: deleteEmail,
                        isSendReportSenderAllowed: isSendReportSenderAllowed,
                        sendReportSender: sendReportSender,
                        getNextExecutionDate: getNextExecutionDate
                    }} />
            ) : (
                <LoadingScreen />
            )}

            {loadingText != null ? <DownloadingPopup strings={config.strings} headerText={loadingText} /> : <></>}
        </>
    );
};

export default ReportSenderEngine;

import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import UploadImg from "../../assets/images/ic_upload.png";
import Firebase from "../../assets/Firebase";
import DownloadingPopup from "./DownloadingPopup";
import { flushSync } from "react-dom";

const ImagePicker = ({ config, id, folder, imageName, onSelect, maxImgSize, maxImgWidth, maxImgHeight, width = "100%", height = "20vh" }) => {
    const f = new Firebase();
    const componentId = f.createUid();

    const [loadingText, setLoadingText] = useState(null);
    const [imgUrl, setImgUrl] = useState(null);

    const onChange = (input) => {
        if (input.target.files && input.target.files[0]) {

            let file = input.target.files[0];
            let url = URL.createObjectURL(file);
            let size = file.size / (1024 * 1024); //MB
            if (maxImgSize && size > maxImgSize) {
                config.popupAlert(config?.strings?.error, config?.strings?.errorMaxSize?.replace("{size}", maxImgSize));
            } else {
                let image = new Image();
                image.src = url;
                image.onload = () => {
                    let height = image.height;
                    let width = image.width;

                    console.log("Uploaded image: " + size + "kb (" + height + "x" + width + " px)");

                    if (maxImgHeight && height > maxImgHeight) {
                        config.popupAlert(config?.strings?.error, config?.strings?.errorMaxHeight?.replace("{height}", maxImgHeight));
                    } else if (maxImgWidth && width > maxImgWidth) {
                        config.popupAlert(config?.strings?.error, config?.strings?.errorMaxWidth?.replace("{width}", maxImgWidth));
                    } else {
                        uploadImage(file);
                    }
                };
            }
        }
    };

    const uploadImage = (file) => {
        setLoadingText(config?.strings?.uploadImage);
        if (folder) {
            f.uploadUserImageToFolder(file, folder, onSuccessUpload, onErrorUpload)
        } else {
            f.uploadUserImage(file, onSuccessUpload, onErrorUpload)
        }
    };

    const onSuccessUpload = (response) => {
        setLoadingText(null);
        onSelect(id, response?.name);
    }

    const onErrorUpload = (error) => {
        setLoadingText(null);
        config.popupAlert(config?.strings?.error, config?.strings?.errorUpload);
        console.error(config?.strings?.errorUploadImage, error);
    }

    useEffect(() => {
        if (imageName) {
            if (folder) {
                f.loadUserImageFromFolder(folder, imageName, setImgUrl);
            } else {
                f.loadUserImage(imageName, setImgUrl);
            }
        }
    }, [imageName, folder])

    const ImageContainer = {
        height: height,
        width: width,
        margin: "auto",
        textAlign: "center",
        backgroundColor: "#cbcbcb",
        position: "relative",
    };

    const ImageStyle = {
        maxHeight: "90%",
        maxWidth: "90%",
        cursor: "pointer",
        position: "absolute",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        margin: "auto",
    };

    const ImageUpload = {
        ...ImageStyle,
        maxHeight: "40%",
        maxWidth: "40%",
    };

    const ImageInput = styled.input`
        display: none;
    `;

    return (
        <div style={ImageContainer}>
            <img src={imgUrl ? imgUrl : UploadImg} style={imgUrl ? ImageStyle : ImageUpload} onClick={() => document.getElementById("ImageInput-" + componentId).click()} />
            <ImageInput type="file" id={"ImageInput-" + componentId} accept="image/png, image/jpeg" onChange={onChange} />
            {loadingText != null ? <DownloadingPopup headerText={loadingText} /> : <></>}
        </div>
    );
};
export default ImagePicker;

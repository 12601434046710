import React from "react";
import LoginComponent from "./LoginComponent";
import Firebase from "../../../assets/Firebase";
import { useNavigate } from "react-router-dom";

const LoginEngine = ({ props }) => {
  let navigate = useNavigate();
  let f = new Firebase();

  const login = () => {
    let name = document.getElementById("nameInput").value;
    let password = document.getElementById("passwordInput").value;
    f.tryLogin(name, password, success, error);
  };

  const success = () => {
    navigate("/");
  };

  const redirect = () => {
    navigate("/register");
  };

  const error = (err) => {
    console.log("Login error:", err);

    switch (err?.code) {
      case "auth/wrong-password":
        props.popupAlert(props.getText("error"), props.getText("wrongPassword"));
        break;
      case "auth/user-not-found":
        props.popupAlert(props.getText("error"), props.getText("errorUserNotFound"));
        break;
      case "auth/too-many-requests":
        props.popupAlert(props.getText("error"), props.getText("errorToManyRequests"));
        break;
      default:
        props.popupAlert(props.getText("error"), props.getText("wrongCredentials"));
        break;
    }
  };

  const passwordPopup = () => {
    props.popupInputAlert(props.getText("passwordTitulo"), props.getText("passwordHeader"), null, true, () => {
      f.checkEmailResetPassword(document.getElementById("testo").value, succesSend, errorSend);
    });
  }

  const succesSend = () => {
    props.popupAlert(props.getText("correctCredentials"), props.getText("correctSend"));
  }

  const errorSend = (err) => {
    console.log("Login error:", err);
    switch (err) {
      case "auth/user-not-found":
        props.popupAlert(props.getText("error"), props.getText("errorUserNotFound"));
        break;
      case "auth/invalid-email":
        props.popupAlert(props.getText("error"), props.getText("errorInvalidEmail"));
        break;
      default:
        props.popupAlert(props.getText("error"), props.getText("wrongCredentials"));
        break;
    }
  };

  return (
    <LoginComponent
      props={props}
      login={login}
      redirect={redirect}
      passwordPopup={passwordPopup} />
  );
};

export default LoginEngine;

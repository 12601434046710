import React from "react";
import defaultLogo from "../../../../assets/images/hcb_logo.png";
import styled from "@emotion/styled";
import Option from "./Option";
import Colors from "../../../../assets/Colors.json";
import HomeIcon from "../../../../assets/images/home-icon.svg";
import SurveysIcon from "../../../../assets/images/surveys-icon.svg";
import LogOutIcon from "../../../../assets/images/logout-icon.svg";
import DevicesIcon from "../../../../assets/images/devices-icon.svg";
import ProfileIcon from "../../../../assets/images/profile-icon.svg";
import ReportIcon from "../../../../assets/images/resume-icon-gray.svg";
import EmailIcon from "../../../../assets/images/email.svg";


const NavbarComponent = ({ config, logo, langIcon, goToProfile, logout }) => {
  const colors = Colors.lightTheme;

  const Wrapper = styled["div"]`
    display: flex;
    flex-direction: column;
    background:  linear-gradient(black, ${colors.lightGrey});;
    width: 18%;
  `;

  const TopWrapper = styled.div`
    position: relative;
    width: calc(100% - 4vh);
    padding: 2vh;
  `;

  const UserWrapper = styled.div` 
    margin-top: 0.1vh;
    display: flex;
    width: 100%;
    flex-direction: column;
  `;

  const Flag = styled.img`
    width: 3.5vh;
    cursor: pointer;
  `;

  const UserImageWrapper = styled.div`
    display: flex;
    background-color: white;
    margin: 2vh auto;
    height: 13vh;
    width: 13vh;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `;

  const UserImage = styled.img`
    max-height: 70%;
    max-width: 70%;
  `;

  const UserNameWrapper = styled.div`
    display: flex;
    margin: auto;
    column-gap: 1vh;
  `;

  const UserName = styled.div`
    margin: auto 0 ;
    font-size: 2.5vh;
    color: white;
    font-weight: bold;
  `;

  const LinksWrapper = styled["div"]`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    color: black;
    flex: 1;
    > * {
      display: flex;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
      padding: 2vh 0 2vh 2vh;
      margin: 0.1vh 0.2vh;
      place-items: center;
      transition: 0.1s;
      background-color: rgb(255 255 255 / 60%);
      &:hover {
        background-color: rgb(255 255 255 / 75%);
      }
    }
  `;

  return (
    <Wrapper>
      <TopWrapper>
        <UserWrapper>
          <UserImageWrapper onClick={goToProfile}>
            <UserImage src={logo || defaultLogo}/>
          </UserImageWrapper>
          <UserNameWrapper>
            <UserName>{config?.user?.name || config?.user?.email || "-"} </UserName>
            <Flag src={langIcon} onClick={goToProfile} />
          </UserNameWrapper>
        </UserWrapper>
      </TopWrapper>
      <LinksWrapper>
        <Option config={config} to={`/dashboard/home`} icon={HomeIcon} id={"home"} />
        <Option config={config} to={`/dashboard/profile`} icon={ProfileIcon} id={"profile"} />
        <Option config={config} to={`/dashboard/surveys/list`} icon={SurveysIcon} id={"surveys"} />
        <Option config={config} to={`/dashboard/reports/list`} icon={ReportIcon} id={"reports"} />
        <Option config={config} to={`/dashboard/reportSenders/list`} icon={EmailIcon} id={"reportSenders"} />
        <Option config={config} to={`/dashboard/devices/list`} icon={DevicesIcon} id={"devices"} />
        <Option icon={LogOutIcon} action={logout} text={config?.strings?.logout} />
      </LinksWrapper>
    </Wrapper>
  );
};

export default NavbarComponent;

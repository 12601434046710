import React from "react";
import styled from "@emotion/styled";
import Colors from "../../assets/Colors.json";

const Checkbox = ({ id = "actionButton", wrapperStyle, text, checked, onChange = () => { }, enabled = true }) => {
  let theme = "lightTheme";
  let colors = Colors[theme];

  const Wrapper = {
    padding: "1vh",
    display: "flex",
    alignContent: "center",
    ...wrapperStyle
  }

  const Label = {
    display: "inline",
    color: enabled ? "black" : "gray",
    fontSize: "2vh",
    padding: "0.5vh",
    margin: "auto 1vh",
  };

  const Checkbox = {
    margin: "auto 0",
    height: "2.5vh",
    width: "2.5vh",
  };

  return (
    <div style={Wrapper}>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        style={Checkbox}
        disabled={enabled ? "" : "disabled"}
        onChange={(e) => {
          let selected = e.target.checked;
          onChange(selected);
        }}
      />
      <p style={Label}>{text}</p>
    </div>
  );
};

export default Checkbox;

import { isNullString, isNullSelecction, isNullMap, isNullOrUndefined, isInvalidBoolean, isInvalidFloat } from "../../../../../../assets/utils/FormsUtils";

export const validateReportSender = (config, reportSender) => {

    if (reportSender == null) return config.getText("survey") + " " + config.getText("cantBeNull");


    if (isNullString(reportSender.alias)) return config.getText("alias") + ' ' + config.getText("cantBeNull");

    if (isNullSelecction(reportSender.surveyId)) return config.getText("survey") + ' ' + config.getText("cantBeNull");

    if (isNullSelecction(reportSender.type)) return config.getText("type") + ' ' + config.getText("cantBeNull");

    if (isNullOrUndefined(reportSender.emails) || reportSender.emails.length === 0) return config.getText("email") + ' ' + config.getText("cantBeNull");

    for (let index in (reportSender.emails || [])) {
        let email = reportSender.emails?.[index];
        let isValid = email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        if (!isValid) return config.getText("email") + ' ' + (index) + ' ' + config.getText("cantBeNull");
    }
    return null;
};
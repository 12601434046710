import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import UploadImg from "../../assets/images/ic_upload.png";
import EditIcon from "../../assets/images/edit-icon-white.svg";
import DeleteIcon from "../../assets/images/delete-icon-white.svg";
import DownloadingPopup from "./DownloadingPopup";
import Firebase from "../../assets/Firebase";
import ReactPlayer from "react-player";

const VideoPicker = ({ config, id, folder, videoName, onSelect, maxSizeMB = "600", width = "100%", height = "20vh", rootStyles }) => {

    let f = new Firebase();

    const [videoUrl, setVideoUrl] = useState(null);
    const [cursonOn, setCursonOn] = useState(false);
    const [videoPlaying, setVideoPlaying] = useState(false);
    const [loadingText, setLoadingText] = useState(null);

    const onSelectFile = (e) => {
        let file = e?.target?.files?.[0];

        setLoadingText(config?.strings?.uploadImage);
        if (folder) {
            f.uploadUserImageToFolder(file, folder, onSuccessUpload, onErrorUpload)
        } else {
            f.uploadUserImage(file, onSuccessUpload, onErrorUpload)
        }
    }

    const onSuccessUpload = (response) => {
        setLoadingText(null);
        onSelect(id, response?.name);
    }

    const onErrorUpload = (error) => {
        setLoadingText(null);
        config.popupAlert(config.getText("error"), config.getText("errorUpload"));
        console.error(config?.strings?.errorUploadImage, error);
    }

    const deleteVideo = () => {
        config.popupAlert(config.getText("delete"), config.getText("deleteConfirm"), null, true, () => {
            setVideoUrl(null);
            onSelect(id, null);
        });
    }

    const openSelectFileDialog = () => {
        document.getElementById("VideoInput-" + id).click();
    }

    useEffect(() => {
        if (videoName) {
            if (folder) {
                console.log("Hola 1", folder, videoName);
                f.loadUserImageFromFolder(folder, videoName, setVideoUrl);
            } else {
                f.loadUserImage(videoName, setVideoUrl);
            }
        }
    }, [videoName, folder]);

    useEffect(() => {
        console.log("Hola 2", videoUrl);
    }, [videoUrl]);

    const Wrapper = {
        height: height,
        width: width,
        textAlign: "center",
        backgroundColor: "#cbcbcb",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        ...rootStyles
    };

    const VideoBackground = {
        ...Wrapper,
        backgroundColor: "black",
        position: "relative",
        width: "100%",
        height: "100%",
    };

    const VideoWrapper = {
        width: "85%",
        height: "100%",
    }

    const IconsWrapper = {
        width: "15%",
        height: "100%",
        display: "flex",
        backgroundColor: "#2b3e50",
        flexDirection: "column",
        justifyContent: "space-evenly"
    }

    const ImageUpload = {
        maxHeight: "40%",
        maxWidth: "90%",
        cursor: "pointer",
        width: "auto",
    };

    const Icon = {
        width: "40%",
        cursor: "pointer",
        margin: "auto",
    };

    const ImageInput = styled.input`
        display: none;
    `;

    return (
        <div style={Wrapper}
            onMouseEnter={() => setCursonOn(true)}
            onMouseLeave={() => setCursonOn(false)}>

            {videoUrl !== null && videoUrl !== undefined ? (
                <div style={VideoBackground}>
                    <div style={VideoWrapper}>
                        <video height="100%" width="100%" src={videoUrl} controls={true}
                            onPlay={() => setVideoPlaying(true)}
                            onPause={() => setVideoPlaying(false)} />
                    </div>
                    <div style={IconsWrapper}>
                        <img src={EditIcon} style={Icon} onClick={openSelectFileDialog} />
                        <img src={DeleteIcon} style={Icon} onClick={deleteVideo} />
                    </div>
                </div>
            ) : (
                <img src={UploadImg} style={ImageUpload} onClick={openSelectFileDialog} />
            )}
            <ImageInput type="file" id={"VideoInput-" + id} accept="video/*" onChange={onSelectFile} />
            {loadingText != null ? <DownloadingPopup headerText={loadingText} /> : <></>}
        </div>
    );
};
export default VideoPicker;

import React, { useState, useEffect } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import Firebase from "../../../../../assets/Firebase";
import { isNullSelecction } from "../../../../../assets/utils/FormsUtils";
import ProfileComponent from "./ProfileComponent";

const ProfileEngine = () => {
  const config = useOutletContext();
  const navigate = useNavigate();
  const f = new Firebase();

  const [profile, setProfile] = useState({
    name: config?.user?.name,
    logo: config?.user?.logo,
    language: config?.lang,
    alternativeLanguages: config?.user?.alternativeLanguages || [],
    languageDisplayMode: config?.user?.languageDisplayMode
  });

  const Langs = {
    notSelected: config.strings.notSelected,
    es_ES: config.strings?.es_ES,
    ca_ES: config.strings?.ca_ES,
    en_GB: config.strings?.en_GB,
    de_DE: config.strings?.de_DE,
    fr_FR: config.strings?.fr_FR,
    pt_PR: config.strings?.pt_PR,
    it_IT: config.strings?.it_IT,
    ru_RU: config.strings?.ru_RU,
    zh_CN: config.strings?.zh_CN,
    ar_SA: config.strings?.ar_SA
  }

  const LangsDisplayOptions = {
    TEXT: config.getText("textLangOption"),
    ICON: config.getText("iconLangOption")
  }

  const updateProfile = (key, value) => {
    setProfile((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  const confirmAction = () => {
    f.saveUserData(profile, (response) => {
      config.update("user", { ...config?.user, ...profile });
      if (!isNullSelecction(!profile.language)) config.setLang(profile.language);

      config.popupAlert(config.strings.correct, config.strings.successMsg, () => {
        navigate(`/dashboard/home`);
      });
    }, (error) => {
      config.popupAlert(config?.strings?.error, config?.strings?.alertError);
      console.error("Error saving profile: ", error);
    })
  }

  const cancelAction = () => {
    navigate(`/dashboard/home`);
  }

  useEffect(() => {
    if (profile.language !== config?.lang) {
      updateProfile("language", config?.lang)
    }
  }, [config?.lang]);

  return (
    <ProfileComponent
      config={config}
      profile={profile}
      langs={Langs}
      langsDisplayOptions={LangsDisplayOptions}
      updateProfile={updateProfile}
      confirmAction={confirmAction}
      cancelAction={cancelAction} />
  );
};

export default ProfileEngine;

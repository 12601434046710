import React, { useState, useEffect } from "react";
import ListComponent from "./SurveyListComponent";
import LoadingScreen from "../../../../../shared/LoadingScreen";
import DownloadingPopup from "../../../../../shared/DownloadingPopup";
import { useNavigate, useOutletContext } from "react-router-dom";
import Firebase from "../../../../../../assets/Firebase";
import Config from "../../../../../../assets/Config";

const SurveyListEngine = () => {
  const config = useOutletContext();
  const navigate = useNavigate();
  let fdb = new Firebase();

  const [surveys, setSurveys] = useState(null);
  const [loadingText, setLoadingText] = useState(null);

  const loadSurveys = () => {
    fdb.loadSurveys((data) => {
      console.debug("Surveys loaded: ", data);
      let parsedData = {};
      Object.entries(data).forEach(([id, value]) => {
        parsedData[id] = {
          name: value?.name || config.strings.notNamed,
          type: value?.type || config.strings.noType,
          creationDate: value?.creationDate?.toDate() || "-",
          lastUpdate: value?.lastUpdate?.toDate() || "-",
        }
      });
      setSurveys(parsedData);
    }, (error) => {
      console.error("Error loading surveys: ", error);
      setSurveys({});
    });

  }

  useEffect(() => {
    if (surveys == null) {
      loadSurveys();
    }
  }, []);

  const createSurvey = () => {
    navigate(`/dashboard/surveys/new`);
  };

  const editSurvey = (id) => {
    navigate(`/dashboard/surveys/edit/${id}`);
  };

  const deleteSurvey = (key) => {
    config.popupAlert(config.getText("delete"), config.getText("deleteConfirm"), null, true, () => {
      fdb.deleteSurvey(key,
        (response) => {
          console.log("Survey with ID " + key + " has been deleted", response);
          setSurveys((prevState) => {
            const state = { ...prevState };
            delete state[key];
            return state;
          });
        },
        (error) => {
          console.error("Error deleting survey with ID " + key, error);
          config.popupAlert("Error", "Error deleting survey");
        });
    });
  };

  const cloneSurvey = (id) => {
    config.popupAlert(config.getText("cloneSurvey"), config.getText("cloneSurveyConfirm"), null, true, () => {
      setLoadingText(config.getText("cloning"));
      fdb.cloneSurvey(id,
        (res) => {
          loadSurveys();
          setLoadingText(null);
          config.popupAlert("Info", "Survey cloned successfully");
        },
        (error) => {
          setLoadingText(null);
          console.error("Error cloning survey with ID " + id, error);
          config.popupAlert("Error", "Error deleting survey");
        }
      );
    });
  }

  const openSurveyApp = (id) => {
    // TODO: Change app tmp url
    new Config();
    let subdomain = Config.ENV === "DEV" || Config.ENV === "LOCAL" ? "dev" : "www";
    let url = `https://${subdomain}.quickratesurvey.com/survey/${fdb.getUid()}/${id}`;
    window.open(url);
  }

  const showReport = (id) => {
    navigate(`/dashboard/reports/${id}`);
  }

  return (<>
    {surveys != null ? (
      <ListComponent
        config={config}
        surveys={surveys}
        actions={{
          createSurvey: createSurvey,
          editSurvey: editSurvey,
          deleteSurvey: deleteSurvey,
          openSurveyApp: openSurveyApp,
          showReport: showReport,
          cloneSurvey: cloneSurvey
        }} />
    ) : (
      <LoadingScreen />
    )}
    {loadingText != null ? <DownloadingPopup strings={config.strings} headerText={loadingText} /> : <></>}
  </>
  );
};

export default SurveyListEngine;

import React from "react";
import styled from "@emotion/styled";
import Colors from "../../assets/Colors.json";

const Button = ({ id = "actionButton", value, action = () => {}, style, enabled = true }) => {
  let theme = "lightTheme";
  let colors = Colors[theme];

  const Wrapper = styled["div"]`
    display: inline-block;
    flex: 0 1 auto; /* 1 */
    overflow: hidden;
    border: none;
    background-color: ${enabled ? colors.accentColor1 : "gray"};
    color: white;
    padding: 1vh 3vh;
    cursor: ${enabled ? "pointer" : "not-allowed"};
    text-align: center;
    transition: 0.1s;
    text-transform: uppercase;
    border-radius: 0.5vh;
    &:hover {
    background - color: ${ enabled ? colors.accentColor2 : "" };
    }
    @media (max-width: 426px) {
      padding: 1vh 1vh;
    }
    ${style};
  `;

  return (
    <Wrapper id={id} onClick={enabled ? (e) => action(e) : () => { }}>
      {value}
    </Wrapper>
  );
};

export default Button;

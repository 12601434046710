import React from "react";
import styled from "@emotion/styled";
import Button from "../../../../../../shared/Button";
import Checkbox from "../../../../../../shared/Checkbox";
import SecondaryButton from "../../../../../../shared/SecondaryButton";
import Table from "../../../../../../shared/Table";

const RawReportComponent = ({ config, survey, responses, reportData, includeEmptyResponses, actions }) => {
    const Wrapper = {
        width: "100%",
        position: "relative"
    }

    const ActionsWrapper = {
        position: "absolute",
        top: "0",
        right: "0",
        display: "flex",
        alignItems: "center"
    }


    const Tag = styled.div`
        flex: 1;
        font-size: 2.7vh;
        max-height: 2.7vh;
        border-bottom: 1px solid grey;
        margin-top: 1vh;
        margin-bottom: 2vh;
        padding-bottom: 2vh;
    `;

    return (
        <div style={Wrapper}>
            <div style={ActionsWrapper}>
                <Checkbox text={config.getText("IncludeEmptyResponses")} checked={includeEmptyResponses} onChange={actions.setIncludeEmptyResponses} />
                <SecondaryButton value={config?.strings?.downloadExcel} action={actions.dowloadExcel} style="margin-right: 2vh;" />
                <Button value={config?.strings?.graphs} action={() => { actions.updateReportData("mode", "GRAPHS") }} />
            </div>
            <Tag>Raw data:</Tag>
            <Table
                strings={config?.strings}
                header={config?.strings?.responsesDataHeaders}
                exportHeader={config?.strings?.exportedResponsesDataHeaders}
                data={actions.getRawData(false)}
                downloadableData={actions.getRawData(true)}
                noElementsText={config?.strings?.noElementsText}
                ready={true}
                sortByField={"creationDate"}
                sortOrder={"ascending"}
                onEventsBind={actions.setTableActions}
                styles={`
                    width: 100%;
                    max-width: 100%;
                    th {
                        padding: 2vh 2vh;
                    }
                    td {
                        padding: 1.5vh 2vh;
                    }
                `} />
        </div>
    );

};
export default RawReportComponent;
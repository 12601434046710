import React, { useState } from "react";
import styled from "@emotion/styled";
import Radium from "radium";
import Colors from "../../assets/Colors.json";

const DivButtons = ({ id = "selectId", options, defaultOption, width = "95%", styles, disabled = false, onClick = () => { }, config }) => {

  const [activeKey, setActiveKey] = useState(defaultOption)

  const Wrapper = styled.div`
    border-radius: 0.3vh;
    /* border: solid 0.2vh #d8dde1; */
    padding: 1.5vh;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
    width: ${width};
    &:focus {
      border-color: ${Colors.lightTheme.accentColor1};
    }
    ${styles ? styles : ""}
  `;

  const Option = styled.button`
    ${props => `
      width: 10vw;
      height: 15vh;
      background-repeat:no-repeat;
      background-position:center;
      background-size: contain;
      cursor: pointer;
      border-radius: 1.5vh;
      border: solid 0.2vh #d8dde1;
      background-color: ${props.value === activeKey ? Colors.lightTheme.accentColor1 : "unset"};

      &:hover {
        background-color: ${Colors.lightTheme.darkGrey};
        border-color: black;
      }

      ${props.style}
    `}
  `;

  const getIcon = (icon) => {
    try {
      return require(`../../assets/images/${icon}.png`);
    } catch (e) {
      return null;
    }
  }

  const sortOptions = (options) => {
    let entries = Object.entries(options || {});
    let sortedEntries = entries.sort(([keyA, valA], [keyB, valB]) => {
      if (keyA === "notSelected") return -1;
      else if (keyB === "notSelected") return 1;
      else if (valA?.toLowerCase() < valB?.toLowerCase()) return -1;
      else if (valA?.toLowerCase() > valB?.toLowerCase()) return 1;
      else return 0;
    });
    const sortedObj = Object.fromEntries(sortedEntries);
    return sortedObj;
  }

  const click = () => {
    config.popupAlert(config?.strings?.changeBTitle, config?.strings?.changeBSubTitle, null, true, (e) => {
      setActiveKey("BUTTON_PANEL");
      onClick(id, "BUTTON_PANEL")
    })
  }

  const loadOptions = () => {
    let Views = [];
    for (let key in options) {
      Views.push(
        <Option style={{ backgroundImage: "url(" + getIcon(key) + ")" }} name="types" value={key} onClick={onClick ? key === "BUTTON_PANEL" ? click : (e) => {
          setActiveKey(key);
          onClick(id, e.target.value)
        } : null}>
        </Option >
      );
    }
    return Views;
  };


  return (
    <Wrapper id={id} size={4}>
      {loadOptions()}
    </Wrapper>
  );
};

export default Radium(DivButtons);

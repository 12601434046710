import app from "firebase/compat/app";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";
import "firebase/compat/analytics";
import Config from "./Config";

const configDev = {
  apiKey: "AIzaSyCokZxpXqOc4c1AloJC1Rjl1MDk4BUTOvI",
  authDomain: "quickratesurvey-dev.firebaseapp.com",
  projectId: "quickratesurvey-dev",
  storageBucket: "quickratesurvey-dev.appspot.com",
  messagingSenderId: "919629346482",
  appId: "1:919629346482:web:c222e724a089bc54293bab"
};

const configProd = {
  apiKey: "AIzaSyBP9o_ywW3R6MEAOmP8diiko42K_dVnzr4",
  authDomain: "quickratesurvey-a121d.firebaseapp.com",
  projectId: "quickratesurvey-a121d",
  storageBucket: "quickratesurvey-a121d.appspot.com",
  messagingSenderId: "1056356721543",
  appId: "1:1056356721543:web:a6aafd6694b7b1394ae922"
};

class Firebase {
  constructor() {
    try {
      if (typeof Firebase.instance === "object") {
        return Firebase.instance;

      } else {
        new Config();
        Firebase.instance = this;
        Firebase.instance.firebase = app.initializeApp(Config.ENV === "DEV" || Config.ENV === "LOCAL" ? configDev : configProd);

        app.analytics().setAnalyticsCollectionEnabled(true);

        this.runWhenSignUp(() => {
          app.analytics().setUserId(firebase.auth().currentUser.uid);
        });

        return this;
      }
    } catch (r) {
      console.error("Error init firebase: ", r);
    }
  }

  tryCreateUser(email, password, password2, props, success, error) {
    //const ba = new BackendAccessor();
    this.firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {

        let uid = userCredential.user.uid;

        let user = {
          email: email,
          language: "es_ES",
          logo: "",
        }

        firebase.firestore().collection("Users").doc(uid).set(user)
          .then(() => {
            success();
          })
          .catch(function (err) {
            error(err.code);
          });
      })
      .catch(function (err) {
        error(err.code);
      });
  }

  tryLogin(name, password, success, error) {
    this.firebase
      .auth()
      .signInWithEmailAndPassword(name, password)
      .then(() => {
        success();
      })
      .catch(function (err) {
        error(err);
      });
  }

  tryLoginWithCustomToken(token, success, error) {
    this.firebase
      .auth()
      .signInWithCustomToken(token)
      .then(success)
      .catch(error);
  }

  isEmailVerificated() {
    return firebase.auth().currentUser.emailVerified;
  }

  changeUserPassword(newPassword, success, error) {
    this.firebase.auth().currentUser.updatePassword(newPassword).then(success).catch(error);
  }

  reauthenticateUser(password, success, error) {
    let user = this.firebase.auth().currentUser;
    let credential = firebase.auth.EmailAuthProvider.credential(user.email, password);
    user.reauthenticateWithCredential(credential).then(success).catch(error);
  }

  tryLogout(success, error) {
    this.firebase
      .auth()
      .signOut()
      .then(function () {
        success();
      })
      .catch(function (err) {
        if (error) error(err);
      });
  }

  checkSignedUser(success, error) {
    this.firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        Firebase.user = user;
        success(user);
      } else if (error) {
        error()
      }
    });
  }

  waitForTokenId(success, fail) {
    this.runWhenSignUp(() => {
      this.getTokenId(success, fail);
    });
  }

  getCustomToken(tokenId, success, fail) {
    let verifyTokenId = this.firebase.functions().httpsCallable("verifyTokenId");
    verifyTokenId({ tokenId: tokenId })
      .then((result) => {
        if (success) success(result);
      })
      .catch((error) => {
        if (fail) fail(error);
      });
  }

  runWhenSignUp(action) {
    this.firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        action();
      }
    });
  }

  sendEmailVerification(email, success, fail) {
    let sendEmail = this.firebase.functions().httpsCallable("emailVerificationSender");
    sendEmail({ email: email })
      .then((result) => {
        if (success) success();
      })
      .catch((error) => {
        if (fail) fail();
      });
  }

  checkEmailResetPassword(email, success, error) {
    this.firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(function () {
        success();
      })
      .catch(function (e) {
        error(e.code);
      });
  }

  getCreationDate() {
    return this.firebase.auth().currentUser?.metadata.creationTime;
  }

  getTokenId(onSuccess, onError) {
    let user = this.firebase.auth().currentUser;

    if (user === null || user === undefined) {
      onError("You must be singned up");
    } else {
      user.getIdToken(true)
        .then(onSuccess)
        .catch(onError);
    }
  }

  getRefFromPath(path) {
    try {
      return this.firebase.firestore().doc(path);
    } catch (e) {
      return null;
    }
  }

  getServerTimeStamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  loadUserImageFromFolder(folder, imageName, success) {
    this.runWhenSignUp(() => {
      let ref = this.firebase.storage().ref().child(`users/${Firebase.user.uid}/${folder}/${imageName}`);
      this.loadImage(ref, success);
    });
  }

  loadUserImage(imageName, success) {
    this.runWhenSignUp(() => {
      let ref = this.firebase.storage().ref().child(`users/${Firebase.user.uid}/${imageName}`);
      this.loadImage(ref, success);
    });
  }

  loadImage(ref, success) {
    this.firebase
      .storage()
      .refFromURL(ref)
      .getDownloadURL()
      .then((url) => success(url))
      .catch((error) => console.error("LoadImage error: ", error));
  }

  uploadUserImageToFolder(image, folder, success, fail) {
    let ref = this.firebase.storage().ref().child(`users/${Firebase.user.uid}/${folder}/${image?.name || this.createUid()}`);
    this.uploadImage(ref, image, success, fail);
  }

  uploadUserImage(image, success, fail) {
    let ref = this.firebase.storage().ref().child(`users/${Firebase.user.uid}/${this.createUid()}`);
    this.uploadImage(ref, image, success, fail);
  }

  uploadImage(ref, image, success, fail) {
    ref.put(image).then(function (snapshot) {
      success({
        gsUrl: `gs://${snapshot.metadata.bucket}/${snapshot.metadata.fullPath}`,
        path: snapshot.metadata.fullPath,
        name: snapshot.metadata.name,
      });
    }).catch(fail);
  }

  getUid() {
    return firebase?.auth()?.currentUser?.uid;
  }

  createUid(length = 20) {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  loadUserData(onSuccess, onFail) {
    var db = this.firebase.firestore();
    db.collection("Users")
      .doc(this.getUid())
      .get()
      .then(snap => {
        onSuccess(this.getCompleteData(snap));
      }).catch(onFail);
  }

  saveUserData(userData, onSuccess, onFail) {
    var db = this.firebase.firestore();
    db.collection("Users")
      .doc(this.getUid())
      .update(this.getUpdatableData(userData))
      .then(onSuccess)
      .catch(onFail);
  }

  loadSurveys(onSuccess, onFail) {
    var db = this.firebase.firestore();
    db.collection("Users")
      .doc(this.getUid())
      .collection("surveys")
      .where("deleted", "==", false)
      .get()
      .then(snap => {
        let data = {};
        snap.forEach((doc) => {
          data[doc.id] = this.getCompleteData(doc);
        });
        if (onSuccess) onSuccess(data);
      }).catch(function (error) {
        if (onFail) onFail(error);
      });;
  }

  loadCompleteSurvey(surveyId, onSuccess, onFail) {
    var db = this.firebase.firestore();
    db.collection("Users").doc(this.getUid()).collection("surveys").doc(surveyId).get().then(snap => {
      if (snap.exists) {
        let survey = this.getCompleteData(snap);

        this.loadScreens(survey?.ref, (screens) => {
          survey.screens = screens;
          onSuccess(survey);
        }, onFail);
      } else {
        throw "Snap not exists";
      }
    }).catch(onFail);
  }

  loadScreens(surveyRef, onSuccess, onFail) {
    surveyRef
      .collection("screens")
      .where("deleted", "==", false)
      .get().then((screensSnap) => {
        let screens = [];
        if (screensSnap.empty) onSuccess(screens);

        screensSnap.forEach((screenDoc) => {
          let screen = this.getCompleteData(screenDoc);

          this.loadQuestions(screen?.ref, (questions) => {
            screen.questions = questions;
            screens.push(screen);

            if (screens.length === screensSnap.size) {
              this.orderList(screens);
              onSuccess(screens);
            }
          }, onFail);
        });
      }).catch(onFail);
  }

  loadQuestions(screenRef, onSuccess, onFail) {
    screenRef.collection("questions")
      .where("deleted", "==", false)
      .get().then((questionsSnap) => {
        let questions = [];
        if (questionsSnap.empty) onSuccess(questions);

        questionsSnap.forEach((questionDoc) => {
          let question = this.getCompleteData(questionDoc);

          this.loadOptions(question?.ref, (options) => {
            question.options = options;
            questions.push(question);

            if (questions.length === questionsSnap.size) {
              this.orderList(questions);
              onSuccess(questions);
            }
          }, onFail);
        });
      }).catch(onFail);
  }

  loadOptions(questionRef, onSuccess, onFail) {
    questionRef.collection("options")
      .where("deleted", "==", false)
      .get().then((optionsSnap) => {
        let options = [];
        if (optionsSnap.empty) onSuccess(options);

        optionsSnap.forEach((optionDoc) => {
          let option = this.getCompleteData(optionDoc);

          this.loadSuboptions(option?.ref, (suboptions) => {
            option.suboptions = suboptions;
            options.push(option);

            if (options.length === optionsSnap.size) {
              this.orderList(options);
              onSuccess(options);
            }
          }, onFail);
        });
      }).catch(onFail);
  }

  loadSuboptions(optionRef, onSuccess, onFail) {
    optionRef.collection("subOptions")
      .where("deleted", "==", false)
      .get().then((suboptionsSnap) => {
        let suboptions = [];
        if (suboptionsSnap.empty) {
          onSuccess(suboptions);
        } else {
          suboptionsSnap.forEach((suboptionDoc) => {
            let suboption = this.getCompleteData(suboptionDoc);
            suboptions.push(suboption);
            if (suboptions.length === suboptionsSnap.size) {
              this.orderList(suboptions);
              onSuccess(suboptions);
            }

          });
        }
      }).catch(onFail);
  }

  loadSurveyResponses(surveyId, includeQuestion, fromDate, toDate, onSuccess, onFail) {
    var db = this.firebase.firestore();
    db.collection("Users").doc(this.getUid()).collection("surveys").doc(surveyId).collection("responses")
      .where("creationDate", ">", fromDate)
      .where("creationDate", "<", toDate)
      .where("deleted", "==", false)
      .get().then(survResSnap => {
        let surveyResponses = [];

        if (survResSnap.empty) {
          onSuccess(surveyResponses);
        } else {
          survResSnap.forEach((survResDoc) => {
            let surveyResponse = this.getCompleteData(survResDoc);

            let pushSurveyResponse = () => {
              surveyResponses.push(surveyResponse);
              if (surveyResponses.length === survResSnap.size) {
                onSuccess(surveyResponses);
              }
            }

            if (includeQuestion) {
              this.loadQuestionsAnswers(surveyResponse?.ref, (questionAnswers) => {
                if (questionAnswers.length > 0) {
                  surveyResponse.answers = questionAnswers
                }
                pushSurveyResponse();
              }, onFail);

            } else {
              pushSurveyResponse();
            }
          });
        }
      }).catch(onFail);
  }

  loadButtonPanelResponses(fromDate, toDate, onSuccess, onFail) {
    var db = this.firebase.firestore();
    db.collection("Users").doc(this.getUid()).collection("buttonPanelAnswers")
      .where("creationDate", ">", fromDate)
      .where("creationDate", "<", toDate)
      .where("deleted", "==", false)
      .get().then(snap => {
        let buttonPanelResponses = [];

        if (snap.empty) {
          onSuccess(buttonPanelResponses);
        } else {
          snap.forEach((survResDoc) => {
            let buttonPanelResponse = this.getCompleteData(survResDoc);
            buttonPanelResponses.push(buttonPanelResponse);
            if (buttonPanelResponses.length === snap.size) {
              onSuccess(buttonPanelResponses);
            }
          });
        }

      }).catch(onFail);
  }

  loadQuestionsAnswers(responseRef, onSuccess, onFail) {
    responseRef.collection("answers").get().then(questionsAnsSnap => {
      let questionAnswers = [];

      if (questionsAnsSnap.empty) {
        onSuccess(questionAnswers);
      } else {
        questionsAnsSnap.forEach((questionAnsDoc) => {
          let questionAnswer = this.getCompleteData(questionAnsDoc);
          questionAnswers.push(questionAnswer);
          if (questionAnswers.length === questionsAnsSnap.size) {
            onSuccess(questionAnswers);
          }
        });
      }
    }).catch(onFail);
  }

  orderList(list) {
    list.sort((elementA, elementB) => {
      let orderA = (elementA.order + 1) || Number.MAX_SAFE_INTEGER;
      let orderB = (elementB.order + 1) || Number.MAX_SAFE_INTEGER;
      return orderA - orderB;
    })
  }

  getCompleteData(docSnap) {
    if (!docSnap.exists) return null;
    return {
      ref: docSnap.ref,
      path: docSnap.ref.path,
      id: docSnap.ref.id,
      ...docSnap.data()
    }
  }

  deleteSurvey(id, onSaved, onError) {
    var db = this.firebase.firestore();
    db.collection("Users")
      .doc(this.getUid())
      .collection("surveys")
      .doc(id)
      .update({ deleted: true })
      .then(onSaved)
      .catch(onError);
  }

  saveSurvey(survey, onSuccess, onError) {
    let updatableData = this.getUpdatableData(survey);

    let onSaved = (ref) => {
      if (ref) survey.ref = ref; 
      if (survey?.screens?.length > 0) {
        this.saveScreens(survey.ref, survey.screens, onSuccess, onError);
      } else {
        onSuccess();
      }
    }

    if (!survey.ref) {
      var db = this.firebase.firestore();
      db.collection("Users").doc(this.getUid()).collection("surveys").add(updatableData)
        .then(onSaved)
        .catch(onError);
    } else {
      survey.ref.update(updatableData)
        .then(onSaved)
        .catch(onError);
    }
  }

  saveScreens(surveyRef, screens, onSuccess, onError) {
    let savedScreens = 0;

    screens.forEach((screen, index) => {
      let updatableData = this.getUpdatableData(screen, index);
      updatableData.type = (screen?.questions?.length > 1) ? "DOUBLE" : "SIMPLE";

      let onSaved = (ref) => {
        if (ref) {
          screen.ref = ref;
        } else if (screen?.id) {
          screen.ref = surveyRef.collection("screens").doc(screen?.id);
        }

        if (screen?.questions?.length > 0) {
          this.saveQuestions(screen.ref, screen?.questions, onDependencesSaved, onError);
        } else {
          onDependencesSaved();
        }
      }

      let onDependencesSaved = () => {
        savedScreens++;
        if (savedScreens === screens.length) {
          this.checkDeletedObjects(surveyRef.collection("screens"), screens, onSuccess, onError);
        }
      }

      let action;
      if (screen?.ref) {
        action = screen.ref.update(updatableData);
      } else if (this.isValidId(screen?.id)) {
        action = surveyRef.collection("screens").doc(screen?.id).set(updatableData);
      } else {
        action = surveyRef.collection("screens").add(updatableData);
      }
      action.then(onSaved).catch(onError);
    });
  }

  saveQuestions(screenRef, questions, onSuccess, onError) {
    let savedQuestions = 0;

    questions.forEach((question, index) => {
      let updatableData = this.getUpdatableData(question, index);

      let onSaved = (ref) => {
        if (ref) {
          question.ref = ref;
        } else if (question?.id) {
          question.ref = screenRef.collection("questions").doc(question?.id);
        }

        if (question?.options?.length > 0) {
          this.saveOptions(question.ref, question?.options, onDependencesSaved, onError);
        } else {
          onDependencesSaved();
        }
      }

      let onDependencesSaved = () => {
        savedQuestions++;
        if (savedQuestions === questions.length) {
          this.checkDeletedObjects(screenRef.collection("questions"), questions, onSuccess, onError);
        }
      }

      let action;
      if (question?.ref) {
        action = question.ref.update(updatableData)
      } else if (this.isValidId(question?.id)) {
        action = screenRef.collection("questions").doc(question?.id).set(updatableData);
      } else {
        action = screenRef.collection("questions").add(updatableData);
      }
      action.then(onSaved).catch(onError);
    });
  }

  saveOptions(questionRef, options, onSuccess, onError) {
    let savedOptions = 0;

    options.forEach((option, index) => {
      let updatableData = this.getUpdatableData(option, index);

      let onSaved = (ref) => {
        if (ref) {
          option.ref = ref;
        } else if (option.id) {
          option.ref = action = questionRef.collection("options").doc(option?.id);
        }

        if (option?.suboptions?.length > 0) {
          this.saveSuboptions(option.ref, option?.suboptions, onDependencesSaved, onError);
        } else {
          onDependencesSaved();
        }
      }

      let onDependencesSaved = () => {
        savedOptions++;
        if (savedOptions === options.length) {
          this.checkDeletedObjects(questionRef.collection("options"), options, onSuccess, onError);
        }
      }

      let action;
      if (option?.ref) {
        action = option.ref.update(updatableData);
      } else if (this.isValidId(option?.id)) {
        action = questionRef.collection("options").doc(option?.id).set(updatableData);
      } else {
        action = questionRef.collection("options").add(updatableData);
      }
      action.then(onSaved).catch(onError);
    });
  }

  saveSuboptions(optionRef, suboptions, onSuccess, onError) {
    let savedSubptions = 0;

    suboptions.forEach((suboption, index) => {
      let updatableData = this.getUpdatableData(suboption, index);

      let onSaved = (ref) => {
        if (ref) suboption.ref = ref;
        savedSubptions++;
        if (savedSubptions === suboptions.length) {
          this.checkDeletedObjects(optionRef.collection("subOptions"), suboptions, onSuccess, onError);
        }
      }

      if (suboption?.ref) {
        suboption.ref.update(updatableData)
          .then(onSaved)
          .catch(onError);
      } else {
        optionRef.collection("subOptions").add(updatableData)
          .then(onSaved)
          .catch(onError);
      }
    });
  }

  checkDeletedObjects(collectionReference, localList, onSuccess, onError) {
    collectionReference.where("deleted", "==", false).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let existsInLocal = localList.some(localElement => localElement.ref.path === doc.ref.path);
        if (!existsInLocal) {
          console.log("Delete", doc.ref);
          doc.ref.update({ deleted: true });
        }
        onSuccess();
      });
    }).catch(onError);
  }

  cloneSurvey(id, onSaved, onError) {
    this.loadCompleteSurvey(id, (survey) => {
      let surveyClone = { ...survey };
      surveyClone.name += " (Clone)";
      this.removeRef(surveyClone);
      this.saveSurvey(surveyClone, onSaved, onError);
    });
  }

  removeRef(object) {
    if (object.ref) delete object.ref;

    Object.keys(object).forEach(key => {
      if (typeof object[key] === 'object') {
        object[key] = this.removeRef(object[key]);
      }
      if (Array.isArray(object[key])) {
        object[key] = object[key].map(element => this.removeRef(element));
      }
    });

    return object;
  }

  getDevicesList(onSuccess, onFail) {
    var db = this.firebase.firestore();

    db.collection("Users").doc(this.getUid())
      .collection("devices")
      .where("deleted", "==", false)
      .get()
      .then(snap => {
        let read = 0;
        let data = {};

        if (snap.empty) {
          onSuccess(data);
          return;
        }

        snap.forEach((doc) => {

          // Read last status
          doc.ref.collection("status")
            .where("deleted", "==", false)
            .orderBy("creationDate", "desc")
            .limit(1)
            .get()
            .then(statusSnap => {
              let status = [];
              statusSnap.forEach((satusDoc) => {
                status.push(this.getCompleteData(satusDoc));
              });

              data[doc.id] = {
                ...doc.data(),
                status: status
              };

              if (++read == snap.size && onSuccess) onSuccess(data);

            }).catch(onFail);
        });
      }).catch(onFail);
  }

  getDeviceById(id, onSuccess, onFail) {
    var db = this.firebase.firestore();

    db.collection("Users")
      .doc(this.getUid())
      .collection("devices")
      .doc(id)
      .get()
      .then(deviceDocSnap => {
        let data = this.getCompleteData(deviceDocSnap)

        deviceDocSnap.ref.collection("status")
          .where("deleted", "==", false)
          .orderBy("creationDate", "desc")
          .limit(200)
          .get()
          .then(statusSnap => {
            let status = [];
            statusSnap.forEach((satusDoc) => {
              status.push(this.getCompleteData(satusDoc));
            });

            data.status = status;
            onSuccess(data);
          }).catch(onFail);

      }).catch(onFail);
  }

  getSimpleDeviceById(id, onSuccess, onFail) {
    var db = this.firebase.firestore();
    db.collection("Users")
      .doc(this.getUid())
      .collection("devices")
      .doc(id)
      .get()
      .then(deviceDocSnap => {
        let data = this.getCompleteData(deviceDocSnap)
        onSuccess(data);
      }).catch(onFail);
  }


  getReportSendersList(onSuccess, onFail) {
    var db = this.firebase.firestore();

    db.collection("Users").doc(this.getUid())
      .collection("reportSenders")
      .where("deleted", "==", false)
      .get()
      .then(snap => {
        let data = {};
        snap.forEach((doc) => {
          data[doc.id] = doc.data();
        });
        if (onSuccess) onSuccess(data);
      }).catch(onFail);;
  }

  getReportSenderById(id, onSuccess, onFail) {
    var db = this.firebase.firestore();

    db.collection("Users")
      .doc(this.getUid())
      .collection("reportSenders")
      .doc(id)
      .get()
      .then(docSnap => {
        let data = this.getCompleteData(docSnap);
        onSuccess(data);
      }).catch(onFail);
  }

  saveReportSender(reportSender, onSuccess, onError) {
    let updatableData = this.getUpdatableData(reportSender);

    if (!reportSender.ref) {
      var db = this.firebase.firestore();
      db.collection("Users").doc(this.getUid()).collection("reportSenders").add(updatableData)
        .then(onSuccess)
        .catch(onError);
    } else {
      reportSender.ref.update(updatableData)
        .then(onSuccess)
        .catch(onError);
    }
  }

  deleteReportSender(id, onSaved, onError) {
    var db = this.firebase.firestore();
    db.collection("Users")
      .doc(this.getUid())
      .collection("reportSenders")
      .doc(id)
      .update({ deleted: true })
      .then(onSaved)
      .catch(onError);
  }

  getReportSendersRegisterList(reportSenderId, onSuccess, onFail) {
    var db = this.firebase.firestore();

    db.collection("Users").doc(this.getUid())
      .collection("reportSenders")
      .doc(reportSenderId)
      .collection("registers")
      .where("deleted", "==", false)
      .orderBy("creationDate", "desc")
      .limit(10)
      .get()
      .then(snap => {
        let data = {};
        snap.forEach((doc) => {
          data[doc.id] = doc.data();
        });
        if (onSuccess) onSuccess(data);
      }).catch(onFail);;
  }

  getRGPD(onSuccess, onFail) {
    this.getUserRGPD(this.getUid(), (data) => {
      if (data != null) {
        onSuccess(data);
      } else {
        this.getUserRGPD("default", onSuccess, onFail);
      }
    }, onFail);
  }

  getUserRGPD(uid, onSuccess, onFail) {
    var db = this.firebase.firestore();
    db.collection("Users")
      .doc(uid)
      .collection("pages")
      .doc("RGPD")
      .get()
      .then(docSnap => {
        let data = this.getCompleteData(docSnap)
        onSuccess(data);
      }).catch(onFail);
  }

  saveRGPD(RGPD, onSuccess, onFail) {
    var db = this.firebase.firestore();
    db.collection("Users")
      .doc(this.getUid())
      .collection("pages")
      .doc("RGPD")
      .set(this.getUpdatableData(RGPD))
      .then(onSuccess)
      .catch(onFail);
  }

  updateDevice(device, onSuccess, onFail) {
    device.ref
      .update(this.getUpdatableData(device))
      .then(onSuccess)
      .catch(onFail);
  }

  isValidId(id) {
    return id !== null &&
      id !== undefined &&
      id.length > 0 &&
      /\S/.test(id)
  }

  getUpdatableData(completeData, order) {
    const tmpFields = ["ref", "id", "path", "metadata", "screens", "questions", "options", "suboptions", "status"];
    let updatableData = {
      ...completeData
    }

    Object.keys(updatableData).forEach(key => updatableData[key] === undefined ? delete updatableData[key] : {});

    tmpFields.forEach((tmpField) => {
      if (tmpField in updatableData) delete updatableData[tmpField];
    });

    if (!("creationDate" in updatableData)) {
      updatableData.creationDate = firebase.firestore.Timestamp.now().toDate();
    }

    if (order !== null && order !== undefined) updatableData.order = order;
    updatableData.lastUpdate = firebase.firestore.Timestamp.now().toDate();
    updatableData.deleted = false;

    return updatableData;
  }
}
export default Firebase;

import React from "react";
import styled from "@emotion/styled";
import TextInput from "../../../../shared/TextInput";
import Select from "../../../../shared/Select";
import Button from "../../../../shared/Button";
import SecondaryButton from "../../../../shared/SecondaryButton";
import ImagePicker from "../../../../shared/ImagePicker";
import Checkbox from "../../../../shared/Checkbox";

const ProfileComponent = ({ config, profile, langs, langsDisplayOptions, updateProfile, confirmAction, cancelAction }) => {

  const Title = styled.div`
    background-color: darkgray;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    padding: 2vh 5vh;
  `;

  const Wrapper = {
    width: "calc(100% - 12vh)",
    display: "flex",
    justifyContent: "center",
    padding: "4vh 6vh",
    flexDirection: "column",
  }

  const Row = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    columnGap: "5vh"
  }

  const WrapedRow = {
    ...Row,
    flexWrap: "wrap"
  }

  const Column = {
    ...Row,
    flexDirection: "column"
  }

  const Cell = {
    marginBottom: "3vh"
  }

  const Tag = styled.div`
    flex: 1;
    font-size: 3vh;
    border-bottom: 1px solid grey;
    margin-bottom: 2vh;
    padding-bottom: 2vh;
  `

  const ButtonWrapper = styled.div`
    display: flex;
    place-items: center;
    border-top: 1px solid grey;
    padding-top: 0.5rem;
    margin-top: 0.5rem;
  `;

  return (
    <div style={{ width: "100%" }}>
      <Title>{config.strings.editProfile}</Title>
      <div style={Wrapper}>
        <div style={Row}>

          <div style={Column}>

            <div style={Cell}>
              <Tag>Nombre</Tag>
              <TextInput
                id="name"
                width={"100%"}
                value={profile?.name}
                onChange={(e) => updateProfile("name", e.target.value)} />
            </div>

            <div style={Cell}>
              <Tag>{config.strings.langDefault}</Tag>
              <Select
                id={"language"}
                width={"100%"}
                options={langs}
                defaultOption={profile?.language}
                onChange={updateProfile}
              />
            </div>

            <div style={Cell}>
              <Tag>{config.strings.idiomasTitulo}</Tag>
              <div style={WrapedRow}>
                {Object.entries(langs).map(([code, lang]) => {
                  return (code !== "notSelected" ? (
                    <Checkbox
                      text={lang}
                      checked={profile?.alternativeLanguages?.includes(code) || profile?.language === code}
                      enabled={profile?.language !== code}
                      wrapperStyle={{ width: "calc(50% - 7vh)" }}
                      onChange={(selected) => {
                        let alternativeLanguages = profile?.alternativeLanguages;
                        if (selected && !alternativeLanguages.includes(code)) {
                          alternativeLanguages.push(code);
                          updateProfile("alternativeLanguages", alternativeLanguages);
                        } else if (!selected && alternativeLanguages.includes(code)) {
                          alternativeLanguages.splice(alternativeLanguages.indexOf(code), 1);
                          updateProfile("alternativeLanguages", alternativeLanguages);
                        }
                      }} />
                  ) : (
                    <></>
                  ));
                })}
              </div>
            </div>
          </div>

          <div style={Column}>

            <div style={Cell}>
              <Tag>{config.strings.logo}</Tag>
              <ImagePicker
                id={"logo"}
                imageName={profile?.logo}
                height={"25vh"}
                width={"25vh"}
                onSelect={updateProfile} />
            </div>

            <div style={Cell}>
              <Tag>{config.strings.langDisplay}</Tag>
              <Select
                id={"languageDisplayMode"}
                width={"100%"}
                options={langsDisplayOptions}
                defaultOption={profile?.languageDisplayMode}
                onChange={updateProfile}
              />
            </div>

          </div>

        </div>

        <ButtonWrapper>
          <SecondaryButton value={config.strings.cancel} action={cancelAction} style="margin: 0 1vh 0 auto" />
          <Button value={config.strings.confirm} action={confirmAction} style="margin: 0 auto 0 0" />
        </ButtonWrapper>

      </div>
    </div>
  );
};

export default ProfileComponent;

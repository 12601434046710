import React, { useState } from "react";
import styled from "@emotion/styled";
import Button from "./Button";
import SecondaryButton from "./SecondaryButton";
import TextInput from "./TextInput";
import Colors from "../../assets/Colors.json";

const InputPopup = ({ strings, headerText, hintText, onAccept, onCancel }) => {
  let theme = "lightTheme";
  let colors = Colors[theme];

  const [text, setText] = useState(null);

  const PopupBackground = {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    margin: "auto",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: "900",
  }

  const Popup = {
    position: "absolute",
    maxWidth: "60%",
    minWidth: "30%",
    maxHeight: "40%",
    minHheight: "10%",
    margin: "auto",
    padding: "3vh",
    borderRadius: "3px",
    background: "white",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    textAlign: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  }

  const Header = styled.h1`
    font-size: 3vh;
    font-weight: bold;
    color: ${colors.accentColor1};
  `;

  const Divider = styled.div`
    width: 100px;
    height: 2px;
    background-color: ${colors.accentColor1};
    margin: 1vh auto;
  `;

  const ButtonWrapper = styled.div`
    display: flex;
    margin: auto;
    gap: 3vh;
    width: fit-content;
  `;

  return (
    <>
      <div style={PopupBackground}>
        <div style={Popup}>
          {headerText ? <Header>{headerText}</Header> : <></>}
          <Divider />
          {hintText ? <p>{hintText}</p> : <></>}
          <TextInput width="100%" value={text || ""} onChange={(e) => setText(e.target.value)} />
          <ButtonWrapper>
            {onCancel ? <SecondaryButton value={strings?.cancel} action={onCancel} style={{ marginTop: "2vh" }} /> : <></>}
            {onAccept ? <Button value={strings?.accept} action={() => { onAccept(text) }} style={{ marginTop: "2vh" }} /> : <></>}
          </ButtonWrapper>
        </div>
      </div>
      <div>iNPUT</div>
    </>
  );
};

export default InputPopup;

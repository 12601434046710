import React, { useState } from "react";
import Button from "../../shared/Button";
import UpTastingLogo from "../../../assets/images/hcb_logo.png";
import eyeIcon from "../../../assets/images/icon_eye-slash.svg";
import eyeSlashIcon from "../../../assets/images/icon_eye.svg";
import Video from "../../../assets/videos/camera.mp4";
import TextInput from "../../shared/TextInput";
import Colors from "../../../assets/Colors.json";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

const RegisterComponent = ({ props, register }) => {
  let colors = Colors.lightTheme;

  const [shown, setShown] = useState(false);
  //const [password, setPassword] = useState('');
  //const [secondPassword, setSecondPassword] = useState('');
  const switchShown = () => setShown(!shown);
  let navigate = useNavigate();

  const redirect = () => {
    navigate("/login");
  };

  const Wrapper = {
    height: "100vh",
    display: "flex",
    position: "relative",
    background: "rgb(0 0 0 / 90%)",
    justifyContent: "center",
    overflow: "hidden"
  };

  const Form = {
    width: "25%",
    height: "75%",
    margin: "auto",
    borderRadius: "2vh",
    backgroundColor: "rgb(255 255 255 / 75%)",
    padding: "0px 5vw",
  };

  const FormContent = {
    margin: "0 auto",
    textAlign: "center",
    position: "relative",
    top: "50%",
    transform: "translate(0, -50%)",
  };

  const LogoStyle = styled.img`
    max-width: 20vw;
    max-height: 15vh;
    margin: auto;
    @media (max-width: 426px) {
      width:10rem;
    }
  `;

  const Invis = {
    display: "none",
    textAlign: "left",
    margin: "0.75rem 0",
  };

  const Tag = {
    color: "black",
    fontSize: "2.3vh",
    textAlign: "left",
    margin: "0.75rem 0",
  };

  const ButtonStyle = {
    textAlign: "center",
    marginTop: "2rem",
    width: "auto",
    padding: "2vh 10vh"
  };

  const PwdDiv = {
    borderRadius: "0.3vh",
    border: "0.2vh solid rgb(216, 221, 225)",
    backgroundColor: "white",
    fontSize: "2vh",
    transition: "all 0.1s ease 0s",
    width: "99%",
    display: "flex",
    height: "5vh",
  };

  const PwdInput = {
    display: "flex",
    width: "87%",
    border: "none !important",
    fontSize: "2.3vh",
    backgroundColor: "white",
    outline: "none",
  }

  const Eye = {
    background: "no-repeat center",
    backgroundSize: "80% auto",
    padding: "1vh",
    cursor: "pointer",
  }

  const BackgroundVideo = styled.video`
    z-index: -1;
    position: absolute;
    min-height: 100%;
    min-width: 100%;
  `;


  return (
    <div style={Wrapper}>

      <BackgroundVideo autoPlay muted loop src="http://www.happycustomerbox.com/es/mp4/camera.mp4" type="video/mp4" />

      <form style={Form} autoComplete="off">
        <div style={FormContent}>
          <LogoStyle src={UpTastingLogo} />

          <div style={Invis} autoComplete="false" />
          <div style={Tag}>{props.strings.email}</div>
          <TextInput id="nameInput" placeholder={props.strings.user} width="100%" />

          <div style={Tag}>{props.strings.contrasenya}</div>
          <div style={PwdDiv}>
            <TextInput style={PwdInput}
              enterAction={register}
              id="passwordInput"
              placeholder={props.strings.password}
              width="100%"
              type={shown ? 'text' : 'password'} />
            <img style={Eye} onClick={switchShown} src={shown ? eyeIcon : eyeSlashIcon} />
          </div>

          <div style={Tag}>{props.strings.chechkContrasenya}</div>
          <div style={PwdDiv}>
            <TextInput style={PwdInput}
              enterAction={register}
              id="passwordInput2"
              placeholder={props.strings.password}
              width="100%"
              type={shown ? 'text' : 'password'} />
            <img style={Eye} onClick={switchShown} src={shown ? eyeIcon : eyeSlashIcon} />
          </div>

          <Button value={props.strings.register} action={register} style={ButtonStyle} />
          <Button value={props.strings.volver} action={redirect} style={ButtonStyle} />
        </div>
      </form>
    </div>
  );
};

export default RegisterComponent;

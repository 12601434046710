export default {
    "endMessage": {
        "es_ES": "Muchas gracias por completar nuestra encuesta",
        "ca_ES": "Moltes gràcies per completar la nostra enquesta",
        "en_GB": "Thank you very much for completing our survey",
        "de_DE": "Vielen Dank, dass Sie an unserer Umfrage teilgenommen haben",
        "fr_FR": "Merci beaucoup d'avoir répondu à notre enquête",
        "pt_PR": "Muito obrigado por completar nossa pesquisa",
        "it_IT": "Grazie mille per aver completato il nostro sondaggio",
        "ru_RU": "Большое спасибо за заполнение нашего опроса",
        "zh_CN": "非常感謝您完成我們的調查",
        "ar_SA": "شكرا جزيلا لاستكمال استطلاعنا",
    },
    "preScreen": {
        "header": {
            "es_ES": "¡Bienvenido!",
            "ca_ES": "Benvingut!",
            "en_GB": "Welcome!",
            "de_DE": "Herzlich willkommen!",
            "fr_FR": "Accueillir!",
            "pt_PR": "Bem-vindo!",
            "it_IT": "Ben arrivato!",
            "ru_RU": "Добро пожаловать!",
            "zh_CN": "欢迎！",
            "ar_SA": "اهلا",
        },
        "startButtonText": {
            "es_ES": "Empezar",
            "ca_ES": "Començar",
            "en_GB": "Start",
            "de_DE": "Anfang",
            "fr_FR": "Accueillir!",
            "pt_PR": "Commencer",
            "it_IT": "Inizio",
            "ru_RU": "Начинать",
            "zh_CN": "开始",
            "ar_SA": "بداية",
        }
    }
}
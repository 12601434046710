import React, { useState, useEffect } from "react";
import './App.css';
import { BrowserRouter, Router, Routes, Route, Navigate, useNavigate, useHistory } from "react-router-dom";

import DashboardEngine from "./components/views/Dashboard/DashboadEngine";
import LoginEngine from "./components/views/Login/LoginEngine";
import RegisterEngine from "./components/views/Register/RegisterEngine";
import HomeEngine from "./components/views/Dashboard/Pages/Home/HomeEngine";
import SurveysEngine from "./components/views/Dashboard/Pages/Surveys/SurveysEngine";
import SurveyFormEngine from "./components/views/Dashboard/Pages/Surveys/Form/SurveyFormEngine";
import SurveyListEngine from "./components/views/Dashboard/Pages/Surveys/List/SurveyListEngine";
import ProfileEngine from "./components/views/Dashboard/Pages/Profile/ProfileEngine";
import ReportsEngine from "./components/views/Dashboard/Pages/Reports/ReportsEngine";
import ReportEngine from "./components/views/Dashboard/Pages/Reports/Report/ReportEngine";
import ReportListEngine from "./components/views/Dashboard/Pages/Reports/List/ReportListEngine";

import SettingsEngine from "./components/views/Dashboard/Pages/Settings/SettingsEngine";

import Firebase from "./assets/Firebase";
import Config from "./assets/Config";
import StringsLoader from "./assets/strings/StringsLoader";
import Popup from "./components/shared/Popup";
import InputPopup from "./components/shared/PopupInput";
import DevicesEngine from "./components/views/Dashboard/Pages/Devices/DevicesEngine";
import DeviceListEngine from "./components/views/Dashboard/Pages/Devices/List/DeviceListEngine";
import DeviceEngine from "./components/views/Dashboard/Pages/Devices/Device/DeviceEngine";
import RGPDEngine from "./components/views/Dashboard/Pages/Profile/RGPD/RGPDEngine";
import ReportSendersEngine from "./components/views/Dashboard/Pages/ReportSenders/ReportSendersEngine";
import ReportSenderListEngine from "./components/views/Dashboard/Pages/ReportSenders/List/ReportSenderListEngine";
import ReportSenderEngine from "./components/views/Dashboard/Pages/ReportSenders/ReportSender/ReportSenderEngine";

function App() {
  let f = new Firebase();
  let strLoader = new StringsLoader();
 //let navigate = useNavigate();

  const [lang, setLang] = useState(strLoader.DEFAULT_LANG);
  const [strings, setStrings] = useState(strLoader.getDefaultStrings());
  const [popupData, setPopupData] = useState({
    visible: false,
    header: "",
    text: "",
    confirmation: false,
  });

  const [inputPopupData, setInputPopupData] = useState({
    visible: false,
    header: "",
    text: "",
    confirmation: false,
  });

  useEffect(() => {
    // Comprueva el navegador
    checkBrowser();
  }, []);

  // Carga los strings cuando el lang cambia
  useEffect(() => {
    setStrings(strLoader.getStrings(lang));
  }, [lang]);

  function checkBrowser() {
    new Config();
    console.debug("Browser", Config.NAV);
    console.debug("Envoriment " + Config.ENV);

    /*if (Config.NAV.safari || Config.NAV.firefox) {
      popupAlert("strings.incompatibleBrowserHeader", "strings.incompatibleBrowserBody", () => {
        window.location.replace("https://www.google.com/chrome/");
      });
    }*/
  }

  const popupAlert = (header, text, onClose, confirmation, onConfirm, closeText, acceptText, question, onDoble, onSimple) => {
    setPopupData({
      visible: true,
      header: header,
      text: text,
      onClose: onClose,
      confirmation: confirmation,
      onConfirm: onConfirm,
      closeText: closeText,
      acceptText: acceptText,
      question: question,
      onDoble: onDoble,
      onSimple: onSimple,
    });
  };

  const popupInputAlert = (header, text, onClose, confirmation, onConfirm, closeText, acceptText) => {
    setInputPopupData({
      visible: true,
      header: header,
      text: text,
      onClose: onClose,
      confirmation: confirmation,
      onConfirm: onConfirm,
      closeText: closeText,
      acceptText: acceptText,
    });
  };

  const closePopupAlert = () => {
    setPopupData({ visible: false });
  }

  const closeInputPopupAlert = () => {
    setInputPopupData({ visible: false });
  }

  const getText = (textId) => {
    return strings?.[textId] || textId + "*";
  }

  const getTranslatedText = (texts) => {
    if (texts !== null && texts != undefined) {
      if (lang in texts) {
        return texts?.[lang];
      } else if (strLoader.DEFAULT_LANG in texts) {
        return texts?.[strLoader.DEFAULT_LANG];
      } else if (Object.values(texts || {}).length > 0) {
        return Object.values(texts)[0];
      }
    }
  }

  const childProps = {
    lang: lang,
    setLang: setLang,
    strings: strings,
    popupAlert: popupAlert,
    closePopupAlert: closePopupAlert,
    popupInputAlert: popupInputAlert,
    closeInputPopupAlert: closeInputPopupAlert,
    getText,
    getTranslatedText
  }

  return (
    <>
      <Popup strings={strings} popupData={popupData} setPopupData={setPopupData} />
      <InputPopup strings={strings} popupData={inputPopupData} setPopupData={setInputPopupData} />

      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginEngine props={childProps} />} />
          <Route path="/register" element={<RegisterEngine props={childProps} />} />
          <Route path="/dashboard" element={<DashboardEngine props={childProps} />} >
            <Route path={"home"} element={<HomeEngine />} />
            <Route path={"settings"} element={<SettingsEngine />} />
            <Route path={"profile"} element={<ProfileEngine />} />
            <Route path={"profile/rgpd"} element={<RGPDEngine />} />

            <Route path={"reports"} element={<ReportsEngine props={childProps} />}>
              <Route path={"list"} element={<ReportListEngine />} />
              <Route path={":surveyId"} element={<ReportEngine />} />
            </Route>

            <Route path={"surveys"} element={<SurveysEngine props={childProps} />}>
              <Route path={"list"} element={<SurveyListEngine />} />
              <Route path={"new"} element={<SurveyFormEngine />} />
              <Route path={"edit/:surveyId"} element={<SurveyFormEngine />} />
            </Route>

            <Route path={"devices"} element={<DevicesEngine props={childProps} />}>
              <Route path={"list"} element={<DeviceListEngine />} />
              <Route path={":deviceId"} element={<DeviceEngine />} />
            </Route>

            <Route path={"reportSenders"} element={<ReportSendersEngine props={childProps} />}>
              <Route path={"list"} element={<ReportSenderListEngine />} />
              <Route path={"new"} element={<ReportSenderEngine />} />
              <Route path={"edit/:reportSenderId"} element={<ReportSenderEngine />} />
            </Route>
          </Route>

          <Route path="*" element={<Navigate to={{ pathname: "/dashboard/home" }} />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

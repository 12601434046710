export default [
    {
        "id": "DEFAULT_BUTTONS_SCREEN",
        "type": "SIMPLE",
        "questions": [
            {
                "id": "DEFAULT_BUTTONS_QUESTION",
                "questionTitle": {},
                "type": "SATISFACTION",
                "options": [
                    {
                        "id": "FACE_4",
                        "icon": "face_4",
                        "order": 4,
                        "option": {
                            "en_GB": "Very bad",
                            "pt_PR": "Muito ruim",
                            "zh_CN": "非常糟糕",
                            "de_DE": "Sehr schlecht",
                            "fr_FR": "Très mauvais",
                            "ca_ES": "Molt malament",
                            "ar_SA": "سيئ جدا",
                            "it_IT": "Molto male",
                            "ru_RU": "Очень плохо",
                            "es_ES": "Muy mal"
                        },
                        "style": {
                            "optionTextBackgroundColor": "#e20613",
                            "optionTextColor": "#FFFFFF",
                            "showOptionText": true
                        }
                    },
                    {
                        "id": "FACE_3",
                        "icon": "face_3",
                        "order": 3,
                        "option": {
                            "de_DE": "Mal",
                            "fr_FR": "Mal",
                            "ar_SA": "خاطئ - ظلم - يظلم",
                            "pt_PR": "Mal",
                            "ca_ES": "Malament",
                            "zh_CN": "不正确",
                            "es_ES": "Mal",
                            "ru_RU": "Мал",
                            "en_GB": "Bad",
                            "it_IT": "Mal"
                        },
                        "style": {
                            "optionTextBackgroundColor": "#e9541b",
                            "optionTextColor": "#FFFFFF",
                            "showOptionText": true
                        }
                    },
                    {
                        "id": "FACE_2",
                        "icon": "face_2",
                        "order": 2,
                        "option": {
                            "ca_ES": "Bé",
                            "it_IT": "Bene",
                            "ar_SA": "جيد",
                            "en_GB": "Good",
                            "de_DE": "Gut",
                            "zh_CN": "好的",
                            "ru_RU": "Хорошо",
                            "fr_FR": "Bien",
                            "pt_PR": "Bem",
                            "es_ES": "Bien"
                        },
                        "style": {
                            "optionTextBackgroundColor": "#90c162",
                            "optionTextColor": "#FFFFFF",
                            "showOptionText": true
                        }
                    },
                    {
                        "id": "FACE_1",
                        "icon": "face_1",
                        "order": 1,
                        "option": {
                            "pt_PR": "Muito bem",
                            "ca_ES": "Molt bé",
                            "zh_CN": "非常好",
                            "ar_SA": "جيد جدا",
                            "de_DE": "Sehr gut",
                            "es_ES": "Muy bien",
                            "fr_FR": "Très bien",
                            "ru_RU": "Очень хорошо",
                            "it_IT": "Molto bene",
                            "en_GB": "Very good"
                        },
                        "style": {
                            "optionTextBackgroundColor": "#009540",
                            "optionTextColor": "#FFFFFF",
                            "showOptionText": true
                        }
                    },
                ]
            }
        ]
    }
];
import React, { useState } from "react";
import { Pie, Radar, Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale,
    BarElement, Title
} from 'chart.js';
import IcGraphBar from "../../../../../../../../assets/images/ic_bar_grpah.png";
import IcGraphPie from "../../../../../../../../assets/images/ic_pie_grpah.png";
import IcHour from "../../../../../../../../assets/images/ic_hour.png";
import IcDay from "../../../../../../../../assets/images/ic_day.png";
import IcMonth from "../../../../../../../../assets/images/ic_month.png";
import IcLegend from "../../../../../../../../assets/images/ic_table.svg";
import IcMaximize from "../../../../../../../../assets/images/maximize-icon-white.svg";
import IcClose from "../../../../../../../../assets/images/cross-icon-white.svg";
import Colors from "../../../../../../../../assets/Colors.json";
import CoreTooltip from "@material-ui/core/Tooltip";

const GraphComponent = ({ config, question, questionData, actions, isLastNPS}) => {
    ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

    const [graphSettings, setGraphSettings] = useState({
        mode: "bar",
        showLegened: false,
        maximized: false,
    });

    const GraphBackground = graphSettings.maximized ? {
        top: "0",
        left: "0",
        position: "fixed",
        width: "60vw",
        height: "70vh",
        padding: "15vh 20vw",
        zIndex: 1,
        background: "rgb(0 0 0 / 85%)",
        display: "flex",
    } : {
        minHeight: "35vh"
    }

    const GraphContainer = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        width: "calc(100% - 6vh)",
        maxHeight: "100%",
        minHeight: "calc(100% - 4vh)",
        borderRadius: "1vh",
        backgroundColor: graphSettings.maximized ? "rgb(255 255 255 / 95%)" : "rgb(216 216 216 / 45%)",
        margin: "auto 2vh",
        padding: "1vh",
    }

    const Graph = {
        margin: "auto",
        width: graphSettings.maximized ? "85%" : "80%",
        height: graphSettings.maximized ? "85%" : "80%",
        maxHeight: graphSettings.maximized ? "60vh" : "35vh",
        maxWidth: graphSettings?.maximized ? (
            graphSettings?.mode === "pie" ? "30vw" : "50vw"
        ) : (
            graphSettings?.mode === "pie" ? "35vh" : "70vh"
        )
    }

    const OptionsWrapper = {

    }


    const IconContainer = {
        height: "3vh",
        width: "3vh",
        padding: "0.6vh",
        margin: "0.75vh",
        borderRadius: "6px",
        backgroundColor: "darkgray",
        cursor: "pointer",
    }

    const SelectedIconContainer = {
        ...IconContainer,
        backgroundColor: "gray",
    }

    const Icon = {
        width: "100%",
        height: "100%"
    }

    const pieOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: graphSettings?.showLegened
            }
        }
    }

    const barOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: graphSettings?.showLegened
            }
        },
        options: {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        stepSize: 1
                    }
                }]
            }
        }
    };

    const stackedBarOptions = {
        ...barOptions,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };

    const getColor = () => {
        if (Colors.graphColors[question.type]) {
            if (isLastNPS) {
                return Colors.graphColors[question.type+"_LAST"];
            }
            else{
                return Colors.graphColors[question.type];
            }
        }
        else {
            return Colors.graphColors.default;
        }
    }

    const updateGraphSettings = (key, value) => {
        setGraphSettings((prev) => ({
            ...prev,
            [key]: value
        }));
    }

    const setGrapMode = (mode) => {
        updateGraphSettings("mode", mode);
    }

    const getIconOptionComp = (icon, action, text, selected) => {
        return (
            <CoreTooltip title={text} aria-label="add" placement="left">
                <div style={selected ? SelectedIconContainer : IconContainer} onClick={action}>
                    <img style={Icon} src={icon} />
                </div>
            </CoreTooltip>
        );
    }

    const getSimpleFormatedData = () => {
        let data = actions.getGraphData(question, questionData);
        return {
            labels: data?.labels || ['A', 'B', 'C',],
            datasets: [
                {
                    label: "Total",
                    data: data?.values || [12, 19, 6],
                    backgroundColor: getColor(data?.values),
                    borderColor: getColor(data?.values),
                    borderWidth: 1,
                },
            ],
        }
    };

    const getStackedData = (stackedBy, grouped) => {
        let data = actions.getStackedGraphData(question, stackedBy, grouped);
        let colors = getColor(data?.datasets);

        data?.datasets?.forEach((dataset, i) => {
            dataset.backgroundColor = colors[i % colors.length];
        });

        return {
            labels: data?.labels,
            datasets: data?.datasets
        }
    };

    const getGraph = () => {
        switch (graphSettings?.mode) {
            case "pie":
                return <Pie data={getSimpleFormatedData()} options={pieOptions} />
            case "bar":
                return <Bar data={getSimpleFormatedData()} options={barOptions} />
            case "groupByHour":
                return <Bar data={getStackedData("hour", true)} options={stackedBarOptions} />
            case "groupByDay":
                return <Bar data={getStackedData("day", true)} options={stackedBarOptions} />
            case "stackedDay":
                return <Bar data={getStackedData("day", false)} options={stackedBarOptions} />
            case "stackedMonth":
                return <Bar data={getStackedData("month", false)} options={stackedBarOptions} />
            default:
                return <div>No type</div>;
        }
    }


    return (
        <div style={GraphBackground}>
            <div style={GraphContainer}>
                <div style={Graph}>
                    {getGraph()}
                </div>

                <div style={OptionsWrapper}>
                    {getIconOptionComp(
                        graphSettings.maximized ? IcClose : IcMaximize,
                        () => { updateGraphSettings("maximized", !graphSettings.maximized) },
                        config.getText("minimizeMaximize"),
                        graphSettings.maximized
                    )}
                    {getIconOptionComp(
                        IcLegend,
                        () => { updateGraphSettings("showLegened", !graphSettings.showLegened) },
                        config.getText("showLegend"),
                        graphSettings.showLegened
                    )}
                    {getIconOptionComp(
                        IcGraphBar,
                        () => { setGrapMode("bar") },
                        config.getText("graphBar"),
                        graphSettings.mode === "bar")
                    }
                    {getIconOptionComp(
                        IcGraphPie,
                        () => { setGrapMode("pie") },
                        config.getText("graphPie"),
                        graphSettings.mode === "pie")
                    }
                    {getIconOptionComp(
                        IcHour,
                        () => { setGrapMode("groupByHour") },
                        config.getText("groupByHour"),
                        graphSettings.mode === "groupByHour"
                    )}
                    {getIconOptionComp(
                        IcDay,
                        () => { setGrapMode("groupByDay") },
                        config.getText("groupByDay"),
                        graphSettings.mode === "groupByDay"
                    )}
                    {getIconOptionComp(
                        IcDay,
                        () => { setGrapMode("stackedDay") },
                        config.getText("stackedDay"),
                        graphSettings.mode === "stackedDay"
                    )}
                    {getIconOptionComp(
                        IcMonth,
                        () => { setGrapMode("stackedMonth") },
                        config.getText("stackedMonth"),
                        graphSettings.mode === "stackedMonth"
                    )}
                </div>

            </div>
        </div>
    )
};
export default GraphComponent;
import React, { useState } from "react";
import styled from "@emotion/styled";
import Colors from "../../../../../../../../assets/Colors.json";
import Button from "../../../../../../../shared/Button";
import SecondaryButton from "../../../../../../../shared/SecondaryButton";
import Select from "../../../../../../../shared/Select";
import { isNullSelecction } from "../../../../../../../../assets/utils/FormsUtils";

const FilterPopup = ({ config, filterActions, survey }) => {
  const theme = "lightTheme";
  const colors = Colors[theme];

  const [newFilter, setNewFilter] = useState({
    questionId: null,
    optionId: null,
  });

  const updateNewFilter = (key, value) => {
    setNewFilter((prevState) => ({
      ...prevState,
      [key]: value
    }))
  }

  const getQuestionsList = () => {
    let questions = { notSelected: config.getText("notSelected") };

    survey?.screens?.forEach((screen, screenIndex) => {
      screen?.questions.forEach((question, questionIndex) => {
        let isMultipleQuestion = screen?.questions?.length > 1;
        let questionNum = "" + (screenIndex + 1) + (isMultipleQuestion ? ("." + (questionIndex + 1)) : "")
        questions[question?.id] = questionNum + " - " + config.getTranslatedText(question?.questionTitle);
      });
    });

    return questions;
  }

  const getOptionsList = () => {
    let options = { notSelected: config.getText("notSelected") };

    let screeen = survey?.screens?.find((s) => s?.questions?.some((q) => q?.id === newFilter?.questionId));
    let question = screeen?.questions?.find((q) => q?.id === newFilter?.questionId);

    question?.options?.forEach((option) => {
      options[option?.id] = config.getTranslatedText(option?.option);
    });

    return options;
  }

  const confirmAction = () => {
    if (isNullSelecction(newFilter?.questionId)) {
      config?.popupAlert(
        config?.getText("error"),
        config?.getText("errorSelectQuestion")
      );

    } else if (isNullSelecction(newFilter?.optionId)) {
      config?.popupAlert(
        config?.getText("error"),
        config?.getText("errorSelectOption")
      );

    } else {
      filterActions.addFilter(newFilter?.questionId, newFilter?.optionId)
      filterActions.closeFilterPopup();
    }
  }

  const PopupBackground = {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    margin: "auto",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: "900",
  }

  const Popup = {
    position: "absolute",
    maxWidth: "60%",
    minWidth: "30%",
    maxHeight: "40%",
    minHheight: "10%",
    margin: "auto",
    padding: "3vh",
    borderRadius: "3px",
    background: "white",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    textAlign: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  }

  const Header = styled.h1`
    font-size: 3vh;
    font-weight: bold;
    color: ${colors.accentColor1};
  `;

  const Tag = styled.div`
    flex: 1;
    font-size: 2.2vh;
    color: black;
    text-align: justify;
  
  `;

  const Row = styled.div`
    display: flex;
    flex-direction row;
    padding: 2vh 0;
    align-items: center;
  `;

  const Divider = styled.div`
    width: 100px;
    height: 2px;
    background-color: ${colors.accentColor1};
    margin: 1vh auto;
  `;

  const ButtonWrapper = styled.div`
    display: flex;
    margin: auto;
    gap: 3vh;
    width: fit-content;
  `;

  return (
    <>
      <div style={PopupBackground}>
        <div style={Popup}>
          <Header>{config.getText("addFilter")}</Header>
          <Divider />

          <Row>
            <Tag>{config.getText("Question")}</Tag>
            <Select
              width="100%"
              styles="flex: 3;"
              id={"questionId"}
              options={getQuestionsList()}
              defaultOption={newFilter.questionId}
              onChange={(key, value) => {
                setNewFilter({
                  questionId: value,
                  optionId: null,
                })
              }} />
          </Row>

          <Row>
            <Tag>{config.getText("Option")}</Tag>
            <Select
              width="100%"
              styles="flex: 3;"
              id={"optionId"}
              options={getOptionsList()}
              defaultOption={newFilter.optionId}
              onChange={updateNewFilter} />
          </Row>

          <ButtonWrapper>
            <SecondaryButton value={config.getText("cancel")} action={filterActions.closeFilterPopup} style={{ marginTop: "2vh" }} />
            <Button value={config.getText("accept")} action={confirmAction} style={{ marginTop: "2vh" }} />
          </ButtonWrapper>
        </div>
      </div>
    </>
  );
};

export default FilterPopup;

import React from "react";
import styled from "@emotion/styled";
import EditIcon from "../../../../../../assets/images/edit-icon.svg";
import Table from "../../../../../shared/Table";
import Checkbox from "../../../../../shared/Checkbox";
import Select from "../../../../../shared/Select";
import EyeIcon from "../../../../../../assets/images/icon_eye.svg";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "../../../../../../assets/images/delete-icon.svg";
import TextInput from "../../../../../shared/TextInput";
import SecondaryButton from "../../../../../shared/SecondaryButton";
import Button from "../../../../../shared/Button";

const DeviceComponent = ({ config, device, alertTypes, actions }) => {

    const Title = styled.div`
        background-color: darkgray;
        color: white;
        text-transform: uppercase;
        font-weight: bold;
        padding: 2vh 5vh;
    `;

    const Wrapper = {
        width: "calc(100% - 12vh)",
        display: "flex",
        padding: "4vh 6vh",
        marginBottom: "0.5rem",
        justifyContent: "center",
        flexDirection: "column",
        rowGap: "2vh",
        columnGap: "3vh"
    }

    const Tag = styled.div`
        flex: 1;
        font-size: 2.5vh;
        border-bottom: 1px solid grey;
        margin-bottom: 1vh;
        margin-top: 1vh;
        padding-bottom: 1vh;
    `

    const Label = styled.div`
        font-size: 2.3vh;
        font-weight: bold;
        max-width: 10vw;
        min-width: 6vw;
    `

    const Data = styled.div`
        font-size: 2.3vh;
    `

    const Info = styled.div`
        text-align: center;
        padding: 1vh 2vh;
        border-radius: 0.5vh;
        background: rgba(0, 0, 0, 0.1);
        flex: 2;
    `

    const Icon = styled.img`
        height: 2.5vh;
        cursor: pointer;
    `

    const Row = {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        columnGap: "5vh",
        alignItems: "center"
    }

    const Column = {
        flex: "1",
        display: "flex",
        flexDirection: "column",
        rowGap: "2.5vh"
    }

    let listActions = [
        {
            title: (
                <Tooltip title={config.strings.seeDetails} aria-label="add">
                    <Icon src={EyeIcon} />
                </Tooltip>
            ),
            action: actions.showCompleteStatus,
        }
    ];

    return (
        <div style={{ width: "100%" }}>
            <Title>{config?.strings?.device} {device?.ref?.id}</Title>

            <div style={Wrapper}>


                <div style={{ ...Row, alignItems: "flex-start" }}>
                    <div style={Column}>
                        <Tag>Datos del dispositvo</Tag>
                        <div style={Row}>
                            <Label>Alias:</Label>
                            <TextInput
                                id="alias"
                                width={"100%"}
                                value={device?.alias || "-"}
                                onChange={(e) => actions.updateDevice("alias", e.target.value)} />
                        </div>
                        <div style={Row}>
                            <Label>Id:</Label>
                            <Data>{device?.ref?.id || "-"}</Data>
                        </div>
                        <div style={Row}>
                            <Label>Device Id:</Label>
                            <Data>{device?.deviceId || "-"}</Data>
                        </div>
                    </div>

                    <div style={Column}>
                        <Tag>Alertas de incidencias</Tag>

                        <div style={Row}>
                            <Checkbox
                                wrapperStyle={{ padding: "0" }}
                                text={config?.getText("enableAlert")}
                                checked={device.alarmEnabled}
                                onChange={(val) => { actions.updateDevice("alarmEnabled", val) }} />
                            <Info>
                                Cuando no se reciba respuesta del dispositivo durante el tiempo especificado, se le notificará la incidencia por correo electrónico
                            </Info>
                        </div>

                        {device?.alarmEnabled ? (
                            <>
                                <div style={Row}>
                                    <Label>{config?.getText("type")}: </Label>
                                    <Select
                                        id={"alertType"}
                                        disabled={!device?.alarmEnabled}
                                        options={alertTypes}
                                        defaultOption={device?.alertType}
                                        onChange={actions.updateDevice} />
                                </div>

                                <div>
                                    <div style={Row}>
                                        <div style={{ ...Column, flex: "0" }}>
                                            <Label>Emails:</Label>
                                        </div>
                                        <div style={{ ...Column, rowGap: "1vh" }}>
                                            {(device?.alertEmails || []).map((email, index) => {
                                                return (
                                                    <div style={{ ...Row, columnGap: "1vw" }}>
                                                        <TextInput
                                                            id="alias"
                                                            width={"100%"}
                                                            value={email}
                                                            disabled={!device?.alarmEnabled}
                                                            onChange={(e) => actions.editAlertEmail(index, e.target.value)} />
                                                        <Icon src={DeleteIcon}
                                                            onClick={() => actions.deleteAlertEmail(index)} />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div>
                                            <SecondaryButton value={config?.getText("add")} action={actions.addAlertEmail} />
                                        </div>
                                    </div>

                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>

                <div style={{ ...Row, paddingTop: "2vh", justifyContent: "center" }}>
                    <SecondaryButton value={config?.getText("cancel")} action={actions.cancelAction} />
                    <Button value={config?.getText("accept")} action={actions.confirmAction} />
                </div>

                <div style={{ marginTop: "3vh" }}>
                    <Tag>Status recibidos</Tag>
                    <div style={Row}>
                        <Table
                            header={config?.strings?.statusTableHeaders}
                            data={actions.getFormatedStatus()}
                            actions={listActions}
                            noElementsText={config.strings.noneListStatus}
                            sortByField={"creationDate"}
                            sortOrder={"descending"}
                            styles={"flex: 1;"} />
                    </div>
                </div>

            </div>
        </div>
    );
};

export default DeviceComponent;

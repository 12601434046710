import React, { useState, useEffect } from "react";
import ListComponent from "./ReportListComponent";
import LoadingScreen from "../../../../../shared/LoadingScreen";
import DownloadingPopup from "../../../../../shared/DownloadingPopup";
import { useNavigate, useOutletContext } from "react-router-dom";
import Firebase from "../../../../../../assets/Firebase";
import { getEndOfCurrentMonth, getStartOfCurrentMonth } from "../../../../../../assets/utils/DateUtils";

const ReportListEngine = () => {
  const config = useOutletContext();
  const navigate = useNavigate();
  let fdb = new Firebase();

  const [surveys, setSurveys] = useState(null);
  const [loadingText, setLoadingText] = useState(null);

  const loadSurveys = () => {
    let toDate = getEndOfCurrentMonth();
    let fromDate = getStartOfCurrentMonth();

    fdb.loadSurveys((data) => {
      console.debug("Surveys loaded: ", data);

      let parsedDataList = {};

      if (Object.entries(data).length === 0) {
        setSurveys({});

      } else {
        Object.entries(data).forEach(([id, value]) => {

          let parsedDate = {
            name: value?.name || "Not named",
            type: value?.type || "No type",
            responses: "-",
            creationDate: value?.creationDate?.toDate() || "-",
            lastUpdate: value?.lastUpdate?.toDate() || "-",
          };

          let updateParsedData = (parsedData) => {
            parsedDataList[id] = parsedData;
            if (Object.keys(parsedDataList).length === Object.keys(data).length) {
              setSurveys(parsedDataList);
            }
          }

          fdb.loadSurveyResponses(id, false, fromDate, toDate, (responses) => {
            parsedDate.responses = responses.length;
            updateParsedData(parsedDate);
          }, (error) => {
            console.error("Error loading surveys: ", error);
            updateParsedData(parsedDate);
          });
        });
      }

    }, (error) => {
      console.error("Error loading surveys: ", error);
      setSurveys({});
    });
  }

  useEffect(() => {
    if (surveys == null) {
      loadSurveys();
    }
  }, []);

  const showReport = (id) => {
    navigate(`/dashboard/reports/${id}`);
  }

  return (<>
    {surveys != null ? (
      <ListComponent
        config={config}
        surveys={surveys}
        actions={{
          showReport: showReport
        }} />
    ) : (
      <LoadingScreen />
    )}
    {loadingText != null ? <DownloadingPopup strings={config.strings} headerText={loadingText} /> : <></>}
  </>
  );
};

export default ReportListEngine;

import React, { useState, useRef, useEffect } from "react";
import Radium from "radium";
import styled from "@emotion/styled";
import TextInput from "./TextInput";
import TranslateIcon from "../../assets/images/translate-icon.svg"
import CollapseIcon from "../../assets/images/collapse-icon.svg"
import Colors from "../../assets/Colors.json"

const TranslatableInput = ({ compId, id, config, texts, rootStyles, styles, onChange, focused }) => {
  const myRef = useRef(null);
  const [expanded, setExpanded] = useState(false);

  const Root = {
    width: "100%",
    ...rootStyles
  }

  const InputWrapper = {
    display: "flex",
    flexDirection: "row",
    borderRadius: "0.3vh",
    border: "solid 0.2vh #d8dde1",
    borderColor: "#d8dde1",
    background: "white"
  }

  const EntryWrapper = {
    display: "flex",
    flexDirection: "column",
    flex: "1",
    padding: "0.5vh 0"
  }

  const Entry = {
    display: "flex",
    flexDirection: "row",
  }

  const EntryInput = {
    border: "none",
    borderBottom: "solid 0.2vh #d8dde1",
    padding: "0.5vh 0.5vh",
    margin: "0.5vh 0"
  }

  const LangTag = styled.div`
    margin: auto 2vh;
    min-width: 5vw;
    max-width: 5vw;
    overflow: hidden;
    text-overflow: ellipsis;
  `;

  const IconWrapper = styled.div`
    position: relative;
    cursor: pointer;
    margin: auto 1vh;
  `;

  const IconText = styled.div`
   position: absolute;
   background: ${Colors.lightTheme.accentColor1};
   padding: 0.25vh;
   color: white;
   border-radius: 35%;
   top: 0; 
   right:0;
   font-size: 1.5vh;
   display: ${expanded ? "none" : ""};
  `;

  const Icon = styled.img`
    height: 3.5vh;
    width: 3.5vh;

  `;

  const onChangeAlternativeLang = (langCode, value) => {
    let newTexts = {
      ...texts,
      [langCode]: value,
    };
    onChange(id, newTexts);
  }

  return (
    <div style={Root} > 
      <div style={InputWrapper}>
        {expanded ? (
          <div style={EntryWrapper}>
            {config.getAvaliableLangs().map((langCode) => {
              return (
                <div style={Entry}>
                  <LangTag>{config?.getText(langCode)}:</LangTag>
                  <TextInput
                    id={(compId || id) + "-" + langCode}
                    styles={EntryInput}
                    value={texts?.[langCode]}
                    width={"100%"}
                    onChange={(e) => { onChangeAlternativeLang(langCode, e.target.value) }} />
                </div>
              )
            })}

          </div>
        ) : (
          <TextInput
              id={compId || id}
              styles={{ border: "none" }}
              value={config.getTranslatedText(texts)}
              width={"100%"}
              focused={focused}
              onChange={(e) => { onChange(id, { ...texts, [config?.lang]: e.target.value }) }} />
        )}
        <IconWrapper onClick={() => setExpanded(!expanded)} >
          <Icon src={expanded ? CollapseIcon : TranslateIcon} />
          <IconText>{config?.lang?.split("_")?.[0]}</IconText>
        </IconWrapper>
      </div>
    </div >
  );
};

export default Radium(TranslatableInput);

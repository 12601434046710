import ReactHtmlParser from "react-html-parser";

import React from "react";
import styled from "@emotion/styled";
import Button from "./Button";
import SecondaryButton from "./SecondaryButton";
import Colors from "../../assets/Colors.json";

const MessagePopup = ({ strings, popupData, setPopupData }) => {
  const PopupBackground = styled.div`
    z-index: 999;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
  `;

  const Popup = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    max-width: 40%;
    min-width: 20%;
    max-height: 40%;
    min-height: 10%;
    margin: auto;
    padding: 2vh;
    border-radius: 3px;
    background: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `;

  const Header = styled.h1`
    font-size: 3vh;
    font-weight: bold;
    color: ${Colors.lightTheme.accentColor1};
  `;

  const Divider = styled.div`
    width: 100px;
    height: 2px;
    background-color: ${Colors.lightTheme.accentColor1};
    margin: 1vh auto;
  `;

  const close = () => {
    setPopupData({ visible: false });
    if (typeof popupData.onClose === "function") {
      popupData.onClose();
    }
  };

  const confirm = () => {
    let isFunction = typeof popupData.onConfirm === "function";
    if (isFunction) {
      popupData.onConfirm();
    }
    close();
  };

  const doble = () => {
    let isFunction = typeof popupData.onDoble === "function";
    if (isFunction) {
      popupData.onDoble();
    }
    close();
  };

  const simple = () => {
    let isFunction = typeof popupData.onSimple === "function";
    if (isFunction) {
      popupData.onSimple();
    }
    close();
  };

  return (
    <React.Fragment>
      {popupData.visible ? (
        <PopupBackground>
          <Popup>
            <Header>{popupData.header}</Header>
            <Divider />
            <div style={{ overflow: "auto", padding: "2vh" }}>{ReactHtmlParser(popupData.text)}</div>
            <div>
              {popupData.question ? (
                <React.Fragment>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                    <Button value={strings.typeSimple} action={simple} style={{ marginRight: "0.5vw" }} />
                    <Button value={strings.typeDoble} action={doble} style={{ marginRight: "0.5vw" }} />
                  </div>
                  <br></br>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <SecondaryButton value={popupData.closeText ? popupData.closeText : strings.cancel} action={close} style={{ marginLeft: "0.5vw" }} />
                  </div>
                </React.Fragment>
              ) : (
                <div>
                  {popupData.confirmation ? (
                    <React.Fragment>
                      <SecondaryButton value={popupData.closeText ? popupData.closeText : strings.cancel} action={close} style={{ marginRight: "0.5vw" }} />
                      <Button value={popupData.acceptText ? popupData.acceptText : strings.accept} action={confirm} style={{ marginLeft: "0.5vw" }} />
                    </React.Fragment>
                  ) : (
                    <Button value={popupData.closeText ? popupData.closeText : strings.close} action={close} />
                  )}
                </div>
              )}
            </div>
          </Popup>
        </PopupBackground>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default MessagePopup;

import React, { useEffect, useState } from "react";

import Firebase from "../../../assets/Firebase";
import { Outlet, useNavigate } from "react-router-dom";
import LoadingScreen from "../../shared/LoadingScreen";
import NavBarEngine from "./Navbar/NavbarEngine";
import DownloadingPopup from "../../shared/DownloadingPopup";
import InputPopup from "../../shared/InputPopup";
import Colors from "../../../assets/Colors.json";

const DashboardEngine = ({ props }) => {
  let f = new Firebase();
  let navigate = useNavigate();
  let loadingUser = false;

  const [config, setConfig] = useState({
    ...props,
    user: null,
    downloadingText: null,
    inputPopupData: null,

    getAvaliableLangs: function () {
      let avaliableLangs = [];
      if (this.user?.alternativeLanguages) {
        avaliableLangs = this.user?.alternativeLanguages;
      }
      if (this?.lang && !avaliableLangs.includes(this?.lang)) {
        avaliableLangs.push(this?.lang);
      }
      return avaliableLangs;
    },

    update: function (parameter, value) {
      setConfig((prevState) => ({
        ...prevState,
        [parameter]: value,
      }));
    },

    closeInputPopup: function () {
      this.update("inputPopupData", null);
    },

    showInputPopup: function (header, hint, onAccept) {
      this.update("inputPopupData", {
        header: header,
        hint: hint,
        onAccept: onAccept,
        onCancel: () => this.closeInputPopup(),
      });
    },

    showDownloadingPopup: function (text) {
      this.update("downloadingText", text);
    },

    hideDownloadingPopup: function () {
      this.update("downloadingText", null);
    },

    focusComponent: function (id) {
      setTimeout(() => {
        let element = document.getElementById(id);
        if (element) {
          element.focus();
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 100);
    }
  });

  useEffect(() => {
    if (config.user === null && !loadingUser) {
      // Check user type
      loadingUser = true;
      loadUser();
    }
  }, [config?.user]);

  const loadUser = () => {
    const query = new URLSearchParams(window.location.search);
    const tokenId = query.get('tokenId');

    if (tokenId) {
      loadUserWithTokenId(tokenId);
    } else {
      f.checkSignedUser(onUserLogged, onNotUserLogged);
    }
  }

  const loadUserWithTokenId = (tokenId) => {
    f.getCustomToken(tokenId, (response) => {
      if (response?.data?.status === "OK") {
        const customToken = response?.data?.customToken;
        console.log("TokenId verified. Trying to login...");

        f.tryLoginWithCustomToken(customToken, (result) => {
          console.log("Logged with custom token");
          onUserLogged(result.user);
        }, onNotUserLogged);
      } else {
        throw (response?.data);
      }
    }, onNotUserLogged);
  }

  const onUserLogged = (user) => {
    f.loadUserData((userData) => {
      const completeUser = {
        ...user.multiFactor.user,
        ...userData
      }
      config.update("user", completeUser);
      console.log("User logged: ", completeUser.email, completeUser.uid);

      if (userData?.language) {
        config.setLang(userData?.language);
      }
      loadingUser = false;
    }, (error) => {
      console.error("Error getting user data: ", error);
      onNotUserLogged();
    });
  };

  const onNotUserLogged = (error) => {
    console.log("User not logged", error);
    navigate("/login");
    loadingUser = false;
  };

  const Container = {
    width: "100%",
    minHeight: "100vh",
    maxHeight: "100vh",
    display: "flex",
  };

  const Wrapper = {
    minHeight: "100vh",
    maxHeight: "100vh",
    overflow: "auto", 
    flex: "1",
    backgroundColor: Colors.lightTheme.grey,
  };

  useEffect(() => {
    setConfig((prevState) => ({
      ...prevState,
      ...props,
    }));
  }, [props]);

  return (
    <div style={Container}>

      {/* Dashboard */}
      {config.user ? (
        <>
          <NavBarEngine config={config} />
          <div style={Wrapper}>
            <Outlet context={config} />
          </div>
        </>
      ) : (
          <LoadingScreen />
      )}

      {/* Auxiliar popup*/}
      {config?.downloadingText !== null ? (
        <DownloadingPopup headerText={config?.downloadingText} />
      ) : (
        <></>
      )}

      {/* Auxiliar popup*/}
      {config?.inputPopupData !== null ? (
        <InputPopup
          strings={config?.strings}
          headerText={config?.inputPopupData?.header}
          hintText={config?.inputPopupData?.hint}
          onAccept={config?.inputPopupData?.onAccept}
          onCancel={config?.inputPopupData?.onCancel}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default DashboardEngine;
